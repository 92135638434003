import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllCostIndexRateForDateWise, getAllWorkTypeList } from "app/redux/actions/ConstIndexActions";
import { getAllConstructionNameListByParameters, getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getCostIndexReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";



const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const CostIndexReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { workTypeList } = useSelector((state) => state.costIndex);
  const [workTypeArr,setWorkTypeArr]=useState([])
//   const [reportType, setReportType] = useState("estimate")
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [constructionNameList, setConstructionNameList] = useState([])
  const [projectDetails, setProjectDetails] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const { reportsProjectLocationList, projectLevelStagePercentage, constructionLevelStagePercentage } = useSelector((state) => state.projectLocation);
  // const {userList}=useSelector((state)=>state.userList)
  const [tableData, setTableData] = useState()
    ;
    const [costIndexRates, setCostIndexRates] = useState([]);
  const [state, setState] = useState({
    project_location_id: '',
    construction_name:'',
    work_type:"",
    constructionname_id: '',
    costindex_percent:"",
    costindex_date:"",
    work_type_id:""
  })

  // useEffect(()=>{
  //     console.log(userList)
  // },[userList])

  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }
useEffect(()=>{
    if(workTypeList?.length>0&&state?.constructionname_id){
      const filteredWorkTypes=workTypeList?.filter((workType)=>workType?.constructionname_id===state?.constructionname_id)
        setWorkTypeArr(filteredWorkTypes??[])
    }
},[workTypeList])
  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
        setTableData()
        setWorkTypeArr([])
      
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value,
        project_name :filteredArray[0].project_name,
        project_id : filteredArray[0].project_id,
        project_location:filteredArray[0].project_location,
        construction_name:'',
        constructionname_id: '',
        costindex_date:"",
        costindex_percent:"",
        work_type_id:"" });
        setConstructionNameList([])
        setCostIndexRates([])
      //dispatch(getAllConstructionNameListByParams(0, filteredArray[0].project_location_id, showLoader, hideLoader, showToast))
      getAllConstructionNameList(0, filteredArray[0].project_location_id, filteredArray[0].project_id)

    } else if(event.target.name === "constructionname_id") {
     
        setTableData()
        setWorkTypeArr([])
        const filteredArr=constructionNameList?.filter((elem)=>elem?.constructionname_id===event.target.value)
        setState({ ...state, [event.target.name]: event.target.value ,
            construction_name:filteredArr[0]?.construction_name,
            work_type:"",
            costindex_date:"",
            costindex_percent:"",
            work_type_id:""});
            setWorkTypeArr([])
            setCostIndexRates([])
        dispatch(getAllWorkTypeList(showLoader, hideLoader, showToast, 'user'))
      
    }
    else if(event.target.name === "work_type_id") {
        setTableData()
        setCostIndexRates([])
        // dispatch(getAllWorkTypeList(showLoader, hideLoader, showToast, 'user'))
        const filteredArr=workTypeArr?.filter(elem=>elem?.worktype_id===event.target.value)
        setState({ ...state, [event.target.name]: event.target.value,
            work_type:filteredArr[0]?.worktype,
            costindex_date:"",
            costindex_percent:"" });
            setCostIndexRates([])
        fetchDateWiseCostIndex(event?.target?.value);

      
    }
    else{
        setTableData()
        const filteredArray = costIndexRates.filter(costIndex => {
            return formatStartDate(costIndex.created_on) === event.target.value
          });
          
          
          setState({ ...state, [event.target.name]: filteredArray[0].created_on ,
            costindex_percent : filteredArray[0].costindex_percent});
    }
  }
  const fetchDateWiseCostIndex = (id) => {
    const params = {
      worktype_id: id,
      project_location_id: state?.project_location_id
    }
    getAllCostindexRate(params)

  }
  const getAllCostindexRate = (params) => {
    setLoading(true)
    getAllCostIndexRateForDateWise(params).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success === true) {
        setCostIndexRates(res?.data?.obj)
      } else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
       const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

    })
  }
  const getAllConstructionNameList = (project_location_id, constructionname_id, project_id) => {
    setLoading(true)
    getAllConstructionNameListByParameters(project_location_id, constructionname_id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        // if (res?.data?.length > 0) {
        //     state.constructionname_id = res?.data[0].constructionname_id
        //     state.construction_name = res?.data[0].construction_name 
        //     state.project_id = project_id
        //     setState({ ...state, ['boq_code']: res?.data[0].boq_code });
        // }
        setConstructionNameList(res?.data)
      } else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
       const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

    })
  }

  const handleFormSubmit = () => {
    console.log("state:",state)
    if(checkValidation(state?.costindex_date)===false){
      const key = enqueueSnackbar("Please select cost index date", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
 else{   setLoading(true)
    const params={
         worktype_id: state?.work_type_id,
        created_on: state?.costindex_date
          
    }
   
    getCostIndexReport(params)
        .then((res) => {
          setLoading(false)

          if (res?.status < 300) {
            if(res?.data?.success){
            const tableDataList = res?.data?.obj?.item
            // const TotalAmount = res?.data?.obj?.stageInformationList?.reduce((accumulator, stage) => {
            //   const constructionNameBOQDetailsList = stage.constructionNameBOQDetailsList;
            //   const amountSum = constructionNameBOQDetailsList.reduce((acc, item) => acc + item.amount, 0);
            //   return accumulator + amountSum;
            // }, 0);
            // setTotalAmount(TotalAmount)
            setProjectDetails({
            //   construction_name: res?.data?.obj?.construction_name,
            //   bsr_year: res?.data?.obj?.bsr_year,
            //   project_location: res?.data?.obj?.project_location,
            //   project_name: res?.data?.obj?.project_name,
            //   dzongkhag_name: res?.data?.obj?.dzongkhagName,
              header: res?.data?.obj?.header_logo,
              footer: res?.data?.obj?.footer_logo

            })
            setTableData(tableDataList)
          }
          else{
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
  SnackBarProperty.info(key, closeSnackbar);
          }
          }
          else {
              const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

          }
        }).catch(error => {
          setLoading(false)

           const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

        })}
    



  }

  const handleDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
          scaledHeight = pdfHeight;
          scaledWidth = pdfHeight * aspectRatio;
      }
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10;// Adjust the value for vertical separation
      const textWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      // Add the text content below the header image
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      //doc.text(`Bill of Quantities (${reportType === "estimate" ? "Estimate" : "Contract"})`, textX, textY);
      doc.setFontSize(8); // Set font size
      doc.text("Project Location:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(state?.project_location, textX + textWidth + 5, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Project Name:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(state?.project_name, textX + textWidth + 5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.project_id}`, textX + textWidth + 5, textY + 20);
      doc.setFont('helvetica', 'bold');
      doc.text("Construction Name:", textX, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.construction_name}`, textX + textWidth + 5, textY + 25);

      doc.setFont('helvetica', 'bold');
      doc.text("Work Type:", textX, textY + 30);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.work_type}`, textX + textWidth + 5, textY + 30);

      doc.setFont('helvetica', 'bold');
      doc.text("Cost Index Date:", textX, textY + 35);
      doc.setFont('helvetica', 'normal');
      doc.text(`${formatStartDate(state?.costindex_date)}`, textX + textWidth + 5, textY + 35);

      doc.setFont('helvetica', 'bold');
      doc.text("Cost Index (%):", textX, textY + 40);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.costindex_percent}`, textX + textWidth + 5, textY + 40);

      const headerSectionHeight = scaledHeight+55; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;
      doc.setFont('helvetica', 'normal');
const body=[]
      tableData?.forEach((item,index) => {

          body.push([
            {content:index + 1, styles: { fontSize:8 }},
            {content:item?.item_code, styles: { fontSize:8 }},
            {content:item?.description, styles: { fontSize:8 }},
            {content:item?.unit_name, styles: { fontSize:8 }},
            { content: formatNumberWithCommasAndDecimals(item?.weightage), styles: { fontSize:8 }},
            { content: formatNumberWithCommasAndDecimals(item?.cost_baselocation??0), styles: { halign: "right",fontSize:8 } },
            { content: formatNumberWithCommasAndDecimals(item?.cost_projectlocation??0), styles: { halign: "right",fontSize:8 } },
            { content: formatNumberWithCommasAndDecimals(item?.percentage_increase??0), styles: { halign: "right",fontSize:8 } },
            { content: formatNumberWithCommasAndDecimals(item?.costindex_percent??0), styles: { halign: "right",fontSize:8 } },
          ])
      })

      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "Sl No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Item Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Weightage", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Base Location Rate", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Project Location Rate", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Percentage Increase", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Cost Index (%)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          2: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
          5: { halign: "right" }, // "RATE" column width
          6: { halign: "right" }, // "AMOUNT" column width
          3: { halign: "right" }, // "UNIT" column width
        },
      });

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }
      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }

      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('Cost_Index_Report.pdf');

    });
  };

  useEffect(()=>{
    console.log("workTypeList:",workTypeList)
  },[workTypeList])
  function formatStartDate(date) {
    const customDate = new Date(date)

    return moment(customDate).format('DD-MM-YYYY');
  }

  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Cost Index Details</b></Grid>
            </Grid>

            <Grid container spacing={3} >
              
              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={4} xs={12}>

                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  label="Construction Name"
                  id="constructionname_id"
                  variant="outlined"
                  sx={{ mb: 3 }}
                  name="constructionname_id"
                  value={state?.constructionname_id || ""}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Construction Name is required"]}
                >
                  {constructionNameList?.map((item) => (
                    <MenuItem value={item?.constructionname_id} key={item?.constructionname_id}>
                      {item?.construction_name}
                    </MenuItem>
                  ))}
                </DecoratedTextField>

              </Grid>
              <Grid item sm={4} xs={12}>

                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  label="Work Type"
                  id="work_type_id"
                  variant="outlined"
                  sx={{ mb: 3 }}
                  name="work_type_id"
                  value={state?.work_type_id || ""}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Work Type is required"]}
                >
                  {workTypeArr?.map((item) => (
                    <MenuItem value={item?.worktype_id} key={item?.worktype_id}>
                      {item?.worktype}
                    </MenuItem>
                  ))}
                </DecoratedTextField>

              </Grid>
              <Grid item sm={4} xs={12}>

              <DecoratedTextField
            size="small"
            select
            type="text"
            name="costindex_date"
            label="Cost Index Date(s)"
            value={formatStartDate(state?.costindex_date)}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={["Cost Index is required"]}>
            {costIndexRates?.map((item) => (
              <MenuItem value={formatStartDate(item.created_on)} key={item.costindexrate_id}>
                {formatStartDate(item.created_on)}
              </MenuItem>
            ))}
          </DecoratedTextField>
              </Grid>
              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {tableData && tableData?.length > 0 && <Grid container>
          <Grid xs={12} md={6}>
            {checkValidation(projectDetails?.header)===false&&<FormLabel sx={{color:"#ff9800"}} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
          <Grid item xs={12} md={6}>
            <Box component="span" display="flex" justifyContent="flex-end">
              <Button
                size="small"
                disabled={projectDetails?.header?false:true}
                sx={{ width: "130px", marginLeft: 4 }}
                variant="contained"
                color="warning"
                startIcon={<CloudDownload />}
                onClick={() => { handleDownload(false) }}
              >
                Download
              </Button>
              <Button
                size="small"
                sx={{ width: "100px", marginLeft: 2 }}
                variant="contained"
                color="primary"
                disabled={projectDetails?.header?false:true}
                startIcon={<Print />}
                onClick={() => { handleDownload(true) }}
              >
                Print
              </Button>
            </Box>
          </Grid>

        </Grid>}
        {tableData && tableData?.length > 0 && <Card sx={{ padding: 3 }}>

          <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
            <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

              </div>

              <Grid container spacing={0} >
                <Grid item xs={12} align='center' >
                  <FormLabel sx={{ fontSize: 22 }} ><b>Cost Index Report</b></FormLabel>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{state?.project_location}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{state?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{state?.project_id}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Construction Name: </b>{state?.construction_name}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Work Type: </b>{state?.work_type}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Cost Index Date: </b>{formatStartDate(state?.costindex_date)}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Cost Index(%): </b>{state?.costindex_percent}</FormLabel>
                </Grid>
              
              </Grid>
            </div>

            <div id="tableData">
              <TableContainer component={Paper}>
                {/* <Table>
                  <TableHead>
                    <TableRow align='center'>
                      <FormLabel sx={{ fontSize: 22 }}><b>Bill of Quantities</b></FormLabel>
                    </TableRow>
                    <TableRow>
                      <TableCell><b>Project Name: </b>{projectDetails?.project_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><b>Construction Name: </b>{projectDetails?.construction_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><b>Dzongkhag: </b>{projectDetails?.dzongkhag_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><b>BSR Year: </b>{projectDetails?.dzongkhag_name}</TableCell>
                    </TableRow>
                  </TableHead>
                </Table> */}

                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>

                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>Item Code</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Unit</TableCell>
                      <TableCell>Weightage</TableCell>
                      <TableCell>Base Location Rate</TableCell>
                      <TableCell>Project Location Rate</TableCell>
                      <TableCell>Percentage Increase</TableCell>
                      <TableCell>Cost Index (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.map((data,index) => (
                      
                        <TableRow>
                          <TableCell>{index+1}</TableCell>
                          <TableCell>{data?.item_code}</TableCell>
                          <TableCell>{data?.description}</TableCell>
                          <TableCell>{data?.unit_name}</TableCell>
                          <TableCell>{formatNumberWithCommasAndDecimals(data?.weightage??0)}</TableCell>
                          <TableCell>{formatNumberWithCommasAndDecimals(data?.cost_baselocation??0)}</TableCell>
                          <TableCell>{formatNumberWithCommasAndDecimals(data?.cost_projectlocation??0)}</TableCell>
                          <TableCell>{formatNumberWithCommasAndDecimals(data?.percentage_increase??0)}</TableCell>
                          <TableCell>{formatNumberWithCommasAndDecimals(data?.costindex_percent??0)}</TableCell>
                        </TableRow>
                     

                     
                    ))}
                    {/* <TableRow>
                      <TableCell colSpan={6} align="right" style={{ fontWeight: 600 }}><b>Total Amount</b></TableCell>
                      <TableCell colSpan={1} style={{ fontWeight: 600 }} align="right"><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          </div>
        </Card>}
  
      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}