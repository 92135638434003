import axios from 'axios';
import { ServiceUrl } from 'app/utils/Constants';
import { SHOW_LOADING } from './RoleActions';
import { GET_ALL_UNITS_INVENTORY } from './InvenoryActions';
import { AppConstants } from 'app/utils/AppConstants';

export const GET_ALL_CHAPTERS = 'GET_ALL_CHAPTER_LIST';
export const UPDATE_CURRENT_TAB = 'UPDATE_CURRENT_TAB';
export const UPDATE_CURRENT_BUILTUP_ITEM_TAB = 'UPDATE_CURRENT_BUILTUP_ITEM_TAB';
export const ADD_NEW_BML_ITEM_RATE = 'ADD_NEW_BML_ITEM_RATE'
export const ADD_NEW_BML_ITEM = 'ADD_NEW_BML_ITEM'


export const GET_ALL_UNITS = "GET_ALL_UNITS"
export const GET_ALL_BML_ITEM = 'GET_ALL_BML_ITEM'
export const GET_ALL_BML_ITEM_RATE = 'GET_ALL_BML_ITEM_RATE'
export const GET_ALL_CLASSIFICATIONS = 'GET_ALL_CLASSIFICATIONS';
export const GET_ALL_SECTION = 'GET_ALL_SECTION';
export const GET_ALL_BASE_LOCATION = 'GET_ALL_BASE_LOCATION';
export const GET_ALL_SUB_CHAPTERS = 'GET_ALL_SUB_CHAPTERS';
export const GET_ALL_CATEGORY = 'GET_ALL_CATEGORY';
export const GET_ALL_CHAPTER_TYPE = 'GET_ALL_CHAPTER_TYPE';
export const GET_ALL_SUB_CATEGORY = 'GET_ALL_SUB_CATEGORY';

export const GET_ALL_BUILTUP_ITEM_LMC = 'GET_ALL_BUILTUP_ITEM_LMC'
export const GET_WASTAGE_OVERHEAD = 'GET_WASTAGE_OVERHEAD'
export const GET_PROJECT_LOCATION_LIST = 'GET_PROJECT_LOCATION_LIST'
export const UPDATE_CURRENT_MASTER_TAB = 'UPDATE_CURRENT_MASTER_TAB'




export const getAllChaptersList = (type) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(`/api/bsr/${type}${ServiceUrl.getAllBMLOrBuiltUpChapterList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_CHAPTERS,
        payload: res?.data?.filter((c) => c.projectlocation_id === null),
      });
    } else {
      alert(res?.data.message)
    }
  });
};

export const getAllClassificationsList = (showLoader = true, action) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  if (showLoader === true) {
    dispatch({ type: SHOW_LOADING, payload: showLoader });
  }
  axios.post(ServiceUrl.getAllClassificationList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {

    if (res?.status < 300) {
      if (action === 'all') {
        // var dataList = res?.data?.map((classification) => classification.classification_id.toString())
        var dataList = res?.data?.map((classification) => classification.classification_id)
        var joinedDatas = ''
        joinedDatas = dataList?.length > 0 ? dataList?.join(',') : ''
        dispatch({
          type: GET_ALL_CLASSIFICATIONS,
          payload: res?.data?.length > 0 ? [{ classification_name: "All", classification_id: dataList }, ...res?.data] : res?.data,
        });
      }
      else if (action === "copy_bml_all") {
        var dataList = res?.data?.map((classification) => classification.classification_id)
        var joinedDatas = ''
        joinedDatas = dataList?.length > 0 ? dataList?.join(',') : ''
        dispatch({
          type: GET_ALL_CLASSIFICATIONS,
          payload: res?.data?.length > 0 ? [{ classification_name: "All", classification_id: joinedDatas }, ...res?.data] : res?.data,
        });
      }
      else {
        dispatch({
          type: GET_ALL_CLASSIFICATIONS,
          payload: res?.data,
        });
      }

    }
  });
};

export const saveUpdateUnit = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateUnit, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getPublishYearForBSR = () => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.get(ServiceUrl.getBSRPublishYearList, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getPublishYearForLMC = () => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.get(ServiceUrl.getLMCPublishYear, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getAllUnitList = (showLoader = true, type = 'others') => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  if (showLoader === true) {
    dispatch({ type: SHOW_LOADING, payload: showLoader });
  }
  axios.post(ServiceUrl.getAllUnitList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      if (type === 'inventory') {
        dispatch({
          type: GET_ALL_UNITS_INVENTORY,
          payload: res?.data,
        });
      } else {
        dispatch({
          type: GET_ALL_UNITS,
          payload: res?.data,
        });
      }

    } else {
      alert(res?.data.message)
    }
  });
};

export const getAllSectionList = () => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(ServiceUrl.getAllSectionList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_SECTION,
        payload: res?.data,
      });
    } else {
      console.log(res?.data.error)
      alert(res?.data.error)
    }
  });
};

export const getAllSectionListByClassificationId = (classificationId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.bsrBaseUrl + `${classificationId}/getAllSectionListByClassificationId`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllSubCategoryListByCategoryId = (category_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.bsrBaseUrl + `${category_id}/getAllSubCategoryListByCategoryId`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllSubChapterListById = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`${ServiceUrl.getAllSubChapterListById}?classification_id=${params.classification_id}&section_id=${params.section_id}&chapter_id=${params.chapter_id}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllChapterListById = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`${ServiceUrl.getAllChapterListByClassificationIdAndSectionId}?classification_id=${params.classification_id}&section_id=${params.section_id}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllChapterListForProjectLocationId = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`${ServiceUrl.getAllChapterListForProjectLocationId}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

//getAllChapterListForProjectLocationId

// export const getAllChapterListById = (params) => {
//   const accessToken = window.localStorage.getItem('accessToken')
//   return axios.post(`${ServiceUrl.saveupbu}?classification_id=${params.classification_id}&section_id=${params.section_id}`, {},{headers: {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${accessToken}`
//  }})
// };

export const getAllCategoryListById = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`${ServiceUrl.getAllCategoryListBychapterIdAndSubChapterIdAndSectionId}?section_id=${params.section_id}&chapter_id=${params.chapter_id}&subchapter_id=${params.subchapter_id}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllCategoryList = (type) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(`/api/bsr/${type}${ServiceUrl.getAllBMLOrBuiltUpCategoryList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_CATEGORY,
        payload: res?.data,
      });
    } else {
      alert(res?.data.message)
    }
  });
};

export const getAllChapterTypeList = () => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(ServiceUrl.getAllChapterTypeList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_CHAPTER_TYPE,
        payload: res?.data,
      });
    } else {
      alert(res?.data.message)
    }
  });
};

///getAllChapterTypeList

export const getAllSubCategoryList = (type) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(`/api/bsr/${type}${ServiceUrl.getAllAllBMLOrBuiltUpSubCategoryList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_SUB_CATEGORY,
        payload: res?.data,
      });
    } else {
      alert(res?.data.message)
    }
  });
};


export const getAllBMLItemListByCriteria = (params, onSuccess, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(ServiceUrl.getAllBMLItemListByCriteria, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_BML_ITEM,
        payload: res?.data?.obj,
      });
      onSuccess()
    } else {
      showToast(res?.data.message)
    }
  });
};

export const getWastageOverhead = (params) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(ServiceUrl.getWastageWaterOverhead, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_WASTAGE_OVERHEAD,
        payload: res?.data,
      });
    } else {
      alert(res?.data.message)
    }
  });
};

export const getAllBuildUpItemLMCListByCriteria = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllBuildUpItemLMCListByCriteria, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBuildUpItemLMCRateListByCriteria = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllBuildUpItemLMCListForBuildUpItemRate, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBMLItemListByCriteriaWithValue = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllBMLItemListByCriteria, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBMLAndBuiltUpItemListByCriteria = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllBMLItemListByCriteria, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const saveUpdateAndDeleteBuildUpItem = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateAndDeleteBuildUpItem, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveAndUpdateBuiltUpItemLmcList = (year, params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/bsr/${year}${ServiceUrl.saveAndUpdateBuiltUpItemLmcList}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const updateBuildUpItemLMCDetails = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.updateBuildUpItemLMCDetails, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBMLItemRateListByCriteria = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllBMLItemRateListByCriteria, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBuildUpItemLMCDetailsListByLmcId = (builtuplmc_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/bsr/${builtuplmc_id}${ServiceUrl.getAllBuildUpItemLMCDetailsListByLmcId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const UpdateStatusBMLOrBuiltUpItemsForPublish = (type, publishDate) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/bsr/${type}/${publishDate}${ServiceUrl.UpdateStatusBMLOrBuiltUpItemsForPublish}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getBMLOrBuiltUpItemRateDetails = (type) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/bsr/${type}${ServiceUrl.getBMLOrBuiltUpItemRateDetails}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const checkBMLPublishStatus = () => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.checkBMLPublishStatus, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const checkBuiltUpPublishStatus = () => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.checkBuiltUpPublishStatus, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const deleteBuildUpItemLMCDetails = (builtuplmcdetails_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${builtuplmcdetails_id}${ServiceUrl.deleteBuildUpLMCDetails}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteBMLItemRate = (bmlrate_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${bmlrate_id}${ServiceUrl.deleteBMLItemRate}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteBuildUpItemLMCRate = (builtupmlmcrate_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${builtupmlmcrate_id}${ServiceUrl.deleteBuildUpLMCRate}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteBuildUpItemLMC = (builtupmlmc_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${builtupmlmc_id}${ServiceUrl.deleteBuildUpLMC}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteProjectLocationBuildUpItemLMC = (builtupmlmc_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/project/${builtupmlmc_id}${ServiceUrl.deleteProjectLocationBsrBuiltupItems}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBaseLocationList = () => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(ServiceUrl.getAllBaseLocationList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_BASE_LOCATION,
        payload: res?.data,
      });
    } else {
      alert(res?.data.message)
    }
  });
};

export const getAllSubChapterList = (type) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(`/api/bsr/${type}${ServiceUrl.getAllBMLOrBuiltUpSubChapterList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_SUB_CHAPTERS,
        payload: res?.data,
      });
    } else {
      alert(res?.data.message)
    }
  });
};


export const saveUpdateBaseLocation = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateBaseLocation, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteBaseLocation = (baselocation_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${baselocation_id}${ServiceUrl.deleteBaseLocation}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateClassification = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateClassification, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteClassification = ({ classification_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${classification_id}${ServiceUrl.deleteClassification}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateSection = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateDeleteSection, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteSection = ({ section_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${section_id}${ServiceUrl.deleteSection}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateChapter = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateDeleteChapter, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteChapter = ({ chapter_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${chapter_id}${ServiceUrl.deleteChapter}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateSubChapter = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateDeleteSubChapter, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteSubChapter = ({ sub_chapter_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${sub_chapter_id}${ServiceUrl.deleteSubChapter}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateCategory = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateDeleteCategory, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteCategory = ({ category_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${category_id}${ServiceUrl.deleteCategory}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const generatePdfBMLRatesForDashBoard = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/bsr/dashBoard${ServiceUrl.generateMultipleBaseLocationPdfBMLRatesForDashBoard}`, params, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const generatePdfLMCDetailsForDashBoard = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')

  return axios.post(`/api/bsr/dashBoard/${params?.year}/${params?.classification_id}${ServiceUrl.generatePdfLMCDetailsForDashBoard}`, {}, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const saveUpdateSubCategory = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateAndDeleteSubCategory, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteSubCategory = ({ sub_category_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${sub_category_id}${ServiceUrl.deleteSubCategory}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateBMLItem = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateAndDeleteBMLItem, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const updateBMLItemBuildUpItemLMC = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.updateBMLItemBuildUpItemLMC, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
//

export const saveUpdateBMLItemRate = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateAndDeleteBMLItemRate, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrupdateBuildUpItemRate = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrupdateBuildUpItemRate, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBuildUpItemLMCDetailsListForBuildUpItemRate = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllBuildUpItemLMCDetailsListForBuildUpItemRate, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const downloadBMLItemRateExcel = (bml_year, bl_id, cl_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios({
    url: `/api/bsr/export/${bml_year}/${bl_id}/${cl_id}${ServiceUrl.downloadBMLItemRateExcel}`,
    method: 'GET',
    headers: { 'Authorization': `Bearer ${accessToken}` },
    responseType: 'blob',
  })
}

export const generatePdfBMLRates = (year, classification_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios({
    url: `/api/bsr/${year}/${classification_id}${ServiceUrl.generatePdfBMLRates}`,
    method: 'POST',
    headers: { 'Authorization': `Bearer ${accessToken}` },
    responseType: 'blob',
  })
}

export const uploadBMLItemRateExcel = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.uploadBMLItemRateExcelL, params, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}


export const downloadBuiltupItemRateExcel = (year) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios({
    url: `/api/bsr/${year}${ServiceUrl.exportExcelBuildUp}`,
    method: 'GET',
    headers: { 'Authorization': `Bearer ${accessToken}` },
    responseType: 'blob',
  })
}

export const uploadBuiltupItemRateExcel = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.importExcelBuildUp, params, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const getAllProjectLocationListForBSR = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllProjectLocationListForProjectBOQ, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_LOCATION_LIST,
        payload: res?.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(err => {
    hideLoader()
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};

export const getBSRPublishDetails = () => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.get(ServiceUrl.getBSRPublishDetails, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getLMCPublishDetails = () => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.get(ServiceUrl.getLMCPublishDetails, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

