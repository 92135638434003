import axios from 'axios';
import { ServiceUrl } from 'app/utils/Constants';
import { SHOW_LOADING } from './RoleActions';
import { AppConstants } from 'app/utils/AppConstants';

export const GET_ALL_AGENCY_TYPE_LIST = 'GET_ALL_AGENCY_TYPE_LIST';
export const GET_ALL_PARENT_AGENCY_LIST = 'GET_ALL_PARENT_AGENCY_LIST';

export const GET_ALL_AGENCY_LIST_BY_ID = 'GET_ALL_AGENCY_LIST_BY_ID';
export const SAVE_UPDATE_AGENCY_DETAILS = 'SAVE_UPDATE_AGENCY_DETAILS';
export const SAVE_AGENCY_LOGO = "SAVE_AGENCY_LOGO"
export const GET_AGENCY_LOGO = "GET_AGENCY_LOGO"

export const GET_ALL_DEPARTMENT_LIST = 'GET_ALL_DEPARTMENT_LIST';
export const GET_ALL_DIVISION_LIST = 'GET_ALL_DIVISION_LIST';
export const GET_ALL_SECTION_LIST = 'GET_ALL_SECTION_LIST';
export const UPDATE_CURRENT_AGENCY_TAB = 'UPDATE_CURRENT_AGENCY_TAB';


export const getAllAgencyListByParentId = (parentId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllAgencyListByParentId + '?ParentId=' + parentId, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteAgencyType = (agencyTypeId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/agency/${agencyTypeId}${ServiceUrl.deleteAgencyType}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteAgencybyAgencyId = (agencyId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/agency/${agencyId}${ServiceUrl.deleteAgency}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllDivisionListByParentId = (parentId, showLoading, hideLoading, showToast, handleSuccess) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading()
  axios.post(ServiceUrl.getAllAgencyListByParentId + '?ParentId=' + parentId, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_DIVISION_LIST,
        payload: res?.data,
      });
      handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  });
};

export const getAllSectionListByParentId = (parentId) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  axios.post(ServiceUrl.getAllAgencyListByParentId + '?ParentId=' + parentId, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_SECTION_LIST,
        payload: res?.data,
      });
    } else {
      alert(res?.data?.message)
    }
  }).catch(function (error) {
    alert(error?.response?.data?.errorMessage ?? error?.message);;
  });
};

export const getAllDepartmentListByParentId = (parentId, showLoading, hideLoading, showToast, handleSuccess) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading()
  axios.post(ServiceUrl.getAllAgencyListByParentId + '?ParentId=' + parentId, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_DEPARTMENT_LIST,
        payload: res?.data,
      });
      handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  });
};


export const getAllAgencyTypeList = (showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading && showLoading()
  axios.post(ServiceUrl.getAllAgencyTypeList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading && hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_AGENCY_TYPE_LIST,
        payload: res?.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoading && hideLoading()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  });
};


export const getAllParentAgencyList = (agencyTypeId, showLoading, hideLoading, showToast, handleSuccess) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading()
  axios.post(ServiceUrl.getAllParentAgencyList + '?agencyTypeId=' + agencyTypeId, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_PARENT_AGENCY_LIST,
        payload: res?.data,
      });
      handleSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(err => {
    hideLoading()
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};

export const getAllParentAgencyListByAgencyType = (agencyTypeId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllParentAgencyList + '?agencyTypeId=' + agencyTypeId, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateAgencyDetails = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateAgencyDetails, params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const saveUpdateAgencyLogo = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateAgencyLogo, params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateAgencyType = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateAgencyType, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};