import axios from 'axios';
import { ServiceUrl } from 'app/utils/Constants';

export const GET_HIRING_AGENCY_LIST = 'GET_HIRING_AGENCY_LIST';
export const GET_MACHINERY_HIRING_TYPE_LIST = "GET_MACHINERY_HIRING_TYPE_LIST";
export const GET_MACHINERY_REGISTRY_LIST = "GET_MACHINERY_REGISTRY_LIST"
export const GET_MACHINERY_HIRING_LIST = 'GET_MACHINERY_HIRING_LIST'
export const GET_PROJECT_LOCATION_LIST_HIRING = "GET_PROJECT_LOCATION_LIST_HIRING";
export const GET_SUPPLY_ORDER_LIST = 'GET_SUPPLY_ORDER_LIST'
export const GET_HIRING_BILL_LIST = 'GET_HIRING_BILL_LIST'
export const GET_DEPLOYMENT_ORDER_LIST = 'GET_DEPLOYMENT_ORDER_LIST'

export const getAllHiringAgencyList = (showLoader, hideLoader, showToast) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    showLoader()
    axios.post(ServiceUrl.getAllHiringAgencyList, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then((res) => {
        hideLoader !== false && hideLoader()
        if (res?.status < 300) {
            dispatch({
                type: GET_HIRING_AGENCY_LIST,
                payload: res?.data?.obj,
            });
        } else {
            showToast(res?.data?.message)
        }
    }).catch(function (error) {
        hideLoader !== false && hideLoader()
        showToast(error?.response?.data?.errorMessage ?? error?.message);;
    })
};

export const getAllHiringAgencyListByResourceRequisitionId = (resourcerequisition_id, showLoader, hideLoader, showToast) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    showLoader()
    axios.post(`/api/implementation/resourceForecast/${resourcerequisition_id}${ServiceUrl.getAllHiringAgencyListByResourceRequisitionId}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then((res) => {
        hideLoader !== false && hideLoader()
        if (res?.status < 300) {
            dispatch({
                type: GET_HIRING_AGENCY_LIST,
                payload: res?.data
            });
        } else {
            showToast(res?.data?.message)
        }
    }).catch(function (error) {
        hideLoader !== false && hideLoader()
        showToast(error?.response?.data?.errorMessage ?? error?.message);;
    })
};

export const getAllMachineryList = (showLoader, hideLoader, showToast) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    showLoader()
    axios.post(ServiceUrl.getAllMachineryList, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then((res) => {
        hideLoader()
        if (res?.status < 300) {
            dispatch({
                type: GET_MACHINERY_REGISTRY_LIST,
                payload: res?.data?.obj,
            });
        } else {
            showToast(res?.data?.message)
        }
    }).catch(function (error) {
        hideLoader()
        showToast(error?.response?.data?.errorMessage ?? error?.message);;
    })
};

export const getMachineryHiringListForHiringBill = (showLoader, hideLoader, showToast, project_id = 0, handleSuccess) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    showLoader !== false && showLoader()
    axios.post(`/api/implementation/hiringAgency/${project_id}${ServiceUrl.getMachineryHiringListForHiringBill}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then((res) => {
        hideLoader && hideLoader()
        if (res?.status < 300 && res?.data?.success) {
            dispatch({
                type: GET_MACHINERY_HIRING_LIST,
                payload: res?.data?.obj,
            });
            handleSuccess()
        } else {
            showToast(res?.data?.message)
        }
    }).catch(function (error) {
        hideLoader && hideLoader()
        showToast(error?.response?.data?.errorMessage ?? error?.message);
    })
};

export const getHiringBillList = (showLoader, hideLoader, showToast, status = 'SUBMITTED', project_location_id = 0, handleSuccess) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    showLoader !== false && showLoader()
    axios.post(`/api/implementation/musterRoll/${status}/${project_location_id}${ServiceUrl.getAllHiringLogbookListByStatusAndProjectLocationId}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then((res) => {
        hideLoader && hideLoader()
        if (res?.status < 300 && res?.data?.success) {
            dispatch({
                type: GET_HIRING_BILL_LIST,
                payload: res?.data?.obj,
            });
            handleSuccess()
        } else {
            dispatch({
                type: GET_HIRING_BILL_LIST,
                payload: [],
            });
            showToast(res?.data?.message)
        }
    }).catch(function (error) {
        hideLoader && hideLoader()
        dispatch({
            type: GET_HIRING_BILL_LIST,
            payload: [],
        });
        showToast(error?.response?.data?.errorMessage ?? error?.message);
    })
};

export const getAllSupplyOrderRequisitionId = (showLoader, hideLoader, showToast, requisitionId = 0, handleSuccess) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    showLoader !== false && showLoader()
    axios.post(`/api/implementation/resourceForecast/${requisitionId}${ServiceUrl.getAllSupplyOrderRequisitionId}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then((res) => {
        hideLoader()
        if (res?.status < 300 && res?.data?.success) {
            dispatch({
                type: GET_SUPPLY_ORDER_LIST,
                payload: res?.data?.obj,
            });
            handleSuccess()
        } else {
            showToast(res?.data?.message)
        }
    }).catch(function (error) {
        hideLoader()
        showToast(error?.response?.data?.errorMessage ?? error?.message);;
    })
};

export const getAllSupplyOrderProjectLocationId = (showLoader, hideLoader, showToast, project_id = 0, handleSuccess, status_id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    showLoader !== false && showLoader()
    axios.post(`/api/implementation/resourceForecast/${project_id}${ServiceUrl.getAllSupplyOrderProjectLocationId}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then((res) => {
        hideLoader()
        if (res?.status < 300 && res?.data?.success) {
            const filteredArray = res?.data?.obj?.filter((element) => element?.status_id === status_id)
            dispatch({
                type: GET_SUPPLY_ORDER_LIST,
                payload: filteredArray
            });
            handleSuccess()
        } else {
            showToast(res?.data?.message)
        }
    }).catch(function (error) {
        hideLoader()
        showToast(error?.response?.data?.errorMessage ?? error?.message);;
    })
};

export const getAllDeploymentOrderByProjectLocationId = (showLoader, hideLoader, showToast, project_id = 0, handleSuccess, status_id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    showLoader !== false && showLoader()
    axios.post(`/api/implementation/resourceForecast/${project_id}${ServiceUrl.getAllDeploymentOrderByProjectLocationId}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then((res) => {
        hideLoader()
        if (res?.status < 300 && res?.data?.success) {
            const filteredArray = res?.data?.obj?.filter((element) => element?.status_id === status_id)
            console.log(filteredArray)
            dispatch({
                type: GET_DEPLOYMENT_ORDER_LIST,
                payload: filteredArray ?? []
            });
            handleSuccess()
        } else {
            showToast(res?.data?.message)
        }
    }).catch(function (error) {
        hideLoader()
        showToast(error?.response?.data?.errorMessage ?? error?.message);;
    })
};



export const saveOrUpdateMachinery = (params) => {
    const accessToken = window.localStorage.getItem('accessToken')
    return axios.post(ServiceUrl.saveOrUpdateMachinery, params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })
};

export const getAllMachineryLogbookByResourceRequisitionIdAndEntryDate = (params) => {
    const accessToken = window.localStorage.getItem('accessToken')
    return axios.post(ServiceUrl.getAllMachineryLogbookByResourceRequisitionIdAndEntryDate, params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })
};


export const saveOrUpdateHiringAgency = (params) => {
    const accessToken = window.localStorage.getItem('accessToken')
    return axios.post(ServiceUrl.saveOrUpdateHiringAgency, params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })
};

export const saveOrUpdateHiringBill = (params) => {
    const accessToken = window.localStorage.getItem('accessToken')
    return axios.post(ServiceUrl.saveOrUpdateHiringBill, params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })
};

export const getMachineryHiringTypeList = (showLoader, hideLoader, showToast, scrollMachinery) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    showLoader()
    axios.post(ServiceUrl.getMachineryHiringTypeList, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }).then((res) => {
        if (scrollMachinery && scrollMachinery.current) {
            scrollMachinery.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        hideLoader()
        if (res?.status < 300) {
            dispatch({
                type: GET_MACHINERY_HIRING_TYPE_LIST,
                payload: res?.data?.obj,
            });
        } else {
            showToast(res?.data?.message)
        }
    }).catch(function (error) {
        hideLoader()
        showToast(error?.response?.data?.errorMessage ?? error?.message);;
    })
};