import { SHOW_LOADING } from "../actions/RoleActions";
import { GET_STAGE_INFORMATION_LIST } from "../actions/StageInformationAction";

  
  const initialState = {
    stageInformationList: [],
    isLoading: false
  };
  
  const StageInformationReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_STAGE_INFORMATION_LIST: {
        return {
            ...state,
            isLoading: false,
            stageInformationList: [...action.payload],
          };
      }

      case SHOW_LOADING: {
        return {
          ...state,
          isLoading: action.payload,
        };
      }

      default: {
        return {...state}
      }
    }
  };
  
  export default StageInformationReducer;
  