import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getResourceForecastDetails } from "app/redux/actions/ResourceForeCastActions";
import { ServiceUrl } from "app/utils/Constants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import jsPDF from "jspdf";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));
  
  const styles = {
    tableCell: {
      textAlign: "center"
    },
    subHeader: {
      textAlign: "center",
      fontWeight: 700
    }
  
  }
  const DecoratedTextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
  }));

export const ResourceForeCastReport=()=>{
    const dispatch = useDispatch();
    const [isDownloading, setIsDownloading] = useState(false)
    const [isPrinting, setIsPrinting] = useState(false)
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [totalAmount, setTotalAmount] = useState()
    const [shouldShowReport,setShouldShowReport]=useState(false)
    const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
    const { resourceForecastList} = useSelector((state) => state?.resourceForecast)
    const [tableData, setTableData] = useState([])
    const [filteredTableData,setFilteredTableData]=useState([])
    const [uniqueConstructionNameList,setUniqueConstructionNameList]=useState()
    const [state, setState] = useState({
        project_location_id: '',
      })


    useEffect(() => {
        dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
      }, []);

      const showLoader = () => {
        setLoading(true)
      }
      const showToast = (message,type) => {
        const key = enqueueSnackbar(message?message:'Something went wrong', { variant: type==="info"?"info":'error' });
        type==="info"?SnackBarProperty.info(key, closeSnackbar):SnackBarProperty.error(key, closeSnackbar)
      }
    
      const hideLoader = () => {
        setLoading(false)
      }

      useEffect(()=>{console.log("state:",state)},[state])

      const handleChange = (event, source) => {

        if (event.target.name === "project_location_id") {
          setFilteredTableData([])
          setUniqueConstructionNameList([])
          setTableData([])
          setShouldShowReport(false)
          const filteredArray = reportsProjectLocationList.filter(project => {
            return project.project_location_id === event.target.value
          });
          console.log("filtered array:",filteredArray[0])
          const newState={...state}
          delete newState.constructionname_id
          setState({...newState, [event.target.name]: event.target.value,
            project_name : filteredArray[0]?.project_name,
            project_location_id : filteredArray[0]?.project_location_id,
            project_id : filteredArray[0]?.project_id,
            project_location:filteredArray[0]?.project_location
         });
          handleFormSubmit(event.target.value,filteredArray[0])
        }
        else if (event.target.name === "constructionname_id") {
          setShouldShowReport(false)
          setTableData([])
          const filteredArray = filteredTableData.filter(project => {
            return project.constructionname_id === event.target.value
          });
          setTableData(filteredArray)
          const grand_total = filteredArray?.reduce((sum, item) => sum + parseFloat(parseFloat(item?.rate ? item?.rate : 0) * parseFloat(item?.qty ? item?.qty : 0)), 0)
          setTotalAmount(parseFloat(grand_total).toFixed(2))
          setState({ ...state, [event.target.name]: event.target.value });
    
        } else {
          setState({ ...state, [event.target.name]: event.target.value });
        }
      }
    
    
      const handleFormSubmit = (id,project) => {
        setFilteredTableData([])
        dispatch(getResourceForecastDetails(id,0,showLoader,hideLoader,showToast ))
        
      }

      useEffect(()=>{
        if(resourceForecastList?.length>0){
      setFilteredTableData(resourceForecastList)
    
        }
      },[resourceForecastList])


      
     
     
      useEffect(()=>{
        if(filteredTableData&&filteredTableData?.length>0){
           
            const filteredConstructions= Array.from(new Set(filteredTableData.map(item => item.constructionname_id)))
           .map(constructionname_id => filteredTableData.find(item => item.constructionname_id === constructionname_id));
           setUniqueConstructionNameList(filteredConstructions)
           setTableData([])
            } 
      },[filteredTableData])


      const handleReportDownload = (isPrint) => {
        const doc = new jsPDF();
        const contentHeight = 150;
        if (isPrint) {
          setIsPrinting(true)
          setIsDownloading(false)
        }
        else {
          setIsPrinting(false)
          setIsDownloading(true)
        }
        const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image
    
        doc.setFontSize(12); // Set font size
    
    
        // html2canvas(headerImage, {
        //   useCORS: true, // Add this line if the image is from a different domain
        //   allowTaint: true, // Add this line if the image is from a different domain
        // }).then((canvas) => {
        //   const imgData = canvas.toDataURL("image/png");
    
          // Calculate the width and height of the header image based on the PDF page width
        //   const pdfWidth = doc.internal.pageSize.getWidth() - 50;
        //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        //   const headerHeight = pdfHeight;
        //   const tableY = headerHeight + 15;
          // Insert the header image into the PDF
          const topBorderPadding = 20; // Adjust the padding value as needed
          doc.setDrawColor(255, 255, 255); // Black color for border
          doc.setLineWidth(topBorderPadding); // Border width (padding)
    
          // Draw the top border (padding)
         // doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);
    
          // Insert the header image into the PDF
        //   doc.addImage(imgData, "PNG", 25, topBorderPadding, pdfWidth, pdfHeight);
          const textX = 15;
          const textY = 20 + 5;
          doc.setFont('helvetica', 'bold'); // Set font to bold
          doc.setFontSize(12); // Set font size
    
          doc.text("Resource Forecast Report", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("Resource Forecast Report") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), topBorderPadding );
    
          doc.setFontSize(8); // Set font size
          doc.text("Project Location:", 15, topBorderPadding +20);
          const firstTextWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
          doc.setFont('helvetica', 'normal');
          doc.text(`${state?.project_location}`, 15 + firstTextWidth + 5, topBorderPadding +20);
          doc.setFont('helvetica', 'bold');
          doc.text("Project Name:", 15, topBorderPadding + 25);
          const secondTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
          doc.setFont('helvetica', 'normal');
          doc.text(`${state?.project_name}`, 15 + secondTextWidth + 5, topBorderPadding + 25);
          doc.setFont('helvetica', 'bold');
          doc.text("Project Id:", 15, topBorderPadding + 30);
          const thirdTextWidth = doc.getStringUnitWidth("Project Id:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
          doc.setFont('helvetica', 'normal');
          doc.text(`${state?.project_id}`, 15 + thirdTextWidth + 5, topBorderPadding + 30);
    
          doc.setFont('helvetica', 'normal');
    
          const headerSectionHeight = 60; // Adjust the value as needed
    
          // Calculate the starting Y-coordinate for the table
          const tableStartY = headerSectionHeight;
          doc.setFont('helvetica', 'normal');
          const body = []
    
    
          filteredTableData && filteredTableData?.length > 0 && filteredTableData?.forEach((elem,index) => {
    
            body.push(
              [index+1,
              elem?.bml_item,
              elem?.description,
              { content: parseFloat(elem?.quantity?elem?.quantity:0).toFixed(2), styles: { halign: "right" } },
              elem?.unit_name,
              { content: formatNumberWithCommasAndDecimals(elem?.rate ? elem?.rate : 0), styles: { halign: "right" } },
              { content: formatNumberWithCommasAndDecimals(elem?.amount ? elem?.amount : 0), styles: { halign: "right" } }
              ]
            )
    
    
    
          })
        //   if (filteredTableData?.length > 0) {
        //     body.push([{ content: "Grand Total", colSpan: 6, styles: { fontStyle: "bold", valign: "middle", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold" } }])
        //   }
          doc.autoTable({
            startY: topBorderPadding+40,
            head: [[
              { content: "SL No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
              { content: "Item Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
              { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
              { content: "Quantity", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
              { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
              { content: "Rate", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
              { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }
            ]],
            body: body, // Use the transformed data here
            theme: "grid",
            styles: {
              fillColor: [255, 255, 255], // Remove background color from the table
              lineColor: [0, 0, 0], // Set border color for main table
              lineWidth: 0.1, // Set border width for main table
              textColor: [0, 0, 0], // Set text color to black
              cellPadding: 2, // Add padding to cells
            },
            columnStyles: {
              2: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
            },
          });
    
          // Function to add footer on each page
        //   function addFooter(pageNumber) {
        //     // const footerText = "Hello CMS User";
        //     // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        //     const footerX = 0; // Starting X position for the footer
        //     const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        //     const pdfWidth = doc.internal.pageSize.getWidth();
        //     const pdfHeight = 10;
         //    doc.addImage(`${ServiceUrl?.showImageUrl}${state?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
        //   }
    
          // Get the total number of pages
        //   const totalNumPages = doc.internal.getNumberOfPages();
    
        //   // Loop through each page to add the footer with page numbers
        //   for (let i = 1; i <= totalNumPages; i++) {
        //     doc.setPage(i);
        //     addFooter(i);
        //   }
    
    
          const scaleFactor = 1.0; // Adjust this value as needed
          const pdfBlob = doc.output('blob', { scale: scaleFactor });
    
          // Create a URL for the Blob
          const pdfUrl = URL.createObjectURL(pdfBlob);
    
          // Open a new window with the PDF URL
    
          if (isPrint) {
            const newWindow = window.open(pdfUrl, '_blank');
    
            // After the new window is opened, you can close the URL object
            URL.revokeObjectURL(pdfUrl);
            if (newWindow) {
              newWindow.onload = () => {
                setIsPrinting(false)
                setIsDownloading(false)
                // Wait for the window to load and then trigger the print dialog
                newWindow.print();
              };
            }
          }
    
          setIsPrinting(false)
          setIsDownloading(false)
    
          isPrint === false && doc.save('Resource_Forecast_Report.pdf');
    
       
      };
      const handleViewReport=()=>{
        setShouldShowReport(true)
      }

    return(
        <>
        <Container>
        <div style={{ height: 'auto', width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>

<Grid container>

  <Grid item xs={12}>
    <Button
      size="small"
      sx={{ width: "100px" }}
      variant="contained"
      color="error"
      onClick={() => { window.history.back() }}
    >
      <Icon color="primary" fontSize="small">
        skip_previous
      </Icon>
      Back
    </Button>
  </Grid>
</Grid>
<SimpleCard>

  {/* <ValidatorForm onSubmit={handleFormSubmit}>
    <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
      <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Resource Forecast</b></Grid>
    </Grid>
    <Grid container spacing={3}>

      <Grid item sm={5} xs={12}>
        <DecoratedTextField
          select
          size="small"
          fullWidth
          sx={{ mb: 3 }}
          label="Project ID"
          id="project_location_id"
          variant="outlined"
          name="project_location_id"
          value={state?.project_location_id}
          onChange={handleChange}
          validators={["required"]}
          errorMessages={["Project ID is required"]}
        >
          {reportsProjectLocationList?.map((item) => (
            <MenuItem value={item?.project_location_id} key={item?.project_id}>
              {item?.project_id}
            </MenuItem>
          ))}
        </DecoratedTextField>
      </Grid>

      <Grid item sm={4} xs={12}>


        <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

      </Grid>

    </Grid>

  </ValidatorForm> */}
    <ValidatorForm onSubmit={handleViewReport}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Resource Forecast</b></Grid>
            </Grid>
            <Grid container spacing={3}>

              <Grid item sm={4} xs={12}>
                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </DecoratedTextField>
              </Grid>
              <Grid item sm={4} xs={12}>
                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Construction Name"
                  id="construction_id"
                  variant="outlined"
                  name="constructionname_id"
                  value={state?.constructionname_id||""}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Construction Name is required"]}
                >
                  {uniqueConstructionNameList?.map((item) => (
                    <MenuItem value={item?.constructionname_id} key={item?.constructionname_id}>
                      {item?.construction_name}
                    </MenuItem>
                  ))}
                </DecoratedTextField>
              </Grid>

              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
</SimpleCard>
{
  shouldShowReport&&filteredTableData && filteredTableData?.length > 0&&
  <Grid container>
    <Grid item xs={12}>
      <Box component="span" display="flex" justifyContent="flex-end">
        <Button
          size="small"
          sx={{ width: "130px", marginLeft: 4 }}
          variant="contained"
          color="warning"
          startIcon={<CloudDownload />}
          onClick={() => { handleReportDownload(false) }}
        >
          Download
        </Button>
        <Button
          size="small"
          sx={{ width: "100px", marginLeft: 2 }}
          variant="contained"
          color="primary"
          startIcon={<Print />}
          onClick={() => { handleReportDownload(true) }}
        >
          Print
        </Button>
      </Box>
    </Grid>

  </Grid>}
{/* table */}
{
 shouldShowReport&&filteredTableData && filteredTableData?.length > 0&&
  <Card sx={{ padding: 3 }}>

    <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
      <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
        {/* <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
          <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${state?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

        </div> */}

        <Grid item xs={12} align='center' >
          <FormLabel sx={{ fontSize: 22 }} ><b>Resource Forecast Report</b></FormLabel>
        </Grid>


        <Grid container >
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{state?.project_location}</FormLabel>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{state?.project_name}</FormLabel>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormLabel sx={{ fontSize: 18 }}><b>Project Id: </b>{state?.project_id}</FormLabel>
          </Grid>


        </Grid>
      </div>

      <div id="filteredTableData">
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#87CEEB' }}>
              <TableRow>
                <TableCell align="center">SL No.</TableCell>
                <TableCell align="center">Item Code</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Unit</TableCell>
                <TableCell align="center">Rate</TableCell>
                <TableCell align="center">Amount (Nu)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                filteredTableData?.map((elem,index) => {
                  return (
                    <TableRow>
                      <TableCell align="center">{index+1}</TableCell>
                      <TableCell align="center">{elem?.bml_item}</TableCell>
                      <TableCell align="center">{elem?.description}</TableCell>
                      <TableCell align="right">{parseFloat(elem?.quantity?elem?.quantity:0).toFixed(2)}</TableCell>
                      <TableCell align="center">{elem?.unit_name}</TableCell>
                      <TableCell align="right">{formatNumberWithCommasAndDecimals(elem?.rate ? elem?.rate : 0)}</TableCell>
                      <TableCell align="right">{formatNumberWithCommasAndDecimals(elem?.amount ? elem?.amount : 0)}</TableCell>
                    </TableRow>
                  )
                })

              }
              {/* {filteredTableData?.length > 0 &&
                <TableRow>
                  <TableCell colSpan={6} align="right"><b>Grand Total</b></TableCell>
                  <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                </TableRow>
              } */}

            </TableBody>
          </Table>
        </TableContainer>

      </div>
    </div>
  </Card>}

</div>
{loading && (
<Loader
  show={loading}
/>
)}
{(isPrinting || isDownloading) && (
<Loader
  show={isPrinting ? isPrinting : isDownloading}
  text="Please Wait While Generating PDF..."
/>
)}
        </Container>
        </>
    )
}