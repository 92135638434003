import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from '@material-ui/core';
  import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
  import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
  import { Loader } from "app/components";
import { getAllGoodAdjustmentListByProjectLocationId } from 'app/redux/actions/InvenoryActions';
  import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
  import { getAllRaBillNoForReport, getContractWorkPlanReport, getGoodAdjustmentDetailsReport, getRaBillAmendmentDetailsReport } from "app/redux/actions/ReportsAction";
  import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
  import { SnackBarProperty } from "app/utils/SnackBarProperty";
  import { checkValidation, formatDate, formatNumberWithCommasAndDecimals } from "app/utils/utils";
  import { SimpleCard } from "components";
  import html2canvas from "html2canvas";
  import jsPDF from "jspdf";
  import 'jspdf-autotable';
  import moment from "moment";
  import { useSnackbar } from "notistack";
  import React, { useEffect, useState } from "react";
  import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
  import { useDispatch, useSelector } from "react-redux";
  
  
  const styles = {
    tableCell: {
      textAlign: "center"
    },
    subHeader: {
      textAlign: "center",
      fontWeight: 700
    }
  
  }
  const DecoratedTextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
  }));
  
  
  
  
  export const RABillAmendmentDetailsReport = () => {
  
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isDownloading, setIsDownloading] = useState(false)
    const [isPrinting, setIsPrinting] = useState(false)
    const [projectDetails, setProjectDetails] = useState({})
    const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
    const [totalAmount,setTotalAmount]=useState(0)
    const [raBillList,setRaBillList]=useState([])
    const [headerFooterImg,setHeaderFooterImg]=useState()
    const [tableData, setTableData] = useState()
      ;
    const [state, setState] = useState({
      project_location_id: '',
    })
  
  
    useEffect(() => {
      dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
    }, []);
    const showLoader = () => {
      setLoading(true)
    }
    const showToast = (msg) => {
      const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
  
    const hideLoader = () => {
      setLoading(false)
    }
    const handleChange = (event, source) => {
  
      if (event.target.name === "project_location_id") {
        setTableData([])
        setProjectDetails({})
        setHeaderFooterImg()
        setRaBillList([])
        const filteredArray = reportsProjectLocationList.filter(project => {
          return project.project_location_id === event.target.value
        });
        // state.project_name = filteredArray[0].project_name
        // state.project_location_id = filteredArray[0].project_location_id
        // state.project_id = filteredArray[0].project_id
  
        setState({ [event.target.name]: filteredArray[0].project_location_id,
            project_name:filteredArray[0].project_name,
            project_id:filteredArray[0].project_id
         });
         setLoading(true)
         getAllRaBillNoForReport({ project_location_id: filteredArray[0].project_location_id }).then(res => {
          setLoading(false)
          if (res?.status < 300) {
            if (res?.data?.obj?.rabill_details?.length > 0) {
              setRaBillList(res?.data?.obj?.rabill_details)
            }
  
          } else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);
  
          }
        }).catch(error => {
          setLoading(false)
          const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);
  
        })
  
      } 
      else if(event.target.name === "runningaccountbill_id"){
        setTableData([])
        setProjectDetails({})
        setHeaderFooterImg()
        const filteredArray = raBillList.filter(raBill => {
          return raBill.runningaccountbill_id === event.target.value
        });
         setState({ ...state, [event.target.name]: event.target.value,raBillDate:formatDate(filteredArray?.[0]?.rabill_date),runningaccountbill_no:filteredArray?.[0]?.rabill_no});
      }
      
      else {
        setState({ ...state, [event.target.name]: event.target.value });
      }
    }
    

  
    const handleFormSubmit = () => {
      setLoading(true)
       setTableData([])
      getRaBillAmendmentDetailsReport({ project_location_id: state?.project_location_id,ra_bill_id:state?.runningaccountbill_id })
        .then((res) => {
          setLoading(false)
  
          if (res?.status < 300) {
            if (res?.data?.obj && res?.data?.obj?.item && res?.data?.obj?.item?.ra_bill_amendment_details_report&& res?.data?.obj?.item?.ra_bill_amendment_details_report?.ra_bill_amendment_details?.length > 0) {
              const tAmount=res?.data?.obj?.item?.ra_bill_amendment_details_report?.ra_bill_amendment_details?.reduce((sum, item) => sum + parseFloat(item?.amount), 0)
              setTotalAmount(tAmount)
              setTableData(res?.data?.obj?.item?.ra_bill_amendment_details_report?.ra_bill_amendment_details)
             
            }
            if (res?.data?.obj?.item&&Object?.keys(res?.data?.obj?.item)?.length>0) {
              setProjectDetails(res?.data?.obj?.item)
              setHeaderFooterImg({
                header_img:res?.data?.obj?.header_logo,
                footer_img:res?.data?.obj?.footer_logo
              })
            }
  
          }
          else {
              const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  
          }
        }).catch(error => {
          setLoading(false)
           const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  
        })
  
  
   
  
  
    }
  
    const handleReportDownload = (isPrint) => {
      if (isPrint) {
        setIsPrinting(true)
        setIsDownloading(false)
      }
      else {
        setIsPrinting(false)
        setIsDownloading(true)
      }
      const doc = new jsPDF();
      const contentHeight = 150;
      const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image
  
      doc.setFontSize(12); // Set font size
  
  
      html2canvas(headerImage, {
        useCORS: false, // Add this line if the image is from a different domain
        allowTaint: true, // Add this line if the image is from a different domain
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
  
        const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
          scaledHeight = pdfHeight;
          scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10; 
        doc.setFont('helvetica', 'bold'); // Set font to bold
        doc.setFontSize(12); // Set font size
        doc.text("RA BILL AMENDMENT DETAILS", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("RA BILL AMENDMENT DETAILS") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY );
        
        
        const textWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        
        doc.setFontSize(8); // Set font size
        doc.text("Project Location:", textX, textY+10);
        doc.setFont('helvetica', 'normal');
        doc.text(projectDetails?.project_location, textX + textWidth + 5, textY+10);
        doc.setFont('helvetica', 'bold');
        doc.text("Project Name:", textX, textY + 15);
        doc.setFont('helvetica', 'normal');
        doc.text(projectDetails?.project_name, textX + textWidth + 5, textY + 15);
        doc.setFont('helvetica', 'bold');
        doc.text("Project ID:", textX, textY + 20);
        doc.setFont('helvetica', 'normal');
        doc.text(`${projectDetails?.project_id}`, textX + textWidth + 5, textY + 20);

        doc.setFont('helvetica', 'bold');
        doc.text("RA Bill No.:", textX, textY + 25);
        doc.setFont('helvetica', 'normal');
        doc.text(`${state?.runningaccountbill_no??""}`, textX + textWidth + 5, textY + 25);

        doc.setFont('helvetica', 'bold');
        doc.text("RA Bill Date:", (doc.internal.pageSize.getWidth() / 2), textY + 25);
        doc.setFont('helvetica', 'normal');
        doc.text(`${state?.raBillDate??""}`, (doc.internal.pageSize.getWidth() / 2) + textWidth - 5, textY + 25);
        doc.setFont('helvetica', 'bold');
        doc.text("Amendment Amount:", textX, textY + 30);
        doc.setFont('helvetica', 'normal');
        doc.text(`${parseFloat(projectDetails?.ra_bill_amendment_details_report?.amendment_amount??0)?.toFixed(2)}`, textX + textWidth + 5, textY + 30);
        doc.setFont('helvetica', 'bold');
        doc.text("Amendment Date:", (doc.internal.pageSize.getWidth() / 2), textY + 30);
        doc.setFont('helvetica', 'normal');
        doc.text(`${formatDate(projectDetails?.ra_bill_amendment_details_report?.amendment_date)}`, (doc.internal.pageSize.getWidth() / 2) + textWidth - 5, textY + 30);
  
        const headerSectionHeight = scaledHeight+45; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
        const tableStartY = headerSectionHeight + 5;
        doc.setFont('helvetica', 'normal'); // Reset font to normal
        function transformData(data) {
          const transformedData = [];
          data.forEach((row,index) => {

            transformedData.push([
              {content:`${index+1}`,styles:{fontSize:8}},
              {content:row?.construction_type,styles:{fontSize:8}},
              {content:row?.construction_name??"",styles:{fontSize:8}},
              {content:row?.name??"",styles:{fontSize:8}},
              {content:row?.item_code??"0",styles:{fontSize:8}},
              {content:row?.description??"0",styles:{fontSize:8}},
              {content:row?.unit??"0",styles:{fontSize:8}},
              {content:row?.rate??"",styles:{fontSize:8}},
              {content:row?.quantity??"",styles:{fontSize:8}},
              {content:formatNumberWithCommasAndDecimals(row?.amount??0),styles:{fontSize:8}}
              ]);
  
            })
            transformedData?.push([{ content: "Total Amount", colSpan: 9, styles: { fontStyle: "bold", valign: "middle", halign: "right",fontSize:8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount??0), styles: { halign: "right", fontStyle: "bold",fontSize:8 } }])
          return transformedData;
        }
  
        const transformedData = transformData(tableData);
  
  
        doc.autoTable({
          startY: tableStartY,
          head: [[
            { content: "SL NO.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "Construction Type", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "Construction Name", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "Name", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "Item Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "Rate (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "Quantity", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
            { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }
          ]],
          body: transformedData, // Use the transformed data here
          theme: "grid",
          styles: {
            fillColor: [255, 255, 255], // Remove background color from the table
            lineColor: [0, 0, 0], // Set border color for main table
            lineWidth: 0.1, // Set border width for main table
            textColor: [0, 0, 0], // Set text color to black
            cellPadding: 2, // Add padding to cells
          },
          columnStyles: {
            // 1: { cellWidth: 30 },
            0: { halign: "center" },
            1: { halign: "center" },
            2: { halign: "center" },
            3: { halign: "center" },
            4: { halign: "center" },
            5: { halign: "center" },
            6: { halign: "center" },

            //   3: { halign: "right" }, // "UNIT" column width
          },
        });
  
        // Function to add footer on each page
        function addFooter(pageNumber) {
          // const footerText = "Hello CMS User";
          // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
          const footerX = 0; // Starting X position for the footer
          const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = 10;

          doc.addImage(`${ServiceUrl?.showImageUrl}${headerFooterImg?.footer_img}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
        }
  
        // Get the total number of pages
        const totalNumPages = doc.internal.getNumberOfPages();
  
        // Loop through each page to add the footer with page numbers
        for (let i = 1; i <= totalNumPages; i++) {
          doc.setPage(i);
           addFooter(i);
        }
        const scaleFactor = 1.0; // Adjust this value as needed
        const pdfBlob = doc.output('blob', { scale: scaleFactor });
  
        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        // Open a new window with the PDF URL
  
        if (isPrint) {
          const newWindow = window.open(pdfUrl, '_blank');
  
          // After the new window is opened, you can close the URL object
          URL.revokeObjectURL(pdfUrl);
          if (newWindow) {
            newWindow.onload = () => {
              setIsPrinting(false)
              setIsDownloading(false)
              // Wait for the window to load and then trigger the print dialog
              newWindow.print();
            };
          }
        }
  
        setIsPrinting(false)
        setIsDownloading(false)
  
        isPrint === false && doc.save(`ra_bill_adjustment_details.pdf`);
  
      });
    };
  
  
  
  
  
  
  
  
  
  
    return (
      <>
        <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>
  
          <Grid container>
  
            <Grid item xs={12}>
              <Button
                size="small"
                sx={{ width: "100px" }}
                variant="contained"
                color="error"
                onClick={() => { window.history.back() }}
              >
                <Icon color="primary" fontSize="small">
                  skip_previous
                </Icon>
                Back
              </Button>
            </Grid>
          </Grid>
          <SimpleCard>
            <ValidatorForm onSubmit={handleFormSubmit}>
              <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
                <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>RA Bill Amendment Details</b></Grid>
              </Grid>
              <Grid container spacing={3} >
              
                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Project ID"
                    id="project_location_id"
                    variant="outlined"
                    name="project_location_id"
                    value={state?.project_location_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Project ID is required"]}
                  >
                    {reportsProjectLocationList?.map((item) => (
                      <MenuItem value={item?.project_location_id} key={item?.project_id}>
                        {item?.project_id}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    disabled
                    label="Project Name"
                    id="project_name"
                    variant="outlined"
                    name="project_name"
                    value={state?.project_name??""}
                    validators={["required"]}
                    errorMessages={["Project Name is required"]}
                  >
                  </DecoratedTextField>
                </Grid>
  
              </Grid>
              <Grid container spacing={3} >
              <Grid item sm={4} xs={12}>
              <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="RA Bill No."
                  id="runningaccountbill_id"
                  variant="outlined"
                  name="runningaccountbill_id"
                  value={state?.runningaccountbill_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["RA Bill No. is required"]}
                >
                  {raBillList?.map((item) => (
                    <MenuItem value={parseInt(item?.runningaccountbill_id)} key={item?.runningaccountbill_id}>
                      {item?.rabill_no}
                    </MenuItem>
                  ))}
                </DecoratedTextField>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    disabled
                    label="RA Bill Date."
                    id="ra_bill_date"
                    variant="outlined"
                    name="ra_bill_date"
                    value={state?.raBillDate??""}
                    validators={["required"]}
                    errorMessages={["RA Bill Date is required"]}
                  >
                  </DecoratedTextField>
                </Grid>
               
  
                <Grid item sm={4} xs={12}>
  
  
                  <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>
  
                </Grid>
                </Grid>
  
            </ValidatorForm>
          </SimpleCard>
          {tableData && tableData?.length > 0 && 
          <Grid container>
            <Grid xs={12} md={6}>
              {checkValidation(headerFooterImg?.header_img)===false&&<FormLabel sx={{color:"#ff9800"}} color="warning">! Please upload header and footer for download/print</FormLabel>}
              </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  disabled={headerFooterImg?.header_img?false:true}
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  color="warning"
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  disabled={headerFooterImg?.header_img?false:true}
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  color="primary"
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>
  
          </Grid>}
          {projectDetails && Object?.keys(projectDetails)?.length > 0 && <Card sx={{ padding: 3 }}>
  
            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${headerFooterImg?.header_img}`} style={{ height: "150px", width: "auto" }} alt="img" />
  
                </div>
  
                <Grid container >
                  <Grid item xs={12} align='center' >
                    <FormLabel sx={{ fontSize: 22 }} ><b>RA BILL AMENDMENT DETAILS</b></FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{projectDetails?.project_location??""}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name??""}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id??""}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>RA Bill No.: </b>{state?.runningaccountbill_no??""}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>RA Bill Date: </b>{state?.raBillDate??""}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Amendment Amount: </b>{parseFloat(projectDetails?.ra_bill_amendment_details_report?.amendment_amount??0)?.toFixed(2)}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Amendment Date: </b>{formatDate(projectDetails?.ra_bill_amendment_details_report?.amendment_date)}</FormLabel>
                  </Grid>
  
  
                </Grid>
              </div>
  
            {tableData&&tableData?.length>0&&  <div id="tableData">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ backgroundColor: '#87CEEB' }}>
                      <TableRow>
                        <TableCell>SL No.</TableCell>
                        <TableCell>Construction Type</TableCell>
                        <TableCell>Construction Name</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Item Code</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Unit</TableCell>
                        <TableCell>Rate (Nu)</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Amount (Nu)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        tableData?.map((row,index) => (
                              <TableRow key={index}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell>{row?.construction_type??""}</TableCell>
                                <TableCell>{row?.construction_name??""}</TableCell>
                                <TableCell>{row?.name??""}</TableCell>
                                <TableCell>{row?.item_code??""}</TableCell>
                                <TableCell>{row?.description??""}</TableCell>
                                <TableCell>{row?.unit??""}</TableCell>
                                <TableCell>{parseFloat(row?.rate??0)?.toFixed(2)}</TableCell>
                                <TableCell>{parseFloat(row?.quantity??0)?.toFixed(2)}</TableCell>
                                <TableCell align="right">{formatNumberWithCommasAndDecimals(row?.amount??0)}</TableCell>
                              </TableRow>
                        ))}
                               <TableRow >
                                <TableCell colSpan={9} align="right"><b>Total Amount</b></TableCell>
                                <TableCell align="right">{formatNumberWithCommasAndDecimals(totalAmount??0)}</TableCell>
                              </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
  
              </div>}
            </div>
          </Card>}
         
        </div>
        {loading && (
          <Loader
            show={loading}
          />
        )}
        {(isPrinting || isDownloading) && (
          <Loader
            show={isPrinting ? isPrinting : isDownloading}
            text="Processing...Please wait."
          />
        )}
  
      </>
    )
  }