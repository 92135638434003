import { GET_DEPLOYMENT_ORDER_LIST, GET_HIRING_AGENCY_LIST, GET_HIRING_BILL_LIST, GET_MACHINERY_HIRING_LIST, GET_MACHINERY_HIRING_TYPE_LIST, GET_MACHINERY_REGISTRY_LIST, GET_PROJECT_LOCATION_LIST_HIRING, GET_SUPPLY_ORDER_LIST } from "../actions/HiringAgencyActions";
import { GET_MACHINERY_TYPE_LIST } from "../actions/MusterRollActions";


const initialState = {
    hiringAgencyList: [],
    hiringMachineryTypeList: [],
    machineryRegistryList: [],
    machineryTypeList: [],
    machineryHiringList: [],
    supplyOrderList: [],
    hiringBillList: [],
    deploymentOrderList: []
};

const HiringAgencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HIRING_BILL_LIST: {
            return {
                ...state,
                hiringBillList: [...action.payload],
            };
        }
        case GET_HIRING_AGENCY_LIST: {
            return {
                ...state,
                hiringAgencyList: [...action.payload],
            };
        }
        case GET_MACHINERY_HIRING_TYPE_LIST: {
            return {
                ...state,
                hiringMachineryTypeList: [...action.payload],
            };
        }

        case GET_MACHINERY_REGISTRY_LIST: {
            return {
                ...state,
                machineryRegistryList: [...action.payload],
            };
        }

        case GET_MACHINERY_TYPE_LIST: {
            return {
                ...state,
                machineryTypeList: [...action.payload],
            };
        }

        case GET_MACHINERY_HIRING_LIST: {
            return {
                ...state,
                machineryHiringList: [...action.payload]
            }
        }

        case GET_SUPPLY_ORDER_LIST: {
            return {
                ...state,
                supplyOrderList: [...action.payload]
            }
        }

        case GET_DEPLOYMENT_ORDER_LIST: {
            return {
                ...state,
                deploymentOrderList: [...action.payload]
            }
        }

        case GET_PROJECT_LOCATION_LIST_HIRING: {
            return {
                ...state,
                projectLocationList: [...action.payload]
            }
        }


        default: {
            return { ...state };
        }
    }
};

export default HiringAgencyReducer;
