import axios, { AxiosRequestTransformer } from 'axios';
import { ServiceUrl } from 'app/utils/Constants';
import { AppConstants } from 'app/utils/AppConstants';
import { setMenu, setProfileType, setSession, setUser } from 'app/contexts/JWTAuthContext';

const axiosInstance = axios.create({ baseURL: ServiceUrl.baseURL })
//const axiosInstance = axios.create()

axios.interceptors.response.use(
    (response) => { return response },
    (error) => {
        if (error.response.status === 401) {
            setSession(null)
            setUser(null)
            setMenu(null)
            setProfileType(null)
            window.location.reload(false)
        }
        return Promise.reject(error)

    }
);

axios.defaults.baseURL = ServiceUrl.baseURL;


export default axiosInstance;
