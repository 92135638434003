import axios from 'axios';
import { ServiceUrl } from 'app/utils/Constants';

export const GET_MUSTER_ROLL_PAYMENT_REQUEST_LIST = 'GET_MUSTER_ROLL_PAYMENT_REQUEST_LIST';
export const GET_MUSTER_ROLL_PAYMENT_TYPE_LIST = 'GET_MUSTER_ROLL_PAYMENT_TYPE_LIST';
export const GET_MACHINERY_TYPE_LIST = 'GET_MACHINERY_TYPE_LIST';



export const getAllMusterRollListByProjectLocationIdAndPaymentTypeId = (project_location_id, paymenttype_id, status, showLoader, hideLoader, showToast, handleRquestListSuccess) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/implementation/musterRoll/${project_location_id}/${paymenttype_id}/${status}${ServiceUrl.getAllMusterRollListByProjectLocationIdAndPaymentTypeId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_MUSTER_ROLL_PAYMENT_REQUEST_LIST,
        payload: res.data,
      });
      handleRquestListSuccess()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const getAllPaymentTypeList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllPaymentTypeList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_MUSTER_ROLL_PAYMENT_TYPE_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const getAllMachineryTypeList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllMachineryTypeList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_MACHINERY_TYPE_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const saveOrUpdateMusterRoll = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateMusterRoll, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const getAllMachineryListByMachineryTypeId = (machinerytype_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/musterRoll/${machinerytype_id}${ServiceUrl.getAllMachineryListByMachineryTypeId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    }
  })
}


// export const deleteWorkTypeById = (worktype_id) => {
//     const accessToken = window.localStorage.getItem('accessToken')
//     return axios.delete( `/api/project/workType/${worktype_id}${ServiceUrl.deleteWorkTypeById}`,{headers: {
//         'Authorization': `Bearer ${accessToken}`
//    }})
//   };