import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const InventoryVerification = Loadable(lazy(() => import('./InventoryVerification')));
const InventoryGoodsReceipt = Loadable(lazy(() => import('./InventoryGoodsReceipt')));
const InventoryGoodsIssue = Loadable(lazy(() => import('./InventoryGoodsIssue')));
const InventoryGoodsAdjustment = Loadable(lazy(() => import('./InventoryGoodsAdjustment')));
const ItemCatalogue = Loadable(lazy(() => import('./ItemCatalogue')));


const inventoryVerificationRoutes = [
    {
        path: '/ItemCatalogue',
        element: <ItemCatalogue />
    },
    {
        path: '/inventoryVerification',
        element: <InventoryVerification />
    },
    {
        path: '/GoodsReceipt',
        element: <InventoryGoodsReceipt />
    },
    {
        path: '/GoodsIssue',
        element: <InventoryGoodsIssue />
    },
    {
        path: '/GoodsAdjustment',
        element: <InventoryGoodsAdjustment />
    }
];

export default inventoryVerificationRoutes;
