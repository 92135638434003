import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ContractList = Loadable(lazy(() => import('./ContractList')));

const contractListRoutes = [
    {
      path: '/contractList',
      element: <ContractList/>,
    }
  ];
  
  export default contractListRoutes;