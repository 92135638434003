

// export function numToWords (number) {
//     if (number === 0 || number === 0.0) {
//       return 'Zero'
//     }
//     const [r, p] = number.toString().split('.');
//     if (r !== undefined && p !== undefined) {
//       return `${numToWordsConverter(r)} point ${numToWordsConverter(p)}`
//     } else if (r !== undefined && p === undefined) {
//       return `${numToWordsConverter(r)}`
//     } else if (r === undefined && p !== undefined) {
//       return `${numToWordsConverter(p)}`
//     } else {
//       return 'Zero'
//     }

//   }

//    function numToWordsConverter (number) {
//   const first = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
//     const tens = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
//     const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
//     let word = '';

//     for (let i = 0; i < mad.length; i++) {
//       let tempNumber = number%(100*Math.pow(1000,i));
//       if (Math.floor(tempNumber/Math.pow(1000,i)) !== 0) {
//         if (Math.floor(tempNumber/Math.pow(1000,i)) < 20) {
//           word = first[Math.floor(tempNumber/Math.pow(1000,i))] + mad[i] + ' ' + word;
//         } else {
//           word = tens[Math.floor(tempNumber/(10*Math.pow(1000,i)))] + '-' + first[Math.floor(tempNumber/Math.pow(1000,i))%10] + mad[i] + ' ' + word;
//         }
//       }

//       tempNumber = number%(Math.pow(1000,i+1));
//       if (Math.floor(tempNumber/(100*Math.pow(1000,i))) !== 0) word = first[Math.floor(tempNumber/(100*Math.pow(1000,i)))] + 'hunderd ' + word;
//     }
//       return word;
//    }

// export const numToWords=(num)=>{
//   const ones = [
//     '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
//     'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
//   ];
//   const tens = [
//     '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
//   ];
//   const thousands = [  '',
//   'thousand',
//   'million',
//   'billion',
//   'trillion',
//   'quadrillion',
//   'quintillion'];

//   function convertToWords(num) {
//     if (num === 0) return 'zero';
//     if (num < 20) return ones[num];
//     if (num < 100) {
//       const ten = tens[Math.floor(num / 10)];
//       const one = ones[num % 10];
//       return ten + (one ? '-' + one : '');
//     }
//     if (num < 1000) {
//       const hundred = ones[Math.floor(num / 100)];
//       const rest = convertToWords(num % 100);
//       return hundred + ' hundred' + (rest ? ' and ' + rest : '');
//     }
//     for (let i = 0; i < thousands.length; i++) {
//       const base = Math.pow(1000, i);
//       if (num < base * 1000) {
//         const left = Math.floor(num / base);
//         const right = num - left * base;
//         const leftStr = convertToWords(left);
//         const rightStr = convertToWords(right);
//         return leftStr + ' ' + thousands[i] + (rightStr ? ' ' + rightStr : '');
//       }
//     }
//   }

//   const [integer, decimal] = num.toString().split('.');
//   const integerPart = convertToWords(parseInt(integer, 10));
//   let result = integerPart;

//   if (decimal) {
//     const decimalPart = decimal
//       .split('')
//       .map(digit => ones[parseInt(digit, 10)])
//       .join(' ');
//     result += ' point ' + decimalPart;
//   }

//   return result;
// }


export const numToWords = (num) => {
  if (num === 0) return 'zero';
  const ones = [
    '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
    'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
  ];
  const decimalOnes = [
    'zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
    'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
  ];
  const tens = [
    '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];
  const thousands = [
    '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion'
  ];

  function convertToWords(num) {
    console.log('num is', num)
    if (num === 0) return 'zero';
    if (num < 20) return ones[num];
    if (num < 100) {
      const ten = tens[Math.floor(num / 10)];
      const one = ones[num % 10];
      return ten + (one ? '-' + one : '');
    }
    if (num < 1000) {
      const hundred = ones[Math.floor(num / 100)];
      const rest = convertToWords(num % 100);
      return hundred + ' hundred' + (rest ? ' and ' + rest : '');
    }
    for (let i = 0; i < thousands.length; i++) {
      const base = Math.pow(1000, i);
      if (num < base * 1000) {
        const left = Math.floor(num / base);
        const right = num - left * base;
        const leftStr = convertToWords(left);
        const rightStr = convertToWords(right);
        return leftStr + ' ' + thousands[i] + (rightStr ? ' ' + rightStr : '');
      }
    }
  }

  // Split number into integer and decimal parts
  const integerPart = Math.floor(num);
  const decimalPart = Math.round((num - integerPart) * 100);

  // Convert integer part to words
  let words = convertToWords(integerPart);

  // Handle decimal part
  if (decimalPart > 0) {
    words += ' point';
    if (decimalPart < 10) {
      words += ` zero ${ones[decimalPart]}`; // For single-digit decimals
    } else {
      // words += ` ${convertToWords(decimalPart)}`;
      const newDecimalPart = [...`${decimalPart}`]
      newDecimalPart?.forEach((character, index) =>
        words += ` ${decimalOnes[character]}`
      )
    }
  } else {
    words += ' point zero zero'; // Handle case when decimal part is exactly zero
  }

  return words;
};


class N2WIndian {
  static convert(x) {
    let n = x.length;
    x = n === 0 ? '00' : n === 1 || n % 2 === 0 ? '0' + x : x;
    n = x.length;
    let r = N2WIndian.zeroTo99[x.charCodeAt((n -= 2)) * 10 + x.charCodeAt(n + 1) - 528];
    if (n >= 1) {
      const v = N2WIndian.zeroTo99[x.charCodeAt((n -= 1)) - 48];
      if (v)
        r = v + ' Hundred' + (r ? ' ' + r : '');
    }
    for (let i = 0; n > 0; i++) {
      const v = N2WIndian.zeroTo99[x.charCodeAt((n -= 2)) * 10 + x.charCodeAt(n + 1) - 528];
      if (v)
        r = v + ' ' + N2WIndian.place[i] + (r ? ' ' + r : '');
    }
    return r;
  }
}
N2WIndian.zeroTo99 = [];
N2WIndian.place = 'Thousand|Lakh|Crore|Arab|Kharab|Nil'.split('|');
(() => {
  const ones = '|One|Two|Three|Four|Five|Six|Seven|Eight|Nine|Ten|Eleven|Twelve|Thirteen|Fourteen|Fifteen|Sixteen|Seventeen|Eighteen|Nineteen'.split('|');
  const tens = '||Twenty|Thirty|Forty|Fifty|Sixty|Seventy|Eighty|Ninety'.split('|');
  for (let i = 0; i < 100; i++) {
    const t = Math.floor(i / 10);
    const o = i % 10;
    N2WIndian.zeroTo99.push(t < 2 ? ones[i] : tens[t] + (o ? ' ' + ones[o] : ''));
  }
})();