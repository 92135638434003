import { combineReducers } from 'redux';
import AgencyReducer from './AgencyReducer';
import BSRReducer from './BSRReducer';
import ConstructionReducer from './ConstructionReducer';
import CostIndexReducer from './CostIndexReducer';
import EcommerceReducer from './EcommerceReducer';
import HiringAgencyReducer from './HiringAgencyReducer';
import ImplementationReducer from './ImplementationReducer';
import InventoryReducer from './InventoryReducer';
import MusterRollReducer from './MusterRollReducer';
import NavigationReducer from './NavigationReducer';
import NotificationReducer from './NotificationReducer';
import ParamterConfigurationReducer from './ParamterConfigurationReducer';
import PriceAdjustmentReducer from './PriceAdjustmentReducer';
import ProjectLocationReducer from './ProjectLocationReducer';
import RABillReducer from './RABillReducer';
import ResourceForecastReducer from './ResourceForecastReducer';
import RoleReducer from './RoleReducer';
import { SessionReducer } from './SessionReducer';
import StageInformationReducer from './StageInformationReducer';
import UserReducer from './UserReducer';
import QuickCostReducer from './QuickCostReducer'
import QuickCostGeneralReducer from './QuickCostGeneralReducer'


const RootReducer = combineReducers({
  notifications: NotificationReducer,
  navigations: NavigationReducer,
  ecommerce: EcommerceReducer,
  roles: RoleReducer,
  users: UserReducer,
  bsr: BSRReducer,
  agency: AgencyReducer,
  session: SessionReducer,
  stageInformation: StageInformationReducer,
  projectLocation: ProjectLocationReducer,
  construction: ConstructionReducer,
  implementation: ImplementationReducer,
  costIndex: CostIndexReducer,
  configurationParameter: ParamterConfigurationReducer,
  musterRollReducer: MusterRollReducer,
  inventory: InventoryReducer,
  raBill: RABillReducer,
  priceAdjustment: PriceAdjustmentReducer,
  resourceForecast: ResourceForecastReducer,
  hiringAgency: HiringAgencyReducer,
  quickCost: QuickCostReducer,
  quickCostGeneral: QuickCostGeneralReducer
});

export default RootReducer;
