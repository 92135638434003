import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import PasswordResetProcess from '../sessions/PasswordResetProcess';
import ResetPassword from '../sessions/ResetPassword';

const Analytics = Loadable(lazy(() => import('./Analytics')));

const dashboardRoutes = [
  { path: '/dashboard', element: <Analytics />, auth: authRoles.admin },
  { path: '/passwordReset', element: <PasswordResetProcess/> },
  { path: '/reset-password', element: <ResetPassword /> },
];

export default dashboardRoutes;
