import { LoadingButton } from '@mui/lab';
import { Box, Button, styled, TextField } from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import { SnackBarProperty } from 'app/utils/SnackBarProperty';
import { SimpleCard } from 'components';
import { H3 } from 'components/Typography';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';


const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const StyledH3 = styled(H3)(() => ({ marginTop: 10, fontsize: 15 }));
const StyledH4 = styled(H3)(() => ({ marginTop: 2, fontSize: 14, color: 'gray' }));


const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '0px',
  position: 'relative',
  background: 'rgba(0, 0, 0, 0.01)',
}));

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user, resetPassword } = useAuth();
  // const [user, setUser] = useState({})
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [state, setState] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  const handleChange = (event, source) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleFormSubmit = () => {
    if (state?.confirmPassword !== state?.newPassword) {
      const key = enqueueSnackbar("Passwords are not matching", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }

    if (validatePassword(state?.newPassword)) {
      resetPassword(user.username, state?.newPassword)
    }

  };

  function validatePassword(value) {
    if (validator.isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    }) === false) {
      const key = enqueueSnackbar("Password should include uppercase, lower case letters, numbers and special characters with minimum of 8 characters.", { variant: 'info' });
      SnackBarProperty.info(key, closeSnackbar);

      return false
    } else {
      return true
    }
  }

  const onClickCancel = () => {
    navigate(-1)
  }

  return (
    <Container>
      <ContentBox>
        <SimpleCard title="User Details">

          <b>CID/Work Permit No.:</b> {user?.cid_workpermit}<br />
          <b>Full Name:</b> {user?.name}<br />
          <b>User Name:</b> {user?.username}<br />
          <b>User Type:</b> {user?.usertype_name}<br />
          <b>Email ID:</b> {user?.email}<br />
          <b>Agency / Contractor:</b> {user?.agency_name}<br />


          <ValidatorForm onSubmit={handleFormSubmit}>
            <TextField
              fullWidth
              size="small"
              name="newPassword"
              type="password"
              label="New Password"
              variant="outlined"
              value={state?.newPassword}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={["New Password is required"]}
              sx={{ mb: 3, mt: 3 }}
            />
            <TextField
              fullWidth
              size="small"
              name="confirmPassword"
              type="password"
              label="Confirm Password"
              variant="outlined"
              value={state?.confirmPassword}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={["Confirm Password is required"]}
              sx={{ mb: 3 }}
            />
            <p style={{ fontWeight: 'bold', color: 'red' }}>(Password should include uppercase, lowercase, numbers and special characters with minimum of 8 characters)</p>
            <Box display="flex" justifyContent="flex-start">
              <LoadingButton
                type="submit"
                size="small"
                color="primary"
                loading={loading}
                variant="contained"
                sx={{ my: 2 }}
              > Update
              </LoadingButton>
              &nbsp;&nbsp;
              <Button color='error' size='small' onClick={() => onClickCancel()}> Close </Button>
            </Box>
          </ValidatorForm>
        </SimpleCard>

      </ContentBox>
    </Container>
  );
};

export default ResetPassword;
