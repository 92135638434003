import Loadable from 'app/components/Loadable';
import React from 'react';
import { lazy } from 'react';

const DeploymentOrderVerification = Loadable(lazy(() => import('./DeploymentOrderVerification')));
const DeploymentOrderApproval = Loadable(lazy(() => import('./DeploymentOrderApproval')));

const deploymentOrderRoutes = [
    {
        path: '/DeploymentOrderVerification',
        element: <DeploymentOrderVerification />,
    }, {
        path: '/DeploymentOrderApproval',
        element: <DeploymentOrderApproval />
    }
];

export default deploymentOrderRoutes;