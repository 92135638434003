import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const CostIndexWeightage = Loadable(lazy(() => import('./CostIndexWeightage')));

const costIndexWeightageRoutes = [
    {
      path: '/costIndexWeightage',
      element: <CostIndexWeightage/>,
    }
  ];
  
  export default costIndexWeightageRoutes;