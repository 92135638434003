import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const NotFound = Loadable(lazy(() => import('./NotFound')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));

const JwtLogin = Loadable(lazy(() => import('./JwtLogin')));
const Landing = Loadable(lazy(() => import('../Landing/Landing')));
const PasswordResetProcess = Loadable(lazy(() => import('./PasswordResetProcess')));
const ResetPassword = Loadable(lazy(() => import('./ResetPassword')));


const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));

const sessionRoutes = [
  { path: '/signup', element: <JwtRegister /> },
  { path: '/landing', element: <Landing /> },
  { path: '/signin', element: <JwtLogin /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password/step1', element: <ResetPassword /> },
  { path: '/reset-password-step2', element: <ResetPassword /> },
  { path: '/session/404', element: <NotFound /> },
];

export default sessionRoutes;
