import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const CostIndex = Loadable(lazy(() => import('./CostIndex')));
const CostIndexWeightage = Loadable(lazy(() => import('./CostIndex')));
const CostIndexRate = Loadable(lazy(() => import('./CostIndex')));
const CostIndexWorkType = Loadable(lazy(() => import('./CostIndexWorkType')));

const costIndexRoutes = [
   {
     path: '/costIndex',
     element: <CostIndex/>
   },
   {
    path: '/costIndexWorkType',
    element: <CostIndexWorkType/>
  },
  {
    path: '/costIndexWeightage',
    element: <CostIndexWeightage/>
  },
  {
    path: '/costIndexRate',
    element: <CostIndexRate/>
  }


];

export default costIndexRoutes;
