import { ServiceUrl } from 'app/utils/Constants';
import axios from 'axios';
import { GET_PROJECT_LOCATION_LIST_HIRING } from './HiringAgencyActions';
import { AppConstants } from "app/utils/AppConstants";

export const GET_PROJECT_LOCATION_LIST_FOR_AGENCY = 'GET_PROJECT_LOCATION_LIST_FOR_AGENCY';
export const GET_RESOURCE_FORECAST_CATEGORY_LIST = 'GET_RESOURCE_FORECAST_CATEGORY_LIST'
export const GET_REVIEW_APPROVE_REQUISITION_LIST = 'GET_REVIEW_APPROVE_REQUISITION_LIST'
export const GET_RESOURCE_FORECAST_LIST = 'GET_RESOURCE_FORECAST_REQUISITION_LIST'
export const RESET_RESOURCE_FORECAST_LIST = 'RESET_RESOURCE_FORECAST_LIST'
export const GET_RECOVERY_STATEMENT_LIST = 'GET_RECOVERY_STATEMENT_LIST'
export const GET_ALL_REQUISITION_DETAILS_BY_ID = 'GET_ALL_REQUISITION_DETAILS_BY_ID'


export const getAllProjectLocationByAgency = (showLoader, hideLoader, showToast, type = 'others') => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllProjectLocationByAgency, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader !== false && hideLoader()
    if (res?.status < 300) {
      if (type === 'hiring') {
        dispatch({
          type: GET_PROJECT_LOCATION_LIST_HIRING,
          payload: res.data?.length > 0 ? res.data : [],
        });
      } else {
        dispatch({
          type: GET_PROJECT_LOCATION_LIST_FOR_AGENCY,
          payload: res.data?.length > 0 ? res.data : [],
        });

      }
    } else {
      // showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader !== false && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};
export const getAllResourceForecastCategoryList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader !== false && showLoader()
  axios.post(ServiceUrl.getAllResourceForecastCategoryList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader !== false && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_RESOURCE_FORECAST_CATEGORY_LIST,
        payload: res?.data?.obj,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader !== false && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getResourceForecastDetails = (project_location_id, resourceforecast_category_id, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader !== false && showLoader()
  dispatch({
    type: RESET_RESOURCE_FORECAST_LIST,
    payload: [],
  });
  axios.post(`/api/project/projectLocation/${project_location_id}${ServiceUrl.getResourceForecastDetails}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader !== false && hideLoader()
    if (res?.status < 300) {
      if (res?.data?.length > 0) {
        dispatch({
          type: GET_RESOURCE_FORECAST_LIST,
          payload: res?.data,
        });
      }
      else {
        showToast("No Construction Found", "info")
        dispatch({
          type: RESET_RESOURCE_FORECAST_LIST,
          payload: [],
        });
      }

    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader !== false && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong);;
  })
};

export const getRecoveryStatementForDepartmentalWorks = (project_location_id, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader !== false && showLoader()
  axios.post(`/api/implementation/resourceForecast/${project_location_id}${ServiceUrl.getRecoveryStatementForDepartmentalWorks}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader !== false && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_RECOVERY_STATEMENT_LIST,
        payload: res?.data?.obj,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader !== false && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);
  })
};


export const getItemDetailsForDepartmental = ({ project_location_id, category_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/projectLocation/${project_location_id}/${category_id}${ServiceUrl.getItemDetailsForDepartmental}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getWorkerAttendanceForMusterRollBilling = (details_id, month, year) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${details_id}/${month}/${year}/getWorkerAttendanceForMusterRollBilling`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const saveOrUpdateMusterRollBill = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/saveOrUpdateMusterRollBill`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getMusterRollBillByProjectLocation = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${project_location_id}/getMusterRollBillByProjectLocation`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getWorkDoneAmountByProjectLocationId = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${project_location_id}${ServiceUrl.getWorkDoneAmountByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllRecoveryStatementByProjectLocationId = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/advancePayment/${project_location_id}${ServiceUrl.getAllRecoveryStatementByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const saveOrUpdateRecoveryStatement = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/advancePayment/${ServiceUrl.saveOrUpdateRecoveryStatement}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllResourceRequisitionByProjectLocation = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${project_location_id}${ServiceUrl.getAllResourceRequisitionByProjectLocation}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getSupplierByProjectLocationReport = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${project_location_id}/getSupplierByProjectLocationReport`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllMaterialBillBySupplyOrderId = ({ supplyorder_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${supplyorder_id}/getAllMaterialBillBySupplyOrderId`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getMBDetailsByProjectLocationId = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${project_location_id}${ServiceUrl.getMBDetailsByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllLogBookDetails = (resourcerequisition_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/musterRoll/${resourcerequisition_id}${ServiceUrl.getAllLogBookDetails}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateResourceRequisition = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast${ServiceUrl.saveOrUpdateResourceRequisition}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteRequisitionItemById = (id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/resourceForecast/${id}${ServiceUrl.deleteRequisitionItemById}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getItemDetailsForMBDom = ({ project_location_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/project/projectBOQ/${project_location_id}/getItemDetailsForMBDom`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const saveOrUpdateMBDetails = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/saveOrUpdateMBDetails`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateSupplyOrder = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateSupplyOrder, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateDeploymentOrder = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateDeploymentOrder, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateSupplier = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateSupplier, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllSupplier = () => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getAllSupplier, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllSupplierByRequisition = (resourcerequisition_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${resourcerequisition_id}${ServiceUrl.getAllSupplierByRequisition}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateMaterialReceipt = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateMaterialReceipt, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateMaterialReceiptList = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateMaterialReceiptList, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const saveOrUpdateWorker = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`${ServiceUrl.saveOrUpdateWorker}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const saveOrUpdateWorkerAttendance = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`${ServiceUrl.saveOrUpdateWorkerAttendance}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const saveProjectRequisitionWorkerMap = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`${ServiceUrl.saveProjectRequisitionWorkerMap}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getResourceRequisitionDetailsReport = (resourcerequisition_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/report/${resourcerequisition_id}${ServiceUrl.getResourceRequisitionDetailsReport}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getWorkerByIdentityDocumentNo = ({ document_no }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${document_no}${ServiceUrl.getWorkerByIdentityDocumentNo}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const saveOrUpdateMachinery = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`${ServiceUrl.saveOrUpdateMachinery}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllWorkerListByResourceRequisitionId = ({ requisition_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${requisition_id}${ServiceUrl.getAllWorkerListByResourceRequisitionId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getAllMachineryHiringDetailsListByResourceRequisitionId = ({ requisition_id }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/musterRoll/${requisition_id}${ServiceUrl.getAllMachineryHiringDetailsListByResourceRequisitionId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getMachineryByRegistrationNumber = ({ registration_no }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/musterRoll/${registration_no}${ServiceUrl.getMachineryByRegistrationNumber}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const saveOrUpdateMachineryHiringDetails = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/musterRoll${ServiceUrl.saveOrUpdateMachineryHiringDetails}`, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
// export const getItemDetailsForDepartmental = ({project_location_id,category_id}) => {
//   const accessToken = window.localStorage.getItem('accessToken')
//  return axios.post(`/api/project/projectLocation/${project_location_id}/${category_id}${ServiceUrl.getItemDetailsForDepartmental}`, {}, {
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${accessToken}`
//     }
//   })
// };
// export const saveOrUpdateResourceRequisition = (params) => {
//   const accessToken = window.localStorage.getItem('accessToken')
//  return axios.post(`/api/implementation/resourceForecast${ServiceUrl.saveOrUpdateResourceRequisition}`, params, {
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${accessToken}`
//     }
//   })
// };
// export const getItemDetailsForDepartmental = ({ project_location_id, category_id }) => {
//   const accessToken = window.localStorage.getItem('accessToken')
//   return axios.post(`/api/project/projectLocation/${project_location_id}/${category_id}${ServiceUrl.getItemDetailsForDepartmental}`, {}, {
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${accessToken}`
//     }
//   })
// };
// export const saveOrUpdateResourceRequisition = (params) => {
//   const accessToken = window.localStorage.getItem('accessToken')
//   return axios.post(`/api/implementation/resourceForecast${ServiceUrl.saveOrUpdateResourceRequisition}`, params, {
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${accessToken}`
//     }
//   })
// };


export const getAllResourceRequisitionByProjectLocationForApproveOrReview = (status, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader !== false && showLoader()
  axios.post(`/api/implementation/resourceForecast/${status}${ServiceUrl.getAllResourceRequisitionByProjectLocationForApproveOrReview}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_REVIEW_APPROVE_REQUISITION_LIST,
        payload: res?.data?.obj,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};
export const getAllRequisitionDetailsByResourceRequisitionId = (id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${id}${ServiceUrl.getAllRequisitionDetailsByResourceRequisitionId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
