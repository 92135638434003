import { GET_ALL_BASE_LOCATION, GET_ALL_BML_ITEM, GET_ALL_BML_ITEM_RATE, GET_ALL_BUILTUP_ITEM_LMC, GET_ALL_CATEGORY, GET_ALL_CHAPTERS, GET_ALL_CHAPTER_TYPE, GET_ALL_CLASSIFICATIONS, GET_ALL_SECTION, GET_ALL_SUB_CATEGORY, GET_ALL_SUB_CHAPTERS, GET_ALL_UNITS, GET_PROJECT_LOCATION_LIST, GET_WASTAGE_OVERHEAD, UPDATE_CURRENT_BUILTUP_ITEM_TAB, UPDATE_CURRENT_MASTER_TAB, UPDATE_CURRENT_TAB } from "../actions/BSRActions";
import { SHOW_LOADING } from "../actions/RoleActions";

  
  const initialState = {
    chapterList: [],
    sectionList: [],
    subChapterList: [],
    unitList: [],
    chapterTypeList: [],
    classificationList: [],
    baseLocationList: [],
    categoryList: [],
    bmlItemList: [],
    builtUpItemLMCList: [],
    bmlRateList: [],
    subCategoryList: [],
    projectLocationList: [],
    success: false,
    isLoading: false,
    wastageOverheadData: [],
    currentTab: 0,
    currentBsrMasterTab: 0,
    currentBuiltUpItemTab: 0
  };
  
  const BSRReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_PROJECT_LOCATION_LIST: {
        return {
            ...state,
            isLoading: false,
            projectLocationList: action.payload,
          };
      }

      case GET_ALL_CHAPTERS: {
        return {
            ...state,
            isLoading: false,
            chapterList: action.payload,
          };
      }

      case GET_ALL_BML_ITEM: {
        return {
            ...state,
            isLoading: false,
            bmlItemList: action.payload,
          };
      }

      case GET_WASTAGE_OVERHEAD: {
        return {
            ...state,
            isLoading: false,
            wastageOverheadData: action.payload,
          };
      }

      case GET_ALL_BUILTUP_ITEM_LMC: {
        return {
            ...state,
            isLoading: false,
            builtUpItemLMCList: action.payload,
          };
      }
      case GET_ALL_UNITS: {
        return {
            ...state,
            isLoading: false,
            unitList: action.payload,
          };
      }
      case GET_ALL_CHAPTER_TYPE: {
        return {
            ...state,
            isLoading: false,
            chapterTypeList: action.payload,
          };
      }
      case GET_ALL_CATEGORY: {
        return {
            ...state,
            isLoading: false,
            categoryList: [...action.payload],
          };
      }
      case GET_ALL_SUB_CATEGORY: {
        return {
            ...state,
            isLoading: false,
            subCategoryList: [...action.payload],
          };
      }
      case GET_ALL_CLASSIFICATIONS: {
        return {
            ...state,
            isLoading: false,
            classificationList: [...action.payload],
          };
      }
      case GET_ALL_SUB_CHAPTERS: {
        return {
            ...state,
            isLoading: false,
            subChapterList: action.payload,
          };
      }
      case GET_ALL_SECTION: {
        return {
            ...state,
            isLoading: false,
            sectionList: [...action.payload],
          };
      }
      case GET_ALL_BASE_LOCATION: {
        return {
            ...state,
            isLoading: false,
            baseLocationList: [...action.payload],
          };
      }

      case GET_ALL_BML_ITEM_RATE: {
        return {
            ...state,
            isLoading: false,
            bmlRateList: [...action.payload],
          };
      }

      case SHOW_LOADING: {
        return {
          ...state,
          isLoading: action.payload,
        };
      }

      case UPDATE_CURRENT_BUILTUP_ITEM_TAB: {
        return {
          ...state,
          currentBuiltUpItemTab: action.payload.currentTab
        };
      }

      case UPDATE_CURRENT_MASTER_TAB: {
        return {
          ...state,
          currentBsrMasterTab: action.payload.currentTab
        };
      }
  
      case UPDATE_CURRENT_TAB: {
        return {
          ...state,
          currentTab: action.payload.currentTab
        };
      }
      default: {
        state.currentTab = 0
        return {...state};
      }
    }
  };
  
  export default BSRReducer;
  