import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForBill } from "app/redux/actions/RABillActions";
import { getRaBillRecoveryDetailsForReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const RABillRecoveryReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [projectDetails, setProjectDetails] = useState()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [uniqueRaBillList, setUniqueRaBillList] = useState([])

  const { projectLocationList } = useSelector((state) => state.raBill);
  // const [raBillList,setRaBillList]=useState([])

  const [originalTableData, setOriginalTableData] = useState()
  const [filteredTableData, setFilteredTableData] = useState()
  const [grandTotal, setGrandTotal] = useState()

  const [state, setState] = useState({
    project_location_id: '',
    ra_bill_id: "",
    rabill_no: "all"
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForBill(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {


    if (event.target.name === "project_location_id") {
      // setRaBillList([])
      setOriginalTableData()
      setFilteredTableData()
      const filteredArray = projectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value, ra_bill_id: "" });
      //   getAllRaBillNoForReport({project_location_id:filteredArray[0].project_location_id}).then(res=>{
      //     setLoading(false)
      //   if(res?.status<300){
      //     if(res?.data?.obj?.rabill_details?.length>0){
      //         setRaBillList(res?.data?.obj?.rabill_details)
      //     }

      //   }else{
      //     const key=enqueueSnackbar(res?.data?.message, SnackBarProperty.error(key,closeSnackbar));
      //   }
      //   }).catch(error=>{
      //     setLoading(false)
      //     const key=enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, SnackBarProperty.error(key,closeSnackbar));
      //   })
    }
    else if (event.target.name === "rabill_no") {
      setLoading(true)
      if (event.target.value === "all") {
        setFilteredTableData(originalTableData)
      }
      else {
        if (originalTableData && originalTableData?.length) {
          const items = [];
          originalTableData.forEach(obj => {
            let item_list = []
            obj.item_list.forEach(item => {

              if (item.rabill_no === event?.target?.value) {
                item_list?.push(item)

              }
            });
            if (item_list?.length > 0) {
              items?.push(
                {
                  construction_name: obj?.construction_name,
                  item_list: item_list,
                  sub_total: parseFloat(item_list?.reduce((sum, item) => sum + parseFloat(item?.recovered_amount), 0))?.toFixed(2),
                }
              )
            }
          });
          setFilteredTableData(items)
        }
      }
      setLoading(false)
      setState({ ...state, [event.target.name]: event?.target?.value });
    }
    else {
      setState({ ...state, [event.target.name]: event?.target?.value });
    }
  }


  const handleFormSubmit = async () => {
    // if (state?.project_location_id === "") {
    //   const key = enqueueSnackbar("Please select project location", { variant: 'error' });
    //   SnackBarProperty.error(key, closeSnackbar);
    // }
    // else if(state?.ra_bill_id===""){
    //     const key=enqueueSnackbar("Please select RA bill id", SnackBarProperty.error(key,closeSnackbar));
    // }
    setOriginalTableData()
    setFilteredTableData()
    setState({ ...state, rabill_no: "all" })
    setLoading(true)
    setOriginalTableData()

    getRaBillRecoveryDetailsForReport({ project_location_id: state?.project_location_id })
      .then((res) => {
        setLoading(false)
        if (res?.status < 300 && res?.data?.success) {

          if (res?.data?.obj) {
            if (res?.data?.obj && res?.data?.obj?.item) {
              if (res?.data?.obj?.item?.item && Object.keys(res?.data?.obj?.item?.item).length > 0) {
                const table = []
                Object.keys(res?.data?.obj?.item?.item).forEach(key => {
                  let obj = {}
                  const constructionName = key
                  const itemList = res?.data?.obj?.item?.item[key];
                  if (res?.data?.obj?.item?.item[key]?.length > 0) {
                    const subTotal = res?.data?.obj?.item?.item[key]?.reduce((sum, item) => sum + parseFloat(item.recovered_amount), 0);

                    obj = {
                      construction_name: constructionName,
                      item_list: itemList,
                      sub_total: parseFloat(subTotal ? subTotal : 0).toFixed(2),
                    }
                  }
                  table.push(obj)
                });
                const grand_total = table.reduce((sum, item) => sum + parseFloat(item?.sub_total), 0)

                setGrandTotal(grand_total)
                setOriginalTableData(table)
              }


              setProjectDetails({
                company: res?.data?.obj?.agency_name,
                contract_no: res?.data?.obj?.item?.tender_id,
                project_name: res?.data?.obj?.item?.project_name,
                dzongkhag_name: res?.data?.obj?.item?.dzongkhagName,
                header: res?.data?.obj?.header_logo,
                footer: res?.data?.obj?.footer_logo,
                project_id: res?.data?.obj?.item?.project_id

              })
            }
          }
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
          SnackBarProperty.info(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })








    // function mergeByConstructionType(data) {
    //   const result = data.reduce((acc, curr) => {
    //     const constructionType = curr.construcion_type;
    //     const totalAmount = curr.item_list.reduce((sum, item) => sum + item.amount, 0);

    //     // Create a new object with construction_type and total
    //     acc.push({ description: `Total for ${constructionType} (Nu)`, total: totalAmount });
    //     return acc;
    //   }, []);

    //   return result;
    // }


  }
  useEffect(() => {
    if (originalTableData && originalTableData?.length > 0) {
      setFilteredTableData(originalTableData)
      const rabillNos = new Set();
      originalTableData.forEach(obj => {
        obj.item_list.forEach(item => {
          rabillNos.add(item.rabill_no);
        });
      });
      setUniqueRaBillList(Array.from(rabillNos).sort())
    }
  }, [originalTableData])

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.footer}`
      // Calculate the width and height of the header image based on the PDF page width
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
        scaledHeight = pdfHeight;
        scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10;


      // Add the text content below the header image
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text("RUNNING ACCOUNT BILL", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("RUNNING ACCOUNT BILL") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      doc.setFontSize(10)
      doc.text("Details of Recovery", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("Details of Recovery") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY + 5);

      const textWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;

      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + textWidth + 5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.company, textX + textWidth + 5, textY + 20);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.project_id}`, textX + textWidth + 5, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(5);
      //   doc.setFont('helvetica', 'bold');
      //   doc.text("End Date:",textX, textY + 15);
      //   const fourthTextWidth = doc.getStringUnitWidth("End Date:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(`${moment(contractDetails?.work_end_date).format("DD-MM-YYYY")}`, textX + fourthTextWidth + 5, textY+15);
      //   doc.setFont('helvetica', 'normal');
      //   doc.setFont('helvetica', 'bold');
      //   doc.text("RA Bill No.:",textX, textY + 20);
      //   const fifthTextWidth = doc.getStringUnitWidth("RA Bill No.:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(`${contractDetails?.rabill_no}`, textX + fifthTextWidth + 5, textY+20);
      //   doc.setFont('helvetica', 'bold');
      //   doc.text("Contract Amount:",textX, textY + 25);
      //   const sixthTextWidth = doc.getStringUnitWidth("Contract Amount:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(`${parseFloat(contractDetails?.initial_contract_amount).toFixed(2)}`, textX + sixthTextWidth + 5, textY+25);
      //   doc.setFont('helvetica', 'normal');

      const headerSectionHeight = scaledHeight + 40; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;
      doc.setFont('helvetica', 'normal');

      const body = []
      body.push([{ content: "", colSpan: 2 },
      { content: "Tender", styles: { fontStyle: "bold", fontSize: 8 } },
      { content: "Deviated", styles: { fontStyle: "bold", fontSize: 8 } },
        "",
      { content: "Tender", styles: { fontStyle: "bold", fontSize: 8 } },
      { content: "Revised", styles: { fontStyle: "bold", fontSize: 8 } },
      { content: "Tender", styles: { fontStyle: "bold", fontSize: 8 } },
      { content: "Deviated", styles: { fontStyle: "bold", fontSize: 8 } }])
      filteredTableData?.forEach((elem) => {
        body.push([
          { content: elem?.construction_name, colSpan: 11, styles: { fontStyle: "bold", fontSize: 8 } }
        ])
        elem?.item_list?.forEach((item) => {
          body.push([
            { content: `${item?.item_code} (RA Bill No.: ${item?.rabill_no})`, styles: { fontSize: 8 } },
            { content: item?.description, styles: { fontSize: 8 } },
            { content: `${formatNumberWithCommasAndDecimals(parseFloat(item?.executed_quantity ? item?.executed_quantity : 0).toFixed(2))}`, styles: { fontSize: 8 } },
            { content: `${formatNumberWithCommasAndDecimals(parseFloat(item?.dev_quantity ? item?.dev_quantity : 0).toFixed(2))}`, styles: { fontSize: 8 } },
            { content: item?.unit_name, styles: { fontSize: 8 } },
            { content: formatNumberWithCommasAndDecimals(parseFloat(item?.contract_rate ? item?.contract_rate : 0).toFixed(2)), styles: { halign: "right", fontSize: 8 } },
            { content: formatNumberWithCommasAndDecimals(parseFloat(item?.revised_rate ? item?.revised_rate : 0).toFixed(2)), styles: { halign: "right", fontSize: 8 } },
            { content: formatNumberWithCommasAndDecimals(parseFloat((item?.executed_quantity ? item?.executed_quantity : 0) * (item?.contract_rate ? item?.contract_rate : 0)).toFixed(2)), styles: { halign: "right", fontSize: 8 } },
            { content: formatNumberWithCommasAndDecimals(parseFloat((item?.dev_quantity ? item?.dev_quantity : 0) * (item?.revised_rate ? item?.revised_rate : 0)).toFixed(2)), styles: { halign: "right", fontSize: 8 } },
            { content: formatNumberWithCommasAndDecimals(parseFloat(item?.recovered_quantity ? item?.recovered_quantity : 0).toFixed(2)), styles: { halign: "right", fontSize: 8 } },
            { content: formatNumberWithCommasAndDecimals(parseFloat(item?.recovered_amount ? item?.recovered_amount : 0).toFixed(2)), styles: { halign: "right", fontSize: 8 } }
          ])
        })
        body.push([
          { content: "Sub-Total", colSpan: 10, styles: { fontStyle: "bold", halign: "right", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(elem?.sub_total), colSpan: 1, styles: { fontStyle: "bold", halign: "right", fontSize: 8 } }
        ])
      })
      body.push([
        { content: "Grand Total", colSpan: 10, styles: { fontStyle: "bold", halign: "right", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(parseFloat(grandTotal).toFixed(2)), colSpan: 1, styles: { fontStyle: "bold", halign: "right", fontSize: 8 } }
      ])


      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "Item Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Quantity", colSpan: 2, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Rate (Nu)", colSpan: 2, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Amount (Nu)", colSpan: 2, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Recovered Qty", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Recovered Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
          fontSize: 7
        },
        columnStyles: {
          // fontSize:5

          // 8:{columnWidth:15},
          // 9:{columnWidth:15},
          // 10:{columnWidth:15},
          // 11:{columnWidth:15}
        },
      });
      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');// Set font size
      doc.text(`Checked by:  `, doc.internal.pageSize.getWidth() - 100, doc.autoTable.previous.finalY + 10);
      doc.text("Prepared by:", 15, doc.autoTable.previous.finalY + 10);

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(footer, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }


      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save("Ra_Bill_Recovery_Details_Report.pdf");

    });

  };

  useEffect(() => {
    if (filteredTableData && filteredTableData?.length > 0) {
      const grand_total = filteredTableData.reduce((sum, item) => sum + parseFloat(item?.sub_total), 0)
      setGrandTotal(grand_total)
    }
  }, [filteredTableData])





  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>RA Bill Recovery Details</b></Grid>
            </Grid>
            <Grid container spacing={3} >

              <Grid item sm={4} xs={12}>
                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {projectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </DecoratedTextField>
              </Grid>


              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {
          filteredTableData && filteredTableData?.length > 0 &&
          <Grid container>
            <Grid xs={12} md={6}>
              {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  color="warning"
                  disabled={projectDetails?.header ? false : true}
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  color="primary"
                  disabled={projectDetails?.header ? false : true}
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>

          </Grid>}

        {/* table */}
        {
          filteredTableData && filteredTableData?.length > 0 && projectDetails &&
          <Card sx={{ padding: 3 }}>
            <Grid container>
              <Grid items xs={6} sm={4}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="RA Bill No."
                  id="rabill_no"
                  variant="outlined"
                  name="rabill_no"
                  value={state?.rabill_no}
                  onChange={handleChange}
                >
                  <MenuItem value={"all"} key={"All"}>
                    All
                  </MenuItem>
                  {uniqueRaBillList?.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

                </div>
              </div>

              <Grid container >
                <Grid item xs={12} sx={{ marginTop: 2, textAlign: "center" }}>
                  <FormLabel sx={{ fontSize: 20 }}><b>RUNNING ACCOUNT BILL</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 1, textAlign: "center" }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Details of Recovery</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.company}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>
                {/* <Grid item  xs={12} sx={{marginTop:2}}>
                <FormLabel sx={{fontSize:18}}><b>Start Date.: </b>{moment(contractDetails?.work_start_date).format("DD-MM-YYY")}</FormLabel>
            </Grid>
            <Grid item  xs={12} sx={{marginTop:2}}>
                <FormLabel sx={{fontSize:18}}><b>End Date.: </b>{moment(contractDetails?.work_end_date).format("DD-MM-YYY")}</FormLabel>
            </Grid>
            <Grid item  xs={12} sx={{marginTop:2}}>
                <FormLabel sx={{fontSize:18}}><b>RA Bill No.: </b>{contractDetails?.rabill_no}</FormLabel>
            </Grid> */}
                {/* <Grid item  xs={12} sx={{marginTop:2}}>
                <FormLabel sx={{fontSize:18}}><b>Project ID: </b>{contractDetails?.tenderId}</FormLabel>
            </Grid> */}
                {/* <Grid item  xs={12} sx={{marginTop:2}}>
                <FormLabel sx={{fontSize:18}}><b>Contract Amount: </b>{contractDetails?.initial_contract_amount}</FormLabel>
            </Grid> */}


              </Grid>
            </div>

            <div id="filteredTableData">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell rowSpan={2} style={{ width: '100px', textAlign: 'center' }}>Item Code</TableCell>
                      <TableCell rowSpan={2} style={{ width: '600px', whiteSpace: 'wrap', textAlign: "center" }} align="center">Description</TableCell>
                      <TableCell colSpan={2} align="center" style={{ width: '300px', whiteSpace: 'nowrap' }}>Executed Quantity</TableCell>
                      <TableCell rowSpan={2} align="center">Unit</TableCell>
                      <TableCell colSpan={2} align="center">Rate(Nu)</TableCell>
                      <TableCell colSpan={2} align="center">Amount(Nu)</TableCell>
                      <TableCell align="center">Recovered Qty.</TableCell>
                      <TableCell align="center" style={{ width: '300px' }}>Recovery Amount (Nu)</TableCell>
                    </TableRow>

                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={styles?.subHeader} colSpan={2}></TableCell>
                      <TableCell style={styles?.subHeader}>Tender</TableCell>
                      <TableCell style={styles?.subHeader}>Deviated</TableCell>
                      <TableCell style={styles?.subHeader} colSpan={1}></TableCell>
                      <TableCell style={styles?.subHeader}>Tender</TableCell>
                      <TableCell style={styles?.subHeader}>Revised</TableCell>
                      <TableCell style={styles?.subHeader}>Tender</TableCell>
                      <TableCell style={styles?.subHeader}>Deviated</TableCell>
                      <TableCell style={styles?.subHeader}></TableCell>
                      <TableCell style={styles?.subHeader}></TableCell>



                    </TableRow>
                    {filteredTableData && filteredTableData?.map((data) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell colSpan={11}><b>{data?.construction_name}</b></TableCell>
                          </TableRow>
                          {
                            data?.item_list?.map((elem) => {
                              return (
                                <TableRow>
                                  <TableCell >{elem?.item_code} (RA Bill No.: {elem?.rabill_no})</TableCell>
                                  <TableCell><div className="description-cell">{elem?.description}</div></TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.new_quantity ? elem?.new_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.deviation_quantity ? elem?.deviation_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }} >{elem?.unit_name}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.contract_rate ? elem?.contract_rate : 0).toFixed(2))}</TableCell>
                                  <TableCell>{formatNumberWithCommasAndDecimals(parseFloat(elem?.revised_rate ? elem?.revised_rate : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat((elem?.executed_quantity ? elem?.executed_quantity : 0) * (elem?.contract_rate ? elem?.contract_rate : 0)).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat((elem?.deviation_quantity ? elem?.deviation_quantity : 0) * (elem?.revised_rate ? elem?.revised_rate : 0)).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.recovered_quantity ? elem?.recovered_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.recovered_amount ? elem?.recovered_amount : 0).toFixed(2))}</TableCell>
                                </TableRow>
                              )
                            })
                          }
                          <TableRow>
                            <TableCell colSpan={10} align="right"><b>Sub-Total</b></TableCell>
                            <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(data?.sub_total)}</b></TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                    <TableRow>
                      <TableCell colSpan={10} align="right"><b>Grand Total</b></TableCell>
                      <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(parseFloat(grandTotal).toFixed(2))}</b></TableCell>
                    </TableRow>

                  </TableBody>


                </Table>

              </TableContainer>
            </div>
          </Card>}


      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}