import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Suppliers = Loadable(lazy(() => import('./Supplier')));

const supplierRoutes = [
  {
    path: '/Supplier',
    element: <Suppliers/>,
  }
];

export default supplierRoutes;