import { ServiceUrl } from 'app/utils/Constants';
import axios from 'axios';

export const GET_USERS = 'GET_USERS';
export const SHOW_LOADING = 'SHOW_LOADING'

export const getUsers = (showLoading, hideLoading, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoading && showLoading()
  axios.post(ServiceUrl.getAllUsers, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoading && hideLoading()
    if (res?.status < 300) {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoading && hideLoading()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const assignNewRoleToUser = (userID, roleId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/auth/${userID}/${roleId}${ServiceUrl.assignNewRoleToUser}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRolefromUser = (userID, roleId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/auth/${userID}/${roleId}${ServiceUrl.deleteRolefromUser}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const updateUserDetails = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.updateUserDetails, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};