import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import 'smart-webcomponents-react/source/styles/smart.default.css';
import '../fake-db';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const App = () => {
  const content = useRoutes(routes);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>{content}</AuthProvider>
        </MatxTheme>
      </SettingsProvider>
    </Provider>
    </LocalizationProvider>
  );
};

export default App;
