import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getFundForecastReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, currencyFormat, formatDateWithFormatter, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { cmsDateFormat } from "app/utils/constant";
import { ServiceUrl } from 'app/utils/Constants';

const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const FundForecastReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [projectDetails, setProjectDetails] = useState({})
  const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  const [totalAmount, setTotalAmount] = useState(0)
  const [tableData, setTableData] = useState()
    ;
  const [state, setState] = useState({
    project_location_id: '',
    start_date: "",
    end_date: ""
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {
    setTableData([])
    setProjectDetails({})
    if (event.target.name === "project_location_id") {
      setState({
        ...state,
        start_date: "",
        end_date: ""
      })
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value });

    }
    else if (event.target.name === "start_date") {
      setState({ ...state, start_date: new Date(event.target.value) })

    }
    else if (event.target.name === "end_date") {
      setState({ ...state, end_date: new Date(event.target.value) })

    }
    else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }


  const handleFormSubmit = () => {

    // setTableData([])

    if (state?.project_location_id === "" && state?.start_date === "" && state?.end_date === "") {
      const key = enqueueSnackbar("Please fill up all details", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else if (state?.start_date === "" && state?.end_date === "") {
      const key = enqueueSnackbar("Please select Start date and End date", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else if (state?.project_location_id === "") {
      const key = enqueueSnackbar("Please select project id", { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);
    }
    else if (state?.start_date === "") {
      const key = enqueueSnackbar("Please select Start date", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else if (state?.end_date === "") {
      const key = enqueueSnackbar("Please select End date", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else {
      setLoading(true)
      getFundForecastReport(state)
        .then((res) => {
          setLoading(false)

          if (res?.status < 300) {
            if(res?.data?.success){
            console.log("list: ", res?.data?.obj?.item?.ra_bill_vs_time_report)
            if (res?.data?.obj && res?.data?.obj?.item && Object.keys(res?.data?.obj?.item?.ra_bill_vs_time_report)?.length > 0) {
              setTableData([res?.data?.obj?.item?.ra_bill_vs_time_report])
              // const total = res?.data?.obj?.item?.item_list.reduce((accumulator, item) => accumulator+item?.tds_amount, 0);
              // setTotalAmount(parseFloat(total).toFixed(2))
            }
            if (res?.data?.obj?.item) {
              setProjectDetails({
                ...projectDetails,
                company: res?.data?.obj?.agency_name??"",
                project_name: res?.data?.obj?.item?.project_name,
                project_id: res?.data?.obj?.item?.project_id,
                contract_amount: parseFloat(res?.data?.obj?.item?.amount ? res?.data?.obj?.item?.amount : 0).toFixed(2),
                header: res?.data?.obj?.header_logo,
                footer: res?.data?.obj?.footer_logo,
                contract_start_date: moment(res?.data?.obj?.item?.work_start_date).format("DD-MM-YYYY"),
                contract_end_date: res?.data?.obj?.item?.contract_extension_date !== null ? moment(res?.data?.obj?.item?.contract_extension_date).format("DD-MM-YYYY") : res?.data?.obj?.item?.work_end_date,
                average_expenditure_per_day: parseFloat(res?.data?.obj?.item?.average_expenditure_per_day ? res?.data?.obj?.item?.average_expenditure_per_day : 0).toFixed(2),
                forecast_duration: res?.data?.obj?.item?.forecast_duratoin
              })
            }
          }
            else {
              const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
              SnackBarProperty.info(key, closeSnackbar);
            }

          }
          else {
              const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

          }
        }).catch(error => {
          setLoading(false)
           const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

        })
    }


  }

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }

    const doc = new jsPDF();
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Calculate the width and height of the header image based on the PDF page width
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
          scaledHeight = pdfHeight;
          scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      // Insert the header image into the PDF
      const textX = 15;
      const textY = scaledHeight + 10; // Adjust the value for vertical separation

      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text("FUND FORECAST", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("FUND FORECAST") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
     
      const textWidth = doc.getStringUnitWidth("Forecast Duration (Days):") * doc.internal.getFontSize() / doc.internal.scaleFactor;
     
      doc.setFontSize(8); // Set font size
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + textWidth -10, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.company, textX + textWidth -10, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.project_id}`, textX + textWidth -10, textY + 20);
      doc.setFont('helvetica', 'bold');
      doc.text("Forecast Duration (Days):", textX, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.forecast_duration}`, textX + textWidth -10, textY + 25);
      const headerSectionHeight = scaledHeight+40; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;

      doc.setFont('helvetica', 'normal'); // Reset font to normal
      const body = []
      body.push([
        { content: "", colSpan: 3, styles: { fontStyle: "bold", fontSize: 6 } },
        { content: "Mobilization", styles: { fontStyle: "bold", fontSize: 6 } },
        { content: "Material at Site", styles: { fontStyle: "bold", fontSize: 6 } },
        { content: "", colSpan: 4, styles: { fontStyle: "bold", fontSize: 6 } },
      ])
      tableData?.length > 0 && tableData?.forEach((item) => {
        body.push([
          { content: item?.date_of_forecast, styles: { fontSize: 6 } },
          { content: formatNumberWithCommasAndDecimals(item?.rabill_paid_till_date ? item?.rabill_paid_till_date : 0), styles: { halign: "right", fontSize: 6 } },
          { content: formatNumberWithCommasAndDecimals(item?.amendment_amount ? item?.amendment_amount : 0.00), styles: { halign: "right", fontSize: 6 } },
          { content: parseFloat(item?.Mobilization ? item?.Mobilization : 0).toFixed(2), styles: { fontSize: 6 } },
          { content: parseFloat(item?.["Materials Advance"] ? item?.["Materials Advance"] : 0).toFixed(2), styles: { fontSize: 6 } },
          { content: formatNumberWithCommasAndDecimals(item?.contract_amount ? item?.contract_amount : 0), styles: { halign: "right", fontSize: 6 } },
          { content: parseFloat(item?.balance ? item?.balance : 0).toFixed(2), styles: { halign: "right", fontSize: 6 } },
          { content: item?.contract_period_remaining, styles: { fontSize: 6 } },
          { content: formatNumberWithCommasAndDecimals(item?.forecast_for_the_period ? item?.forecast_for_the_period : 0), styles: { halign: "right", fontSize: 6 } }

        ])
      })






      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "Date of Forecast", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 6 } },
          { content: "RA Bill Paid Till Date", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 6 } },
          { content: "Amendment Amount", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 6 } },
          { content: "Advances Till Date", colSpan: 2, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 6 } },
          { content: "Contract Amount", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 6 } },
          { content: "Amount", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 6 } },
          { content: "Contract period remaining (Days)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 6 } },
          { content: "Forecast of the period (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 6 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },

      });

      const bottomTextWidth = doc.getStringUnitWidth("Average Expenditure Per Day :") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');// Set font size
      doc.text(`Contract Start Date :`, textX, doc.autoTable.previous.finalY + 10);
     doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.contract_start_date??"", textX + bottomTextWidth + 5, doc.autoTable.previous.finalY + 10);
      doc.setFont('helvetica', 'bold');// Set font size
      doc.text(`Contract End Date :`, textX, doc.autoTable.previous.finalY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.contract_end_date?moment(projectDetails?.contract_end_date)?.format("DD-MM-YYYY"):"", textX + bottomTextWidth + 5, doc.autoTable.previous.finalY + 15);
      doc.setFont('helvetica', 'bold');// Set font size
      doc.text(`Average Expenditure Per Day :`, textX, doc.autoTable.previous.finalY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.average_expenditure_per_day, textX + bottomTextWidth + 5, doc.autoTable.previous.finalY + 20);

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.footer ? projectDetails?.footer : 'moit_footer.jpg'}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }
      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('Funds_Forecast_Report.pdf');

    });
  };









  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Fund Forecast</b></Grid>
            </Grid>
            <Grid container spacing={3}>

              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={3} xs={12}>
                <DatePicker
                  label="Start Date"
                  value={dayjs(state?.start_date)}
                  format={cmsDateFormat}
                  slotProps={{
                    textField: {
                      size: 'small',
                      required: true,
                      fullWidth: true,
                      variant: 'outlined',
                      error: false,
                    },
                  }}
                  onChange={(newValue) =>
                    setState({
                      ...state, ['start_date']: dayjs(newValue)
                    })
                  }
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <DatePicker
                  label="End Date"
                  value={dayjs(state?.end_date)}
                  format={cmsDateFormat}
                  minDate={dayjs(state?.start_date)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      required: true,
                      fullWidth: true,
                      variant: 'outlined',
                      error: false,
                    },
                  }}
                  onChange={(newValue) =>
                    setState({
                      ...state, ['end_date']: dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss")
                    })
                  }
                />
              </Grid>

              <Grid item sm={2} xs={12}>
                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 2 }}> View </Button>
              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {tableData && tableData?.length > 0 && <Grid container>
          <Grid xs={12} md={6}>
            {checkValidation(projectDetails?.header)===false&&<FormLabel sx={{color:"#ff9800"}} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
          <Grid item xs={12} md={6}>
            <Box component="span" display="flex" justifyContent="flex-end">
              <Button
                size="small"
                sx={{ width: "130px", marginLeft: 4 }}
                variant="contained"
                color="warning"
                disabled={projectDetails?.header?false:true}
                startIcon={<CloudDownload />}
                onClick={() => { handleReportDownload(false) }}
              >
                Download
              </Button>
              <Button
                size="small"
                sx={{ width: "100px", marginLeft: 2 }}
                variant="contained"
                color="primary"
                disabled={projectDetails?.header?false:true}
                startIcon={<Print />}
                onClick={() => { handleReportDownload(true) }}
              >
                Print
              </Button>
            </Box>
          </Grid>

        </Grid>}
        {tableData && tableData?.length > 0 && <Card sx={{ padding: 3 }}>

          <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
            <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

              </div>

              <Grid container >
                <Grid item xs={12} sx={{ marginTop: 2, textAlign: "center" }}>
                  <FormLabel sx={{ fontSize: 20 }}><b>FUND FORECAST</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.company}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Id: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Forecast Duration (Days): </b>{projectDetails?.forecast_duration}</FormLabel>
                </Grid>
                {/* <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Contract Amount : </b>{projectDetails?.contract_amount}</FormLabel>
                </Grid> */}


              </Grid>
            </div>

            <div id="tableData">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell >Date of Forecast</TableCell>
                      <TableCell>RA Bill Paid Till Date</TableCell>
                      <TableCell>Amendment Amount</TableCell>
                      <TableCell colSpan={2}>Advances Till Date</TableCell>
                      <TableCell>Contract Amount</TableCell>
                      <TableCell>Balance</TableCell>
                      <TableCell>Contract Period Remaining (Days)</TableCell>
                      <TableCell>Forecast of The Period (Nu)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={0} >
                      <TableCell></TableCell>
                      <TableCell ></TableCell>
                      <TableCell></TableCell>
                      <TableCell><b>Mobilization</b></TableCell>
                      <TableCell><b>Material at Site</b></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    {
                      tableData?.map((item, index) => (
                        <React.Fragment >

                          <TableRow key={index + 1} >
                            <TableCell>{item?.date_of_forecast}</TableCell>
                            <TableCell>{item?.rabill_paid_till_date}</TableCell>
                            <TableCell>{item?.amendment_amount}</TableCell>
                            <TableCell>{item?.Mobilization}</TableCell>
                            <TableCell>{item?.["Materials Advance"]}</TableCell>
                            <TableCell>{formatNumberWithCommasAndDecimals(item?.contract_amount ? item?.contract_amount : 0)}</TableCell>
                            <TableCell>{formatNumberWithCommasAndDecimals(item?.balance ? item?.balance : 0)}</TableCell>
                            <TableCell>{item?.contract_period_remaining}</TableCell>
                            <TableCell>{currencyFormat(formatNumberWithCommasAndDecimals(item?.forecast_for_the_period ? item?.forecast_for_the_period : 0))}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    {/* <TableRow >
                              <TableCell colSpan={3} align="right"><b>Total (Nu)</b></TableCell>
                              <TableCell align="right"><b>{totalAmount}</b></TableCell>
                       </TableRow> */}


                  </TableBody>
                </Table>

              </TableContainer>
              <Grid container sx={{ mt: 4 }}>
                <Grid items xs={12}>
                  <FormLabel sx={{ fontSize: 20 }}><b>Contract Start Date: </b>{projectDetails?.contract_start_date}</FormLabel>
                </Grid>
                <Grid items xs={12}>
                  <FormLabel sx={{ fontSize: 20 }}><b>Contract End Date: </b>{projectDetails?.contract_end_date}</FormLabel>
                </Grid>
                <Grid items xs={12}>
                  <FormLabel sx={{ fontSize: 20 }}><b>Average Expenditure Per Day: </b>{projectDetails?.average_expenditure_per_day}</FormLabel>
                </Grid>
              </Grid>
            </div>
          </div>
        </Card>}
       
      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}