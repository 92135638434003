import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, Card, FormControlLabel, FormLabel, Grid, Icon, MenuItem, Radio, RadioGroup, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllConstructionNameListByParameters, getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getMaterialForecastReportByPR,getMaterialForecastReportByCN } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const MaterialForecastReport = () => {

  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [projectDetails, setProjectDetails] = useState()
  const [reportType, setReportType] = useState("PR")
  const [totalAmount, setTotalAmount] = useState()
  const [constructionNameList,setConstructionNameList]=useState([])
  const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  // const {userList}=useSelector((state)=>state.userList)
  const [tableData, setTableData] = useState([])
    ;

  const [state, setState] = useState({
    project_location_id: '',
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      setTableData([])
      setConstructionNameList([])
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      // state.project_name = filteredArray[0].project_name
      // state.project_location_id = filteredArray[0].project_location_id
      // state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value,project_name:filteredArray[0].project_name,project_id:filteredArray[0].project_id });
     if(reportType==="CN"){ 
      setLoading(true)
      getAllConstructionNameListByParameters(0,filteredArray[0].project_location_id).then((res) => {
        setLoading(false)
        if (res?.status < 300) {
          // if (res?.data?.length > 0) {
          //     state.constructionname_id = res?.data[0].constructionname_id
          //     state.construction_name = res?.data[0].construction_name 
          //     state.project_id = project_id
          //     setState({ ...state, ['boq_code']: res?.data[0].boq_code });
          // }
          setConstructionNameList(res?.data)
        } else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);
  
        }
      }).catch(function (error) {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
  
      })}

    }
    else if (event.target.name === "constructionname_id") {
      setTableData([])
      const filteredArray = constructionNameList.filter(project => {
        return project.constructionname_id === event.target.value
      });
      // state.project_name = filteredArray[0].project_name
      // state.project_location_id = filteredArray[0].project_location_id
      // state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value,construction_name:filteredArray[0].construction_name});
   

    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }


  const handleFormSubmit = () => {
    setLoading(true)
    setTableData([])
    
    if(reportType==="PR"){
    getMaterialForecastReportByPR({ project_location_id: state?.project_location_id })
      .then((res) => {
        setLoading(false)
        if (res?.status < 300) {

          if (res?.data?.obj?.item) {
            setState({
              ...state,
              header: res?.data?.obj.header_logo,
              footer: res?.data?.obj?.footer_logo,
              agency_name: res?.data?.obj?.agency_name,
              project_name: res?.data?.obj?.item?.project_name,
              company: res?.data?.obj?.item?.company,
              project_id: res?.data?.obj?.item?.project_id

            })

            if (res?.data?.obj?.item?.material_forecast_report?.length > 0) {
              setTableData(res?.data?.obj?.item?.material_forecast_report)
              const grand_total = res?.data?.obj?.item?.material_forecast_report?.reduce((sum, item) => sum + parseFloat(parseFloat(item?.rate ? item?.rate : 0) * parseFloat(item?.qty ? item?.qty : 0)), 0)
              setTotalAmount(parseFloat(grand_total).toFixed(2))
            }

          }
          else {
            const key = enqueueSnackbar("No Materials found for this project", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    }
    else{
      getMaterialForecastReportByPR({ project_location_id: state?.project_location_id })
      .then((res) => {
        // setLoading(false)
        if (res?.status < 300) {

          if (res?.data?.obj?.item) {
            setState({
              ...state,
              header: res?.data?.obj.header_logo,
              footer: res?.data?.obj?.footer_logo,
              agency_name: res?.data?.obj?.agency_name,
              project_name: res?.data?.obj?.item?.project_name,
              company: res?.data?.obj?.item?.company,
              project_id: res?.data?.obj?.item?.project_id

            })

            getItemsByCNId()

          }
          else {
            const key = enqueueSnackbar("No Materials found for this project", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
      
    }



  }

  const getItemsByCNId=()=>{
    getMaterialForecastReportByCN({ construction_name_id: state?.constructionname_id })
      .then((res) => {
        // console.log("response by CN:",res)
        setLoading(false)
        if (res?.status < 300) {

          if (res?.data?.obj?.item) {
            // setState({
            //   ...state,
            //   header: res?.data?.obj.header_logo,
            //   footer: res?.data?.obj?.footer_logo,
            //   agency_name: res?.data?.obj?.agency_name,
            //   project_name: res?.data?.obj?.item?.project_name,
            //   company: res?.data?.obj?.item?.company,
            //   project_id: res?.data?.obj?.item?.project_id

            // })

            if (res?.data?.obj?.item?.length > 0) {
              setTableData(res?.data?.obj?.item)
              const grand_total = res?.data?.obj?.item?.reduce((sum, item) => sum + parseFloat(parseFloat(item?.rate ? item?.rate : 0) * parseFloat(item?.qty ? item?.qty : 0)), 0)
              setTotalAmount(parseFloat(grand_total).toFixed(2))
            }

          }
          else {
            const key = enqueueSnackbar("No Materials found for this project", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
  }

  const handleReportDownload = (isPrint) => {
    const doc = new jsPDF();
    const contentHeight = 150;
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
          scaledHeight = pdfHeight;
          scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10; 
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(10); // Set font size

      doc.text("STATEMENT OF MATERIAL FORECAST", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("STATEMENT OF MATERIAL FORECAST") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);

      const prTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const cnTextWidth = doc.getStringUnitWidth("Construction Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;

      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY+10);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.project_name}`, textX + (reportType==="CN"?cnTextWidth:prTextWidth) +5, textY+10);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY+15);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.agency_name}`, textX + (reportType==="CN"?cnTextWidth:prTextWidth) +5, textY+15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY+20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.project_id}`, textX + (reportType==="CN"?cnTextWidth:prTextWidth) +5, textY+20);

      if(reportType==="CN"){
        doc.setFont('helvetica', 'bold');
      doc.text("Construction Name:", textX, textY+25);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.construction_name}`, textX + cnTextWidth +5, textY+25);
      }

      doc.setFont('helvetica', 'normal');

      const headerSectionHeight =reportType==="CN"?scaledHeight+40: scaledHeight+35; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;
      doc.setFont('helvetica', 'normal');
      const body = []


      tableData && tableData?.length > 0 && tableData?.forEach((elem) => {

        body.push(
          [
          {content:elem?.item_code,styles:{fontSize:8}},
          {content:elem?.description,styles:{fontSize:8}},
          { content: formatNumberWithCommasAndDecimals(parseFloat(elem?.qty ? elem?.qty : 0).toFixed(2)), styles: { halign: "right",fontSize:8 } },
          { content: elem?.unit_name, styles: { halign: "center",fontSize:8 } },
          { content: formatNumberWithCommasAndDecimals(parseFloat(elem?.rate ? elem?.rate : 0).toFixed(2)), styles: { halign: "right",fontSize:8 } },
          { content: formatNumberWithCommasAndDecimals(parseFloat(parseFloat(elem?.rate ? elem?.rate : 0) * parseFloat(elem?.qty ? elem?.qty : 0)).toFixed(2)), styles: { halign: "right",fontSize:8 } }
          ]
        )



      })
      if (tableData?.length > 0) {
        body.push([{ content: "Grand Total", colSpan: 5, styles: { fontStyle: "bold", valign: "middle", halign: "right",fontSize:8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold",fontSize:8 } }])
      }
      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "BML Code", colSpan: 1, styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
          { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
          { content: "Quantity", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } },
          { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Rate (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } },
          { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          1: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
        },
      });

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        doc.addImage(`${ServiceUrl?.showImageUrl}${state?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }


      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('Statement_Of_Material_Forecast_Report.pdf');

    });
  };

  return (
    <Container>

      <div style={{ height: 'auto', width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>

          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Resource Forecast</b></Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={12}>
                <RadioGroup
                  row
                  name="reportType"
                  value={reportType}
                  onChange={(e) => { setReportType(e.target.value); setTableData([]);setState({project_location_id:"",constructionname_id:""}) }}
                >

                  <FormControlLabel
                    label="Project"
                    value="PR"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ mr: 3, height: 20 }}
                  />
                  <FormControlLabel
                    label="Construction Name"
                    value="CN"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ height: 20 }}
                  />
                </RadioGroup>

              </Grid>
              <Grid item sm={5} xs={12}>
                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </DecoratedTextField>
              </Grid>
            {reportType==="CN"&&<Grid item sm={4} xs={12}>

                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  label="Construction Name"
                  id="constructionname_id"
                  variant="outlined"
                  sx={{ mb: 3 }}
                  name="constructionname_id"
                  value={state?.constructionname_id || ""}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Construction Name is required"]}
                >
                  {constructionNameList?.map((item) => (
                    <MenuItem value={item?.constructionname_id} key={item?.constructionname_id}>
                      {item?.construction_name}
                    </MenuItem>
                  ))}
                </DecoratedTextField>

              </Grid>}

              <Grid item sm={3} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {
          tableData && tableData?.length > 0 &&
          <Grid container>
            <Grid xs={12} md={6}>
              {checkValidation(state?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  disabled={state?.header ? false : true}
                  color="warning"
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  disabled={state?.header ? false : true}
                  color="primary"
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>

          </Grid>}
        {/* table */}
        {
          tableData && tableData?.length > 0 &&
          <Card sx={{ padding: 3 }}>

            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${state?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

                </div>

                <Grid container >
                  <Grid item xs={12} align='center' >
                    <FormLabel sx={{ fontSize: 22 }} ><b>STATEMENT OF MATERIAL FORECAST</b></FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{state?.project_name}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{state?.agency_name}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Id: </b>{state?.project_id}</FormLabel>
                  </Grid>
                  {reportType==="CN"&&<Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Construction Name: </b>{state?.construction_name}</FormLabel>
                  </Grid>}


                </Grid>
              </div>

              <div id="tableData">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ backgroundColor: '#87CEEB' }}>
                      <TableRow>
                        <TableCell>BML Code</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Unit</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>Rate (Nu)</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>Amount (Nu)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        tableData?.map((elem) => {
                          return (
                            <TableRow>
                              <TableCell>{elem?.item_code}</TableCell>
                              <TableCell>{elem?.description}</TableCell>
                              <TableCell>{elem?.qty ? elem?.qty : 0}</TableCell>
                              <TableCell>{elem?.unit_name}</TableCell>
                              <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(elem?.rate ? elem?.rate : 0).toFixed(2))}</TableCell>
                              <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(parseFloat(elem?.rate ? elem?.rate : 0) * parseFloat(elem?.qty ? elem?.qty : 0)).toFixed(2))}</TableCell>
                            </TableRow>
                          )
                        })

                      }
                      {tableData?.length > 0 &&
                        <TableRow>
                          <TableCell colSpan={5} align="right"><b>Grand Total</b></TableCell>
                          <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                        </TableRow>
                      }

                    </TableBody>
                  </Table>
                </TableContainer>

              </div>
            </div>
          </Card>}

      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please Wait."
        />
      )}

    </Container>

  )
}