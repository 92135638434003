import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

 const PriceAdjustmentRequest = Loadable(lazy(() => import('./PriceAdjustmentRequest')));
 const PriceAdjustmentVerification = Loadable(lazy(() => import('./PriceAdjustmentVerification')));
 const PriceAdjustmentApproval = Loadable(lazy(() => import('./PriceAdjustmentApproval')));



const priceAdjustmentRoutes = [
  {
    path: '/NewRequest',
    element: <PriceAdjustmentRequest/>,
  },
  {
    path: '/PriceAdjustmentVerification',
    element: <PriceAdjustmentVerification/>,
  },
  {
    path: '/PriceAdjustmentApproval',
    element: <PriceAdjustmentApproval/>,
  }
];

export default priceAdjustmentRoutes;