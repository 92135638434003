import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const BSR = Loadable(lazy(() => import('./BSR')));
const BaseLocation = Loadable(lazy(() => import('./BaseLocation')));
const NewBaseLocation = Loadable(lazy(() => import('./NewBaseLocation')));
const NewClassification = Loadable(lazy(() => import('./NewClassification')));
const NewSection = Loadable(lazy(() => import('./NewSection')));
const NewChapter = Loadable(lazy(() => import('./NewChapter')));
const NewSubChapter = Loadable(lazy(() => import('./NewSubChapter')));
const NewCategory = Loadable(lazy(() => import('./NewCategory')));
const NewSubCategory = Loadable(lazy(() => import('./NewSubCategory')));
const NewBMLItem = Loadable(lazy(() => import('./NewBMLItem')));
const BSRMaster = Loadable(lazy(() => import('./BSRMaster')));
const QuickCost = Loadable(lazy(() => import('../QuickCost/QuickCost')));

const bsrRoutes = [
  {
    path: '/bsr',
    element: <BSR />
  },

  {
    path: '/BSRMaster',
    element: <BSRMaster />
  },
  {
    path: '/baselocation',
    element: <BaseLocation />
  },
  {
    path: '/newBaseLocation',
    element: <NewBaseLocation />
  },
  {
    path: '/newClassification',
    element: <NewClassification />
  },
  {
    path: '/newSection',
    element: <NewSection />
  },
  {
    path: '/newChapter',
    element: <NewChapter />
  },
  {
    path: '/newSubChapter',
    element: <NewSubChapter />
  },
  {
    path: '/newCategory',
    element: <NewCategory />
  },
  {
    path: '/newSubCategory',
    element: <NewSubCategory />
  },
  {
    path: '/newBMLItem',
    element: <NewBMLItem />
  },
  {
    path: '/QuickCost',
    element: <QuickCost />
  }


];

export default bsrRoutes;
