import { GET_PROJECT_LOCATION_LIST_PRICE, MATERIAL_LABOUR_INDEX, PRICE_ADJUSTMENT_CATEGORY_LIST, PRICE_ADJUSTMENT_LIST } from "../actions/PriceAdjustmentActions";

  
  const initialState = {
    projectLocationList: [],
    priceAdjustmentList: [],
    priceAdjustmentCategoryList: [],
    materialLabourIndexObj: {}
  }
  
  const PriceAdjustmentReducer = (state = initialState, action) => {
    switch (action.type) {

      case MATERIAL_LABOUR_INDEX: {
        return {
            ...state,
            materialLabourIndexObj: action.payload,
          };
      }

      case PRICE_ADJUSTMENT_CATEGORY_LIST: {
        return {
            ...state,
            priceAdjustmentCategoryList: [...action.payload],
          };
      }
      case PRICE_ADJUSTMENT_LIST: {
        return {
            ...state,
            priceAdjustmentList: [...action.payload],
          };
      }

      case GET_PROJECT_LOCATION_LIST_PRICE: {
        return {
            ...state,
            projectLocationList: [...action.payload],
          };
      }

      default: {
        return {...state}
      }
    }
  };
  
  export default PriceAdjustmentReducer;
  