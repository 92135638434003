import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormControlLabel, FormLabel, Grid, Icon, MenuItem, Radio, RadioGroup, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllClassificationsList } from "app/redux/actions/BSRActions";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getProjectBMLReport, getProjectBSRReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const ProjectBSRReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("bml")
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [totalAmount, setTotalAmount] = useState()
  const [projectDetails, setProjectDetails] = useState()
  const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  const { classificationList, isLoading } = useSelector((state) => state.bsr);
  const [response, setResponse] = useState()
  const [tableData, setTableData] = useState([])
  const [chapterList, setChapterList] = useState([])
  const [chapterName, setChapterName] = useState("All")
  const [filteredTableData, setFilteredTableData] = useState([])
    ;
  const [state, setState] = useState({
    project_location_id: '',
    chapter_id: 0
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
    dispatch(getAllClassificationsList(false))
  }, []);

  useEffect(() => {
    console.log("Classification List:", classificationList)
  }, [classificationList])

  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {
    if (tableData?.length > 0) {
      setTableData([])
      setFilteredTableData([])
      setChapterList(["All"])
    }

    if (event.target.name === "project_location_id") {
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });

      setState({
        ...state, [event.target.name]: event.target.value,
        project_name: filteredArray[0].project_name,
        project_location: filteredArray[0]?.project_location,
        project_location_id: filteredArray[0].project_location_id,
        project_id: filteredArray[0].project_id,
        bsr_year: filteredArray[0].bsr_year

      });

    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }
  const handleChapters = (e) => {
    setChapterName(e.target.value)
  }
  useEffect(() => {
    if (chapterList?.length > 1) {
      if (chapterName === "All") {
        setFilteredTableData(tableData)
      }
      else {
        const data = tableData?.filter((elem) => {
          return chapterName === elem?.chapter_name
        })
        setFilteredTableData(data)
      }
    }
  }, [chapterName])

  useEffect(() => {
    if (filteredTableData?.length > 0) {
      const GrandTotal = filteredTableData.reduce((acc, item) => acc + parseFloat(item.subTotal), 0);
      setTotalAmount(parseFloat(GrandTotal).toFixed(2))
    }
  }, [filteredTableData])

  useEffect(() => {
    if (tableData?.length > 0) {
      setFilteredTableData(tableData)
    }
  }, [tableData])


  const handleFormSubmit = () => {
    setLoading(true)
    setTableData([])
    if (reportType === "bsr") {
      getProjectBSRReport({ projectlocation_id: state?.project_location_id, classification_id: state?.classification_id, chapter_id: state?.chapter_id })
        .then((res) => {
          setLoading(false)
          console.log(res.data)
          if (res?.status < 300) {

            if (res?.data?.success === true && res?.data?.obj?.item && res?.data?.obj?.item?.length > 0) {
              setResponse(res?.data?.success)
              const tableDataList = res?.data?.obj?.item?.map((elem) => {
                const chapter_name = elem?.chapter_name
                const dataList = elem?.item_list?.map((item, index) => {
                  return {
                    sl_no: index + 1,
                    code: item?.code,
                    description: item?.description,
                    unit: item?.unit_name,
                    rate: parseFloat(item?.builtupitem_rate)
                  }
                })
                const SubTotalAmount = dataList.reduce((accumulator, currentItem) => {
                  return accumulator + currentItem.rate;
                }, 0);
                const obj = {
                  chapter_name: chapter_name,
                  data: dataList,
                  subTotal: parseFloat(parseFloat(SubTotalAmount).toFixed(2))
                }
                return obj
              })
              let chapters = tableDataList?.map(elem => elem?.chapter_name)
              chapters?.unshift("All")
              setChapterList(chapters)
              const GrandTotal = tableDataList.reduce((acc, item) => acc + item.subTotal, 0);
              setTotalAmount(parseFloat(GrandTotal).toFixed(2))
              console.log("Table Data List: ", tableDataList)
              setTableData(tableDataList)
              setProjectDetails({

                header: res?.data?.obj?.header_logo,
                footer: res?.data?.obj?.footer_logo

              })


            }
            else {
              setResponse(false)
            }

          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);

          }
        }).catch(error => {
          setLoading(false)
          const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        })
    }
    if (reportType === "bml") {

      getProjectBMLReport({ projectlocation_id: state?.project_location_id, classification_id: state?.classification_id, chapter_id: state?.chapter_id }).then((res) => {
        setLoading(false)
        console.log("response of BML:", res?.data)
        if (res?.status < 300) {

          if (res?.data?.success && res?.data?.obj?.item && res?.data?.obj?.item?.length > 0) {
            setResponse(res?.data?.success)
            const tableDataList = res?.data?.obj?.item?.map((elem) => {
              const chapter_name = elem?.chapter_name
              const dataList = elem?.item_list?.map((item, index) => {
                return {
                  sl_no: index + 1,
                  code: item?.code,
                  description: item?.description,
                  unit: item?.unit_name,
                  rate: item?.rate ? (item?.rate).toFixed(2) : 0
                }
              })
              const SubTotalAmount = dataList.reduce((accumulator, currentItem) => {
                return accumulator + parseFloat(currentItem.rate);
              }, 0);
              const obj = {
                chapter_name: chapter_name,
                data: dataList,
                subTotal: SubTotalAmount?.toFixed(2)
              }
              return obj
            })
            let chapters = tableDataList?.map(elem => elem?.chapter_name)
            chapters?.unshift("All")
            setChapterList(chapters)

            console.log("Table Data List: ", tableDataList)
            setTableData(tableDataList)
            setProjectDetails({

              header: res?.data?.obj?.header_logo,
              footer: res?.data?.obj?.footer_logo

            })


          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
            setResponse(false)
          }

        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    }


    function mergeByConstructionType(data) {
      console.log("data", data)
      const result = data.reduce((acc, curr) => {
        console.log("current", curr)
        const constructionType = curr.construcion_type;
        const totalAmount = curr.item_list.reduce((sum, item) => sum + item.amount, 0);

        // Create a new object with construction_type and total
        acc.push({ description: `Total for ${constructionType} (Nu)`, total: totalAmount });
        return acc;
      }, []);

      return result;
    }


  }

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.footer}`
      // Calculate the width and height of the header image based on the PDF page width
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
        scaledHeight = pdfHeight;
        scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10;
      // Insert the header image into the PDF
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)


      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text(`${reportType === "bml" ? "BML Report" : "BSR Report"}`, (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth(`${reportType === "bml" ? "Project BML Report" : "Project BSR Report"}`) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      doc.setFontSize(8); // Set font size

      const textWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;

      doc.text("Project Location:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(state?.project_location, textX + textWidth + 5, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Project Name:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(state?.project_name, textX + textWidth + 5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.project_id}`, textX + textWidth + 5, textY + 20);
      doc.setFont('helvetica', 'bold');
      doc.text("BSR Year:", textX, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.bsr_year}`, textX + textWidth + 5, textY + 25);

      function transformData(data) {
        const transformedData = [];
        data.forEach((section) => {

          section?.data?.forEach((elem) => {
            const rate = formatNumberWithCommasAndDecimals(elem.rate)
            elem.rate = rate

          })
          // Create a row for the section header value (spanning across all 8 columns)
          const headerRow = [{ content: section.chapter_name, colSpan: 5, styles: { fontStyle: "bold", fontSize: 8 } }];
          transformedData.push(headerRow);

          // Loop through the data of the section and create rows for each object in the data
          section.data.forEach((row, index) => {
            transformedData.push([
              { content: index + 1, styles: { fontSize: 8 } },
              { content: row?.code ?? "", styles: { fontSize: 8 } },
              { content: row?.description ?? "", styles: { fontSize: 8 } },
              { content: row?.unit ?? "", styles: { fontSize: 8 } },
              { content: row?.rate ?? "", styles: { fontSize: 8 } }
            ]);

          });
          // const subTotalRow = [{ content: "Sub Total", colSpan: 4, styles: { valign: "middle", halign: "right", fontStyle: "bold",fontSize:8 } }, { content: formatNumberWithCommasAndDecimals(section.subTotal), styles: { valign: "middle", fontStyle: "bold",fontSize:8 } }];
          // transformedData.push(subTotalRow);
        });
        return transformedData;
      }

      const transformedData = transformData(filteredTableData);
      //transformedData.push([{ content: "Grand Total", colSpan: 4, styles: { halign: "right", valign: "middle", fontStyle: "bold",fontSize:8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { valign: "middle", fontStyle: "bold",fontSize:8 } }])
      const headerSectionHeight = scaledHeight + 35; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;
      doc.setFont('helvetica', 'normal');
      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "SL NO.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "CODE", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "DESCRIPTION", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "UNIT", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "RATE(Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } },
        ]],
        body: transformedData, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          2: { cellWidth: 80 }, // "ITEM DESCRIPTION" column width
          4: { halign: "right" }, // "AMOUNT" column width
          3: { halign: "center" }, // "UNIT" column width
        },
      });

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(footer, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }


      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save(reportType === "bsr" ? 'ProjectBSR_Report.pdf' : 'ProjectBML_Report.pdf');

    });
  };



  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold">Project BML/BSR</b></Grid>
            </Grid>

            <Grid container spacing={3} >
              <Grid item xs={12}>
                <RadioGroup
                  row
                  name="reportType"
                  value={reportType}
                  onChange={(e) => { setReportType(e.target.value); setTableData([]); setFilteredTableData([]); setChapterList([]); setResponse(true); setChapterName("All") }}
                >

                  <FormControlLabel
                    label="Basic Material and Labour Rates"
                    value="bml"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ mr: 3, height: 20 }}
                  />
                  <FormControlLabel
                    label="Bhutan Schedule of Rates"
                    value="bsr"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ height: 20 }}
                  />
                </RadioGroup>

              </Grid>

              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Classification"
                  id="classification_id"
                  variant="outlined"
                  name="classification_id"
                  value={state?.classification_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {classificationList?.map((item) => (
                    <MenuItem value={item?.classification_id} key={item?.classification_id}>
                      {item?.classification_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>


              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {chapterList && chapterList?.length > 1 &&
          <Grid container>
            <Grid xs={12} sm={6} >
              {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  color="warning"
                  disabled={projectDetails?.header ? false : true}
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  color="primary"
                  disabled={projectDetails?.header ? false : true}
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>


          </Grid>}
        {chapterList && chapterList?.length > 1 &&
          <Grid container>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                size="small"
                fullWidth
                sx={{ backgroundColor: "white" }}
                label="Chapter Name"
                id="chapter_name"
                variant="outlined"
                name="chapter_name"
                value={chapterName}
                onChange={handleChapters}
              //validators={["required"]}
              //errorMessages={["Project ID is required"]}
              >
                {chapterList?.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>}

        {chapterList && chapterList?.length > 1 && filteredTableData?.length > 0 && <Card sx={{ padding: 3 }}>

          <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
            <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />
              </div>

              <Grid container >
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{state?.project_location}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{state?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{state?.project_id}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>BSR Year: </b>{state?.bsr_year}</FormLabel>
                </Grid>

              </Grid>
            </div>

            <div id="tableData">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell>SL No.</TableCell>
                      <TableCell>CODE</TableCell>
                      <TableCell>DESCRIPTION</TableCell>
                      <TableCell>UNIT</TableCell>
                      <TableCell style={{ align: 'right' }}>RATE (Nu)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {filteredTableData?.map((section) => (
                      <React.Fragment key={section.chapter_name}>
                        <TableRow>
                          <TableCell colSpan={5} style={{ fontWeight: 600 }}>{section?.chapter_name}</TableCell>
                        </TableRow>
                        {section.data.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.sl_no}</TableCell>
                            <TableCell>{row.code}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{row.unit}</TableCell>
                            <TableCell align="right">{formatNumberWithCommasAndDecimals(row.rate)}</TableCell>
                          </TableRow>
                        ))}
                        {/* <TableRow>
                          <TableCell colSpan={4} align="right" style={{ fontWeight: 600 }}><b>Sub Total</b></TableCell>
                          <TableCell colSpan={1} align="right" style={{ fontWeight: 600 }}><b>{formatNumberWithCommasAndDecimals(section?.subTotal)}</b></TableCell>
                        </TableRow> */}
                      </React.Fragment>
                    ))}
                    {/* <TableRow>
                      <TableCell colSpan={4} align="right" style={{ fontWeight: 600 }}><b>Grand Total</b></TableCell>
                      <TableCell colSpan={1} align="right" style={{ fontWeight: 600 }}><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          </div>
        </Card>}


      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}