import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, FormControlLabel, FormLabel, Grid, Icon, MenuItem, Radio, RadioGroup, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { BarChart } from "app/components/charts/BarChart";
import { getAllConstructionNameListByParameters } from "app/redux/actions/ProjectLocationActions";
import { getAllProjectLocationListForBill } from "app/redux/actions/RABillActions";
import { getPhysicalAndFinalProgressCNLevelReport, getPhysicalAndFinalProgressReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from "app/utils/Constants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const PhysicalFinancialReport = () => {

  const dispatch = useDispatch();
  const chart = useRef()

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [projectDetails, setProjectDetails] = useState()
  const [isDownload, setIsDownload] = useState(false)
  const [isPrint, setIsPrint] = useState(false)
  const { projectLocationList } = useSelector((state) => state.raBill);
  const [reportType, setReportType] = useState('project')
  const [maxValue, setMaxValue] = useState(0)
  const [constructionNameList, setConstructionNameList] = useState([])
  const [tableData, setTableData] = useState([])
  const [grandTotal, setGrandTotal] = useState()

  const [state, setState] = useState({
    project_location_id: '',
    construction_name_id: ""
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForBill(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {
    setTableData()

    if (event.target.name === "project_location_id") {
      const filteredArray = projectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value });

      //dispatch(getAllConstructionNameListByParams(0, filteredArray[0].project_location_id, showLoader, hideLoader, showToast))
      getAllConstructionNameList(0, filteredArray[0].project_location_id, filteredArray[0].project_id)

    } else {
      setState({ ...state, [event.target.name]: parseInt(event.target.value) });
    }
  }
  const getAllConstructionNameList = (project_location_id, constructionname_id, project_id) => {
    setLoading(true)
    getAllConstructionNameListByParameters(project_location_id, constructionname_id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {

        setConstructionNameList(res?.data)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);

    })
  }

  const handleFormSubmit = async () => {
    if (state?.project_location_id === "") {
      const key = enqueueSnackbar("Please select project location", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }

    else {
      setLoading(true)
      setTableData()

      if (reportType === "project") {
        getPhysicalAndFinalProgressReport({ project_location_id: state?.project_location_id }).then((res) => {
          setLoading(false)
          if (res?.status < 300 && res?.data?.success) {
            if (res?.data?.obj && res?.data?.obj?.item) {
              if (Object.keys(res?.data?.obj?.item?.item)?.length > 0) {
                const table = []
                Object.keys(res?.data?.obj?.item?.item).forEach(key => {
                  let obj = {}
                  const constructionName = key
                  const itemList = res?.data?.obj?.item?.item[key];
                  itemList?.forEach((elem) => {
                    let financial = elem?.financial_percent_completed ? elem?.financial_percent_completed : 0
                    let physical = elem?.physical_percent_completed ? elem?.physical_percent_completed : 0
                    let total = elem?.total_construction_amount ? elem?.total_construction_amount : 0
                    obj.financial_percent_completed = financial
                    obj.physical_percent_completed = physical
                    obj.total_construction_amount = total
                  })
                  if (itemList?.length > 0) {
                    const subTotal = itemList?.reduce((sum, item) => sum + parseFloat(item?.total_construction_amount ? item?.total_construction_amount : 0), 0)
                    obj.construction_name = constructionName
                    obj.sub_total = subTotal

                  }
                  console.log("obj:", obj)
                  table.push(obj)
                });
                setTableData(table)
              }
              else {
                const key = enqueueSnackbar("No comparison found", { variant: 'info' });
                SnackBarProperty.info(key, closeSnackbar);
              }
              setProjectDetails({
                company: res?.data?.obj?.item?.company,
                agency_name: res?.data?.obj?.item?.agency_name,
                contract_no: res?.data?.obj?.item?.tender_id,
                project_id: res?.data?.obj?.item?.project_id,
                project_name: res?.data?.obj?.item?.project_name,
                dzongkhag_name: res?.data?.obj?.item?.dzongkhagName,
                header: res?.data?.obj?.header_logo,
                footer: res?.data?.obj?.footer_logo

              })

            }
            else {
              const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
              SnackBarProperty.error(key, closeSnackbar);

            }

          }
        }).catch(error => {
          setLoading(false)
          const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        })
      }
      else {
        getPhysicalAndFinalProgressCNLevelReport({ construction_name_id: state?.construction_name_id, project_location_id: state?.project_location_id }).then(res => {
          setLoading(false)
          if (res?.status < 300) {
            if (res?.data?.obj && res?.data?.obj?.item) {
              if (res?.data?.obj?.item?.WorkPlanVsPhysicalProgressCNLevel?.length > 0) {
                //const table = []
                // res?.data?.obj?.item?.WorkPlanVsPhysicalProgressCNLevel.forEach(item => {
                // let obj = {}
                const constructionName = res?.data?.obj?.item?.WorkPlanVsPhysicalProgressCNLevel?.construction_name
                const itemList = res?.data?.obj?.item?.WorkPlanVsPhysicalProgressCNLevel;
                // itemList?.forEach((elem) => {
                //   let financial = elem?.financial_percent_completed ? elem?.financial_percent_completed : 0
                //   let physical = elem?.physical_percent_completed ? elem?.physical_percent_completed : 0
                // //  let total = elem?.total_construction_amount ? elem?.total_construction_amount : 0
                //   obj.financial_percent_completed = financial
                //   obj.physical_percent_completed = physical
                //   obj.total_construction_amount = total
                // })
                // if (itemList?.length > 0) {
                //   const subTotal = itemList?.reduce((sum, item) => sum + parseFloat(item?.total_construction_amount ? item?.total_construction_amount : 0), 0)
                //   obj.construction_name = constructionName
                //   obj.sub_total = subTotal

                // }

                // console.log("obj:",obj)
                // table.push(itemList)
                setTableData(itemList)
              }
              else {
                const key = enqueueSnackbar("No comparison found", { variant: 'info' });
                SnackBarProperty.info(key, closeSnackbar);
              }
              setProjectDetails({
                company: res?.data?.obj?.item?.company,
                agency_name: res?.data?.obj?.item?.agency_name,
                contract_no: res?.data?.obj?.item?.tender_id,
                project_id: res?.data?.obj?.item?.project_id,
                project_name: res?.data?.obj?.item?.project_name,
                dzongkhag_name: res?.data?.obj?.item?.dzongkhagName,
                header: res?.data?.obj?.header_logo,
                footer: res?.data?.obj?.footer_logo

              })

            }
            else {
              const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
              SnackBarProperty.error(key, closeSnackbar);

            }

          }
        }).catch(error => {
          setLoading(false)
          const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        })
      }

    }




  }
  useEffect(() => {
    if (tableData && tableData?.length > 0) {
      const maxPercentCompleted = Math.max(
        ...tableData.map(item => item.financial_percent_completed),
        ...tableData.map(item => item.physical_percent_completed)
      );
      const totalPhysical = tableData?.reduce((sum, item) => sum + parseFloat(item?.physical_percent_completed ? item?.physical_percent_completed : 0), 0)
      const totalFinancial = tableData?.reduce((sum, item) => sum + parseFloat(item?.financial_percent_completed ? item?.financial_percent_completed : 0), 0)
      const obj = {
        construction_name: "Overall Completion",
        physical_percent_completed: parseFloat(totalPhysical / 2),
        financial_percent_completed: parseFloat(totalFinancial / 2)
      }
      tableData.unshift(obj)
      console.log("sub:", maxPercentCompleted)
      setMaxValue(parseInt(maxPercentCompleted))
    }
  }, [tableData])
  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrint(true)
      setIsDownload(false)
    }
    else {
      setIsPrint(false)
      setIsDownload(true)
    }
    const doc = new jsPDF();

    doc.setFontSize(12); // Set font size

    if (chart.current) {
      html2canvas(chart.current, {
        useCORS: true, // Add this line if the image is from a different domain
        allowTaint: true,
        scale: 2 // Add this line if the image is from a different domain
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.footer}`
        // Calculate the width and height of the header image based on the PDF page width
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        const headerHeight = pdfHeight;
        const tableY = headerHeight + 15;
        // Insert the header image into the PDF
        const topBorderPadding = 10; // Adjust the padding value as needed
        doc.setDrawColor(255, 255, 255); // Black color for border
        doc.setLineWidth(topBorderPadding); // Border width (padding)

        // Draw the top border (padding)
        doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

        doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.header ? projectDetails?.header : "moit_header.jpg"}`, "PNG", 25, topBorderPadding, pdfWidth - 50, 25)
        doc.setFont('helvetica', 'bold'); // Set font to bold
        doc.setFontSize(12); // Set font size
        doc.text("PHYSICAL VS FINANCIAL PROGRESS", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("PHYSICAL VS FINANCIAL PROGRESS") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), topBorderPadding + 35);

        doc.setFontSize(8); // Set font size
        doc.text("Project Name:", 15, topBorderPadding + 50);
        const firstTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.setFont('helvetica', 'normal');
        doc.text(projectDetails?.project_name, 15 + firstTextWidth + 5, topBorderPadding + 50);
        doc.setFont('helvetica', 'bold');
        doc.text("Client:", 15, topBorderPadding + 55);
        const secondTextWidth = doc.getStringUnitWidth("Client:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.setFont('helvetica', 'normal');
        doc.text(projectDetails?.agency_name, 15 + secondTextWidth + 5, topBorderPadding + 55);
        doc.setFont('helvetica', 'bold');
        doc.text("Project Id:", 15, topBorderPadding + 60);
        const thirdTextWidth = doc.getStringUnitWidth("Project ID:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.setFont('helvetica', 'normal');
        doc.text(`${projectDetails?.project_id}`, 15 + thirdTextWidth + 5, topBorderPadding + 60);
        doc.setFont('helvetica', 'normal');
        doc.addImage(imgData, "PNG", 15, topBorderPadding + 65, pdfWidth - 30, 60);
        // Insert the header image into the PDF
        const textX = 15;
        const textY = tableY + 5; // Adjust the value for vertical separation

        function addFooter(pageNumber) {
          // const footerText = "Hello CMS User";
          // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
          const footerX = 0; // Starting X position for the footer
          const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = 10;
          // const availableWidth = pdfWidth - 2 * footerX;

          // doc.setTextColor(0, 0, 0); // Set text color to black
          // doc.setFontSize(10); // Set font size for the footer

          // // Calculate the width of the text and page numbers
          // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

          // // Calculate the space needed for equal spacing
          // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

          // // Add footer text and page numbers in the same row, justified with space between them
          // doc.text(footerX, footerY, footerText);
          // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
          doc.addImage(footer, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
        }

        // Get the total number of pages
        const totalNumPages = doc.internal.getNumberOfPages();

        // Loop through each page to add the footer with page numbers
        for (let i = 1; i <= totalNumPages; i++) {
          doc.setPage(i);
          addFooter(i);
        }


        const scaleFactor = 1.0; // Adjust this value as needed
        const pdfBlob = doc.output('blob', { scale: scaleFactor });

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open a new window with the PDF URL

        if (isPrint) {
          const newWindow = window.open(pdfUrl, '_blank');

          // After the new window is opened, you can close the URL object
          URL.revokeObjectURL(pdfUrl);
          if (newWindow) {
            newWindow.onload = () => {
              setIsPrint(false)
              setIsDownload(false)
              // Wait for the window to load and then trigger the print dialog
              newWindow.print();
            };
          }
        }

        setIsPrint(false)
        setIsDownload(false)

        isPrint === false && doc.save("report_including_chart_sample.pdf");

      });
    }

  };



  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Physical Vs Financial</b></Grid>
            </Grid>
            <Grid container spacing={3} >

              <Grid item xs={12}>
                <RadioGroup
                  row
                  value={reportType}
                  name="reportType"
                  onChange={(e) => { setReportType(e.target.value); setTableData([]); setState({ project_location_id: "", construction_name_id: "" }); setConstructionNameList() }}

                >

                  <FormControlLabel
                    label="Project Level"
                    value="project"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ mr: 3, height: 20 }}
                  />
                  <FormControlLabel
                    label="Construction Name Level"
                    value="construction"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ height: 20 }}
                  />
                </RadioGroup>

              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project id is required"]}
                >
                  {projectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {reportType === "construction" &&
                <Grid item sm={4} xs={12}>

                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    label="Construction Name"
                    id="construction_name_id"
                    variant="outlined"
                    sx={{ mb: 3 }}
                    name="construction_name_id"
                    value={state?.construction_name_id || ""}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Construction Name is required"]}
                  >
                    {constructionNameList?.map((item) => (
                      <MenuItem value={item?.constructionname_id} key={item?.constructionname_id}>
                        {item?.construction_name}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>

                </Grid>
              }


              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>

        {
          tableData && tableData?.length > 0 &&
          <Grid container>
            <Grid xs={12} md={6}>
              {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  disabled={projectDetails?.header ? false : true}
                  color="warning"
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  disabled={projectDetails?.header ? false : true}
                  color="primary"
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>

          </Grid>}

        {/* table */}
        {
          tableData && tableData?.length > 0 &&
          <SimpleCard>

            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

                </div>
              </div>
              <Grid container >
                <Grid item xs={12} sx={{ marginTop: 2, textAlign: "center" }}>
                  <FormLabel sx={{ fontSize: 20 }}><b>PHYSICAL VS FINANCIAL PROGRESS</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.agency_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>
              </Grid>
              <Grid container ref={chart}>
                <BarChart reportType={reportType} data={tableData} max={maxValue} />
              </Grid>
            </div>
          </SimpleCard>}


      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrint || isDownload) && (
        <Loader
          show={isPrint ? isPrint : isDownload}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}