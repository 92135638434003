import { GET_PROJECT_LOCATION_LIST_FOR_AGENCY, GET_RECOVERY_STATEMENT_LIST, GET_RESOURCE_FORECAST_CATEGORY_LIST, GET_RESOURCE_FORECAST_LIST, GET_REVIEW_APPROVE_REQUISITION_LIST, RESET_RESOURCE_FORECAST_LIST } from "../actions/ResourceForeCastActions";


const initialState = {
  projectLocationListForAgency: [],
  resourceForecastCategories: [],
  approveReviewRequisitionList: [],
  resourceForecastList: [],
  recoveryStatementList: []
}

const ResourceForecastReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_LOCATION_LIST_FOR_AGENCY: {
      return {
        ...state,
        projectLocationListForAgency: [...action.payload],
      };
    }
    case GET_RECOVERY_STATEMENT_LIST: {
      return {
        ...state,
        recoveryStatementList: [...action.payload],
      };
    }
    case GET_RESOURCE_FORECAST_CATEGORY_LIST: {
      return {
        ...state,
        resourceForecastCategories: [...action.payload]
      }
    }
    case GET_REVIEW_APPROVE_REQUISITION_LIST: {
      return {
        ...state,
        approveReviewRequisitionList: [...action.payload]
      }
    }

    case GET_RESOURCE_FORECAST_LIST: {
      return {
        ...state,
        resourceForecastList: [...action.payload]
      }
    }
    case RESET_RESOURCE_FORECAST_LIST: {
      return {
        ...state,
        resourceForecastList: [...action.payload]
      }
    }

    default: {
      return { ...state }
    }
  }
};

export default ResourceForecastReducer