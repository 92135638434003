import React from "react";
import { Chart } from 'smart-webcomponents-react/chart';

export const RABillVsTimeChart=({data,max})=>{
    console.log("max:",max)
   const caption = 'RA BILL VS TIME GRAPH';
	const description = '';
	const showLegend = true;
	const padding = {
		left: 5,
		top: 5,
		right: 5,
		bottom: 5
	};
	const titlePadding = {
		left: 90,
		top: 0,
		right: 0,
		bottom: 10
	};
	const dataSource = data;
	const xAxis = {
		dataField: 'ra_bill_date',
		gridLines: {
			visible: false
		}
	};

    const colorScheme = 'scheme32';
	const seriesGroups = [{
		type: 'column',
		columnsGapPercent:80,
		seriesGapPercent: 10,
		valueAxis: {
			unitInterval: max>100?50:10,
			minValue: 0,
			maxValue: max+50,
			description: 'RA Amount in (%)',
			axisSize: 'auto'
		},
		series: [{
			dataField: 'amount_paid_in_percent',
			displayText: 'RA Bill Date',
            labels: {
				visible: true,
				verticalAlignment: 'top',
				offset: {
					x: 0,
					y: -20
				}
			},
            formatFunction: function (value) {
				
				return `${value}%`
			}
		}
		
		]
	}];
  
    return(
        <>
      
        <div style={{height:"auto",width:"100%"}}>
		<Chart id="chart"
		style={{height:"300px",width:"100%"}}
					caption={caption}
					description={description}
					showLegend={showLegend}
					padding={padding}
					titlePadding={titlePadding}
					dataSource={dataSource}
					xAxis={xAxis}
					colorScheme={colorScheme}
					seriesGroups={seriesGroups}></Chart>
                </div>
               
        </>
    )
}