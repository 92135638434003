import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { AdvanceReportRecovery } from './advanceRecoveryReport';
import { ConstructionNameReport } from './constructionNameReport';
import { ContractWorkPlanReport } from './contractWorkPlanReport';
import { CostIndexReport } from './costIndexReport';
import { ExecutedBillOfQuantityReport } from './executedBillOfQuantitiesReport';
import { FundForecastReport } from './fundForecastReport';
import { GoodIssueReport } from './goodsIssueReport';
import { GoodsReceiptReport } from './goodsReceiptReport';
import { ItemStockReport } from './itemStockReport';
import { LiquidatedDamageRecoveryReport } from './liquidatedDamagesRecoveryReport';
import { MachineryLogBookReport } from './machineryLogbookReport';
import { MaterialBillDetailsReport } from './materialBillDetailsReport';
import { MaterialConsumptionReport } from './materialConsumptionReport';
import { MaterialForecastReport } from './materialForecastReport';
import { MaterialSupplyOrderReport } from './materialSupplyOrder';
import { MusterRollBillDetailsReport } from './musterRollBillDetailsReport';
import { PartThreeReport } from './partThreeReport';
import { PhysicalFinancialReport } from './physicalVSFinancial';
import { PriceAdjustmentStatementReport } from './priceAdjustmentStatementReport';
import { ProjectBSRReport } from './projectBSRReport';
import { ProjectBoQReport } from './projectBoQReport';
import { ProjectSummaryReport } from './projectSummaryReport';
import { RABillRecoveryReport } from './raBillRecoveryReport';
import { RABillReport } from './raBillReport';
import { RABillSummaryReport } from './raBillSummaryReport';
import { RABillVsTimeReport } from './raBillVsTimeReport';
import { RateAnalysisReport } from './rateAnalysisReport';
import { RecoveryStatementReport } from './recoveryStatementReport';
import { RequisitionDetailsReport } from './requisitionDetailsReport';
import { ResourceForeCastReport } from './resourceForecastReport';
import { StatementOfDeviationReport } from './statementOfDeviationReport';
import { StatementOfRetentionReport } from './statementOfRetentionReport';
import { StatementOfTDSReport } from './statementOfTDSReport';
import { WorkPlanVsPhysicalReport } from './workPlanVsPhysicalReport';
import { WorkerAttendanceReport } from './workerAttendanceReport';
import { BSRQuickCostRateReport } from './quickCostRateReport';
import { ContractDetailsReport } from './contractDetailsReport';
import { GoodsAdjustmentReport } from './goodsAjustmentReport';
import { RABillAmendmentDetailsReport } from './raBillAmendmentDetailsReport';

 const Reports = Loadable(lazy(() => import('./Reports')));


const reportsRoutes = [
  {
    path: '/Reports',
    element: <Reports/>,
  },
  {
    path:'/Reports/ConstructionNameBoQReport',
    element:<ConstructionNameReport/>
  },
  {
    path:'/Reports/ProjectBoQReport',
    element:<ProjectBoQReport/>
  },
  {
    path:'/Reports/AdvanceRecoveryReport',
    element:<AdvanceReportRecovery/>
  },
  {
    path:'/Reports/ProjectBSRReport',
    element:<ProjectBSRReport/>
  },
  {
    path:'/Reports/RateAnalysisReport',
    element:<RateAnalysisReport/>
  },
  {
    path:'/Reports/WorkPlanReport',
    element:<ContractWorkPlanReport/>
  },
  {
    path:'/Reports/GoodsReceiptReport',
    element:<GoodsReceiptReport/>
  },
  {
    path:'/Reports/ItemStockReport',
    element:<ItemStockReport/>
  },
  {
    path:'/Reports/RABillSummaryReport',
    element:<RABillSummaryReport/>
  },
  {
    path:'/Reports/RABillReport',
    element:<RABillReport/>
  },
  {
    path:'/Reports/RABillRecoveryReport',
    element:<RABillRecoveryReport/>
  },
  {
    path:"/Reports/LDRecoveryReport",
    element:<LiquidatedDamageRecoveryReport/>
  },
  {
    path:"/Reports/PriceAdjustmentReport",
    element:<PriceAdjustmentStatementReport/>
  },
  {
    path:"/Reports/PhysicalFinancialReport",
    element:<PhysicalFinancialReport/>
  },
  {
    path:"/Reports/ExecutedBillofQuantityReport",
    element:<ExecutedBillOfQuantityReport/>
  },
  {
    path:"/Reports/ProjectSummaryReport",
    element:<ProjectSummaryReport/>
  },
  {
    path:"/Reports/StatementofTDSReport",
    element:<StatementOfTDSReport/>
  },
  {
    path:"/Reports/StatementofRetentionReport",
    element:<StatementOfRetentionReport/>
  },
  {
    path:"/Reports/StatementofDeviationReport",
    element:<StatementOfDeviationReport/>
  },
  {
    path:"/Reports/RABillTimeReport",
    element:<RABillVsTimeReport/>
  },
  {
    path:"/Reports/FundForecastReport",
    element:<FundForecastReport/>
  },
  {
    path:"/Reports/MaterialForecastReport",
    element:<MaterialForecastReport/>
  },
  {
    path:"/Reports/MaterialConsumptionReport",
    element:<MaterialConsumptionReport/>
  },
  {
    path:"/Reports/WorkPlanPhysicalReport",
    element:<WorkPlanVsPhysicalReport/>
  },
  {
    path:"/Reports/GoodsIssueReport",
    element:<GoodIssueReport/>
  },
  {
    path:"/Reports/ResourceForecastReport",
    element:<ResourceForeCastReport/>
  },
  {
    path:"/Reports/RequisitionDetailsReport",
    element:<RequisitionDetailsReport/>
  },
  {
    path:"/Reports/PartIIIReport",
    element:<PartThreeReport/>
  },
  {
    path:"/Reports/RecoveryStatementReport",
    element:<RecoveryStatementReport/>
  },
  {
    path:"/Reports/MachineryLogbookReport",
    element:<MachineryLogBookReport/>
  },
  {
    path:"/Reports/AttendanceSheetReport",
    element:<WorkerAttendanceReport/>
  },
  {
    path:"/Reports/MaterialSupplyReceiptReport",
    element:<MaterialSupplyOrderReport/>
  },
  {
    path:"/Reports/MaterialBillDetailsReport",
    element:<MaterialBillDetailsReport/>
  },
  {
    path:"/Reports/MusterRollBillDetailsReport",
    element:<MusterRollBillDetailsReport/>
  },
  
  {
    path:"/Reports/CostIndexDetailsReport",
    element:<CostIndexReport/>
  },
  {
    path:"/Reports/QuickCostRate",
    element:<BSRQuickCostRateReport/>
  },
  {
    path:"/Reports/ContractDetailsReport",
    element:<ContractDetailsReport/>
  },
  {
    path:"/Reports/GoodsAdjustmentReport",
    element:<GoodsAdjustmentReport/>
  },
  {
    path:"/Reports/RABillAmendmentReport",
    element:<RABillAmendmentDetailsReport/>
  },
  
  
  
];

export default reportsRoutes;
