import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, Card, FormControlLabel, FormLabel, Grid, Icon, MenuItem, Radio, RadioGroup, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllConstructionNameListByParameters, getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getWorkPlanVsPhysicalProgressConstructionLevel, getWorkPlanVsPhysicalProgressProjectLevel } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from "app/utils/Constants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { GanttChart } from 'smart-webcomponents-react/ganttchart';


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const WorkPlanVsPhysicalReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [reportType, setReportType] = useState("projectLevel")
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [constructionNameList, setConstructionNameList] = useState([])
  const [projectDetails, setProjectDetails] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const { reportsProjectLocationList, projectLevelStagePercentage, constructionLevelStagePercentage } = useSelector((state) => state.projectLocation);
  // const {userList}=useSelector((state)=>state.userList)
  const [originalDetailsList, setOriginalDetailsList] = useState()
  const [filteredDetailsList, setFilteredDetailsList] = useState()
  const [workPlanList,setWorkPlanList]=useState([])
  const [state, setState] = useState({
    project_location_id: '',
    constructionname_id: '',
    workplan_no:"all"
  })

  // const dataSource = [{
  //         label: 'Task 1.1',
  //         disableResize: true,
  //         minDateStart: '2020-2-10',
  //         maxDateEnd: '2021-8-10',
  //         dateStart: '2020-2-10',
  //         dateEnd: '2021-4-10',
  //         type: 'task'
  //     },
  //     {
  //         label: 'Task 1.2',
  //         disableDrag: true,
  //         minDateStart: '2020-3-10',
  //         maxDateEnd: '2021-11-10',
  //         dateStart: '2020-3-10',
  //         dateEnd: '2021-6-10',
  //         type: 'task'
  //     }];

  // const taskColumns = [{
  //     label: 'Tasks',
  //     value: 'label',
  //     size: '40%'
  // },
  // {
  //     label: 'Expected End Date',
  //     value: 'dateEnd'
  // },
  // {
  //     label: 'Duration (days)',
  //     value: 'duration'
  // }
  // ];
  //     const adjustToNonwokringTime = false;

  // 	const nonworkingDays = [0, 6];

  // 	const nonworkingHours = [[18, 6]];

  // 	const durationUnit = 'day';

  // 	const view = 'week';

  // 	const treeSize = '25%';

  // 	const disableSelection = true;
  const dateGap = 14; // Date gap of 14 days

  // Calculate the start and end dates for each column (view)

  const customColumns = [];
  const ganttchart = useRef();
  const treeSize = '15%';
  const durationUnit = 'month';
  const settings = {
    // Gantt Chart settings here
    timeline: {
      scale: 'month',
      // Set the number of months per column (view)
      months: 1,
      // Set the date gap to 14 days
      daysPerColumn: 30,
      dragging: false,
      resizing: false,
      readOnly: true,
    },
    treeGrid: {
      // Disable the ability to expand the tree grid
      allowHierarchy: false,
    },
  };
  const taskColumns = [
    {
      label: 'Info',
      value: "infoIcon",
      size: '40px', // Adjust the size of the Info column as needed
    },
    {
      label: 'Tasks',
      value: 'label',
      size: '140px',
      allowColumnExpand: false,
    },
    {
      label: 'Duration(Days)',
      value: 'days',
      size: '120px',
      allowColumnExpand: false
    }
  ];



  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (message) => {
    const key = enqueueSnackbar(message ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {
   
    if (event.target.name === "project_location_id") {
      setOriginalDetailsList()
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value });

      //dispatch(getAllConstructionNameListByParams(0, filteredArray[0].project_location_id, showLoader, hideLoader, showToast))
      if (reportType === "constructionLevel") {
        getAllConstructionNameList(0, filteredArray[0].project_location_id, filteredArray[0].project_id)
      }


    } 
    else if(event.target.name === "workplan_no"){
      setState({...state,[event.target.name]:event?.target?.value})
      if(event?.target?.value==="all"){
        setFilteredDetailsList(originalDetailsList)
      }
      else{
      const filteredData= originalDetailsList.filter(item => !item.workplan_no || item.workplan_no === event.target.value);
      setFilteredDetailsList(filteredData)
    }
    }
    else {
      setOriginalDetailsList()
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }
  const getAllConstructionNameList = (project_location_id, constructionname_id, project_id) => {
    setLoading(true)
    getAllConstructionNameListByParameters(project_location_id, constructionname_id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        // if (res?.data?.length > 0) {
        //     state.constructionname_id = res?.data[0].constructionname_id
        //     state.construction_name = res?.data[0].construction_name 
        //     state.project_id = project_id
        //     setState({ ...state, ['boq_code']: res?.data[0].boq_code });
        // }
        setConstructionNameList(res?.data)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);

    })
  }

  const handleFormSubmit = () => {
    setOriginalDetailsList()
    setLoading(true)
    if (reportType === "projectLevel") {

      getWorkPlanVsPhysicalProgressProjectLevel({ project_location_id: state?.project_location_id })
        .then((res) => {
          setLoading(false)
          if (res?.status < 300) {
            if (res?.data?.obj) {

              if (res?.data?.obj?.item?.WorkPlanVsPhysicalProgressProjectLevel?.length > 0) {
                setStartDate(new Date(res?.data?.obj?.item?.work_start_date))
                setEndDate(new Date(res?.data?.obj?.item?.work_end_date))
                const dataSource = [{
                  label: res?.data?.obj?.item?.project_name,
                  dateStart: res?.data?.obj?.item?.work_start_date,
                  dateEnd: res?.data?.obj?.item?.work_end_date,
                  days: `${res?.data?.obj?.item?.total_number_of_days} ${res?.data?.obj?.item?.total_number_of_days > 0 ? "days" : "day"}`,
                  type: 'project',
                  styles: { fontWeight: 800 },
                  progress: 100,
                  class: 'product-team',
                  disableDrag: true,
                  disableResize: true,
                  infoIcon: "",
                }
                ];
                res?.data?.obj?.item?.WorkPlanVsPhysicalProgressProjectLevel?.forEach((elem) => {
                  dataSource.push({
                    label: elem?.construction_name,
                    dateStart: elem?.start_date,
                    dateEnd: elem?.end_date,
                    days: `${elem?.noofdays} ${elem?.noofdays > 0 ? "days" : "day"}`,
                    type: 'task',
                    infoIcon: elem?.percent_completion < 100 ? ` <span>&#128197;</span>` : `<span style={{color:"green"}}>&#x2714</span>`,
                    styles: { fontWeight: 800 },
                    progress: elem?.percent_completion,
                    class: 'product-team',
                    disableDrag: true,
                    disableResize: true,
                    expanded: false,
                    
                  })
                })
            
                setOriginalDetailsList(dataSource)
                setProjectDetails({
                  project_name: res?.data?.obj?.item?.project_name,
                  company: res?.data?.obj?.item?.company,
                  project_id: res?.data?.obj?.item?.project_id,
                  header: res?.data?.obj?.header_logo,
                  footer: res?.data?.obj?.footer_logo
                })
              }
              else {
                const key = enqueueSnackbar("Constructions details list not found", { variant: 'info' });
                SnackBarProperty.info(key, closeSnackbar);
              }
            }
            else {
              const key = enqueueSnackbar("No Data Found", { variant: 'info' });
              SnackBarProperty.info(key, closeSnackbar);
            }
          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);

          }
        }).catch(err => {
          setLoading(false)

          const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        })
    }
    else {
      getWorkPlanVsPhysicalProgressConstructionLevel({ construction_name_id: state?.constructionname_id, project_location_id: state?.project_location_id })
        .then((res) => {
          setLoading(false)
          if (res?.status < 300) {
            if (res?.data?.obj) {
              if (res?.data?.obj?.item?.WorkPlanVsPhysicalProgressCNLevel?.length > 0) {
                setStartDate(new Date(res?.data?.obj?.item?.work_start_date))
                setEndDate(new Date(res?.data?.obj?.item?.work_end_date))
                // const totalNumberOfDays=res?.data?.obj?.item?.WorkPlanVsPhysicalProgressCNLevel?.reduce((acc,item)=>acc+item?.noofdays,0)
                const dataSource = [{
                  label: res?.data?.obj?.item?.WorkPlanVsPhysicalProgressCNLevel?.[0]?.construction_name,
                  dateStart: res?.data?.obj?.item?.work_start_date,
                  dateEnd: res?.data?.obj?.item?.work_end_date,
                  days: `${res?.data?.obj?.item?.WorkPlanVsPhysicalProgressCNLevel?.[0]?.total_days ?? 0} ${res?.data?.obj?.item?.WorkPlanVsPhysicalProgressCNLevel?.[0]?.total_days > 0 ? "days" : "day"}`,
                  type: 'project',
                  styles: { fontWeight: 800 },
                  progress: 100,
                  class: 'product-team',
                  disableDrag: true,
                  disableResize: true,
                  infoIcon: "",
                  showProgressLabel: false,
                }
                ];
                res?.data?.obj?.item?.WorkPlanVsPhysicalProgressCNLevel?.forEach((elem) => {
                  dataSource.push({
                    label: elem?.stage_name,
                    dateStart: elem?.start_date,
                    dateEnd: elem?.end_date,
                    days: `${elem?.noofdays} ${elem?.noofdays > 0 ? "days" : "day"}`,
                    type: 'task',
                    infoIcon: elem?.percent_completion < 100 ? ` <span>&#128197;</span>` : `<span style={{color:"green"}}>&#x2714</span>`,
                    styles: { fontWeight: 800 },
                    progress: elem?.percent_completion,
                    class: 'product-team',
                    disableDrag: true,
                    disableResize: true,
                    expanded: false,
                    showProgressLabel: true,
                    workplan_no:elem?.workplan_no
                  })
                })
                setOriginalDetailsList(dataSource)
                setProjectDetails({
                  project_name: res?.data?.obj?.item?.project_name,
                  company: res?.data?.obj?.item?.company,
                  project_id: res?.data?.obj?.item?.project_id,
                  header: res?.data?.obj?.header_logo,
                  footer: res?.data?.obj?.footer_logo
                })
              }

              else {
                const key = enqueueSnackbar("Constructions details list not found", { variant: 'info' });
                SnackBarProperty.info(key, closeSnackbar);
              }
            }
            else {
              const key = enqueueSnackbar("No Data Found", { variant: 'info' });
              SnackBarProperty.info(key, closeSnackbar);
            }
          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);

          }
        }).catch(err => {
          setLoading(false)

          const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        })
    }



  }
  useEffect(() => {
    setFilteredDetailsList(originalDetailsList)
    if (originalDetailsList?.length > 0) {
      
      const uniqueSortedWorkplanNos = [...new Set(
        originalDetailsList.map(item => item.workplan_no).filter(Boolean)
    )].sort((a, b) => a.localeCompare(b));
    setWorkPlanList(uniqueSortedWorkplanNos)
      while (startDate <= endDate) {
        const endDateColumn = new Date(startDate);
        endDateColumn.setDate(startDate.getDate() + dateGap - 1); // Calculate the end date for the column
        customColumns.push({ start: new Date(startDate), end: endDateColumn });
        startDate.setDate(startDate.getDate() + dateGap); // Move to the next column
      }
    }
  }, [originalDetailsList]);

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();

    doc.setFontSize(12); // Set font size

    if (ganttchart.current) {
      html2canvas(ganttchart.current, {
        useCORS: true, // Add this line if the image is from a different domain
        allowTaint: true,
        scale: 2 // Add this line if the image is from a different domain
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.footer}`
        // Calculate the width and height of the header image based on the PDF page width
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        const headerHeight = pdfHeight;
        const tableY = headerHeight + 15;
        // Insert the header image into the PDF
        const topBorderPadding = 10; // Adjust the padding value as needed
        doc.setDrawColor(255, 255, 255); // Black color for border
        doc.setLineWidth(topBorderPadding); // Border width (padding)

        // Draw the top border (padding)
        doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

        doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.header ? projectDetails?.header : "moit_header.jpg"}`, "PNG", 25, topBorderPadding, pdfWidth - 50, 25)
        doc.setFont('helvetica', 'bold'); // Set font to bold
        doc.setFontSize(12); // Set font size
        doc.text("WORKPLAN VS PHYSICAL PROGRESS", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("PHYSICAL VS FINANCIAL PROGRESS") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), topBorderPadding + 35);

        doc.setFontSize(8); // Set font size
        doc.text("Project Name:", 15, topBorderPadding + 50);
        const firstTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.setFont('helvetica', 'normal');
        doc.text(projectDetails?.project_name, 15 + firstTextWidth + 5, topBorderPadding + 50);
        doc.setFont('helvetica', 'bold');
        doc.text("Client:", 15, topBorderPadding + 55);
        const secondTextWidth = doc.getStringUnitWidth("Client:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.setFont('helvetica', 'normal');
        doc.text(projectDetails?.company, 15 + secondTextWidth + 5, topBorderPadding + 55);
        doc.setFont('helvetica', 'bold');
        doc.text("Project ID:", 15, topBorderPadding + 60);
        const thirdTextWidth = doc.getStringUnitWidth("Project ID:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.setFont('helvetica', 'normal');
        doc.text(`${projectDetails?.project_id}`, 15 + thirdTextWidth + 5, topBorderPadding + 60);
        doc.setFont('helvetica', 'normal');
        doc.addImage(imgData, "PNG", 15, topBorderPadding + 65, pdfWidth - 30, 0);
        // Insert the header image into the PDF
        const textX = 15;
        const textY = tableY + 5; // Adjust the value for vertical separation

        function addFooter(pageNumber) {
          // const footerText = "Hello CMS User";
          // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
          const footerX = 0; // Starting X position for the footer
          const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = 10;
          // const availableWidth = pdfWidth - 2 * footerX;

          // doc.setTextColor(0, 0, 0); // Set text color to black
          // doc.setFontSize(10); // Set font size for the footer

          // // Calculate the width of the text and page numbers
          // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

          // // Calculate the space needed for equal spacing
          // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

          // // Add footer text and page numbers in the same row, justified with space between them
          // doc.text(footerX, footerY, footerText);
          // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
          doc.addImage(footer, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
        }

        // Get the total number of pages
        const totalNumPages = doc.internal.getNumberOfPages();

        // Loop through each page to add the footer with page numbers
        for (let i = 1; i <= totalNumPages; i++) {
          doc.setPage(i);
          addFooter(i);
        }


        const scaleFactor = 1.0; // Adjust this value as needed
        const pdfBlob = doc.output('blob', { scale: scaleFactor });

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open a new window with the PDF URL

        if (isPrint) {
          const newWindow = window.open(pdfUrl, '_blank');

          // After the new window is opened, you can close the URL object
          URL.revokeObjectURL(pdfUrl);
          if (newWindow) {
            newWindow.onload = () => {
              setIsPrinting(false)
              setIsDownloading(false)
              // Wait for the window to load and then trigger the print dialog
              newWindow.print();
            };
          }
        }

        setIsPrinting(false)
        setIsDownloading(false)

        isPrint === false && doc.save("Workplan_vs_Physical_Progress_Report.pdf");

      });
    }

  };

  //   const formatTimelineHeader = (value) => {
  //     // Custom logic to format the timeline header
  //     const date = new Date(value);
  //     const day = date.getDate();
  //     const month = date.getMonth() + 1;
  //     const year = date.getFullYear();
  //     return `${day}`;
  //   };

 

  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Work Plan Vs Physical Progress</b></Grid>
            </Grid>
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <RadioGroup
                  row
                  name="reportType"
                  value={reportType}
                  onChange={(e) => {
                    setReportType(e.target.value); setOriginalDetailsList([]); setState({
                      project_location_id: '',
                      constructionname_id: ''
                    })
                  }}
                >

                  <FormControlLabel
                    label="Project Level"
                    value="projectLevel"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ mr: 3, height: 20 }}
                  />
                  <FormControlLabel
                    label="Construction Level"
                    value="constructionLevel"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ height: 20 }}
                  />
                </RadioGroup>

              </Grid>

              <Grid item sm={4} xs={12}>
                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </DecoratedTextField>
              </Grid>
              {reportType === "constructionLevel" && <Grid item sm={4} xs={12}>

                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  label="Construction Name"
                  id="constructionname_id"
                  variant="outlined"
                  sx={{ mb: 3 }}
                  name="constructionname_id"
                  value={state?.constructionname_id || ""}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Construction Name is required"]}
                >
                  {constructionNameList?.map((item) => (
                    <MenuItem value={item?.constructionname_id} key={item?.constructionname_id}>
                      {item?.construction_name}
                    </MenuItem>
                  ))}
                </DecoratedTextField>

              </Grid>}
              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {originalDetailsList && originalDetailsList?.length > 0 && <Grid container>
          <Grid xs={12} md={6}>
            {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box component="span" display="flex" justifyContent="flex-end">
              <Button
                size="small"
                sx={{ width: "130px", marginLeft: 4 }}
                variant="contained"
                color="warning"
                disabled={projectDetails?.header ? false : true}
                startIcon={<CloudDownload />}
                onClick={() => { handleReportDownload(false) }}
              >
                Download
              </Button>
              <Button
                size="small"
                sx={{ width: "100px", marginLeft: 2 }}
                variant="contained"
                color="primary"
                disabled={projectDetails?.header ? false : true}
                startIcon={<Print />}
                onClick={() => { handleReportDownload(true) }}
              >
                Print
              </Button>
            </Box>
          </Grid>

        </Grid>}
        {originalDetailsList?.length>0&&reportType==="constructionLevel"&&
        <Grid container >
        <Grid item sm={4} xs={12} sx={{backgroundColor:"white"}}>
        <TextField
          select
          size="small"
          fullWidth
          label="Work Plan No."
          id="workplan_no"
          variant="outlined"
          name="workplan_no"
          value={state?.workplan_no}
          onChange={handleChange}
        >
          <MenuItem value={"all"} key={"all"}>
             All
            </MenuItem>
          {workPlanList?.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      </Grid>}
        {originalDetailsList && originalDetailsList?.length > 0 && <Card sx={{ padding: 3 }}>

          <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
            <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

              </div>

              <Grid container spacing={0} >
                <Grid item xs={12} align='left' >
                  <FormLabel sx={{ fontSize: 22, display: "flex", justifyContent: "center", alignItems: "center" }} ><b>WORKPLAN VS PHYSICAL PROGRESS</b></FormLabel>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Company: </b>{projectDetails?.company}</FormLabel>
                </Grid>

              </Grid>
            </div>

            <div id="tableData">
              <div className="gantt-container">
                <div className="gantt-chart" ref={ganttchart} >
                  <GanttChart view="month"

                    tooltip={{ enabled: true }}
                    settings={settings}
                    dataSource={filteredDetailsList}
                    // showProgressLabel={false}
                    taskColumns={taskColumns}
                    treeSize="280px"
                    durationUnit={durationUnit}
                    id="gantt"
                    viewSettings={{
                      // Set custom timeline columns
                      timelineHeader: customColumns,
                      // formatTimelineHeader:formatTimelineHeader
                    }}
                  ></GanttChart>
                </div>
              </div>
            </div>
          </div>
        </Card>}

      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}