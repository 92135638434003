import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ConstructionName = Loadable(lazy(() => import('./ConstructionName')));

const constructionNameRoutes = [
  {
    path: '/constructionname',
    element: <ConstructionName/>,
  }
];

export default constructionNameRoutes;