import { ServiceUrl } from "app/utils/Constants";
import axios from "axios";




export const GET_GOODS_RECEIPT_LIST = 'GET_GOODS_RECEIPT_LIST';
export const GET_GOODS_ISSUE_LIST = 'GET_GOODS_ISSUE_LIST';
export const GET_GOODS_ADJUSTMENT_LIST = "GET_GOODS_ADJUSTMENT_LIST"
export const GET_PROJECT_LOCATION_LIST_INVENTORY = 'GET_PROJECT_LOCATION_LIST_INVENTORY';
export const GET_BML_ITEMS_GOOD_RECEIPT = 'GET_BML_ITEMS_GOOD_RECEIPT';
export const GET_ITEMS_GOOD_ISSUE = 'GET_ITEMS_GOOD_ISSUE';
export const GET_ITEM_CATALOGUE_LIST = 'GET_ITEM_CATALOGUE_LIST';
export const GET_ALL_UNITS_INVENTORY = "GET_ALL_UNITS_INVENTORY";
export const GET_ITEM_CATALOGUE_LIST_PAYMENT = 'GET_ITEM_CATALOGUE_LISTT_PAYMENT';

export const getAllProjectLocationListForInventory = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllProjectLocationForContractDetails, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_LOCATION_LIST_INVENTORY,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllGoodReceiptListByProjectLocationId = (project_location_id, showLoader, hideLoader, showToast, isAdd = false, handleGoodsReceiptSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/implementation/inventory/${project_location_id}${ServiceUrl.getAllGoodReceiptListByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_GOODS_RECEIPT_LIST,
        payload: res?.data,
      });
      handleGoodsReceiptSucces(isAdd)
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllGoodIssueListByProjectLocationId = (project_location_id, showLoader, hideLoader, showToast, isAdd = false, handleGoodsIssueSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/implementation/inventory/${project_location_id}${ServiceUrl.getAllGoodIssueListByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_GOODS_ISSUE_LIST,
        payload: res.data,
      });
      handleGoodsIssueSucces(isAdd)
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllGoodAdjustmentListByProjectLocationId = (project_location_id, showLoader, hideLoader, showToast, isAdd = false, handleGoodsAdjustmentSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/implementation/inventory/${project_location_id}${ServiceUrl.getAllGoodAdjustmentListByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_GOODS_ADJUSTMENT_LIST,
        payload: res.data,
      });
      handleGoodsAdjustmentSucces(isAdd)
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};



export const getAllItemCatalogueList = (showLoader, hideLoader, showToast, type = 'inventory') => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllItemCatalogueList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      if (type === 'payment') {
        dispatch({
          type: GET_ITEM_CATALOGUE_LIST_PAYMENT,
          payload: res?.data?.obj,
        });
      } else {
        dispatch({
          type: GET_ITEM_CATALOGUE_LIST,
          payload: res?.data?.obj,
        });
      }
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllProjectLocationBsrBmlitemsByProjectLicationIdForGoodReceipt = (project_location_id, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/project/${project_location_id}${ServiceUrl.getAllProjectLocationBsrBmlitemsByProjectLicationIdForGoodReceipt}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_BML_ITEMS_GOOD_RECEIPT,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAlStockForGoodReceipt = (project_location_id, item_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/inventory/${project_location_id}/{item_id}${ServiceUrl.getAlStockForGoodReceipt}?item_id=${item_id}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateGoodReceipt = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateGoodReceipt, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteGoodReceiptById = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/inventory/${params}${ServiceUrl.deleteGoodReceiptById}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteGoodReceiptDetails = (params) => {
  console.log("params:", params)
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.deleteGoodReceiptDetails, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const updateGoodReceiptDetails = (params) => {
  console.log("params:", params)
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.updateGoodReceiptDetails, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateItemCatalogue = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateItemCatalogue, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const saveOrUpdateGoodIssue = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateGoodIssue, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const deleteItemCatalogueListById = (item_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/catalogue/${item_id}${ServiceUrl.deleteItemCatalogueListById}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getItemCatalogueListById = (item_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/catalogue/${item_id}${ServiceUrl.getItemCatalogueListById}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateGoodAdjustment = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateGoodAdjustment, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


//getAlStockForGoodReceipt

export const populateItemDetailsForIssueOrAdjustment = (project_location_id, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(`/api/implementation/inventory/${project_location_id}${ServiceUrl.populateItemDetailsForIssueOrAdjustment}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_ITEMS_GOOD_ISSUE,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};