import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { MusterRollBillApproval } from './MusterRollBillApproval';
import { MusterRollBillReccommendation } from './MusterRollBillRecommendation';
import { MusterRollBillVerification } from './MusterRollBillVerification';
import { MusterRollBillSubmission } from './MusterRollSubmission';

const ResourceForecast = Loadable(lazy(() => import('./ResourceForecast')));
const ReviewRequisition = Loadable(lazy(() => import('./ReviewRequisition')));
const ApproveRequisition = Loadable(lazy(() => import('./ApproveRequisition')));


const resourceForecastRoutes = [
  {
    path: '/ResourceForecast',
    element: <ResourceForecast/>,
  },
  {
    path: '/ReviewRequisition',
    element: <ReviewRequisition/>,
  },
  {
    path: '/ApproveRequisition',
    element: <ApproveRequisition/>,
  },
  {
    path: '/MusterRollBillSubmission',
    element: <MusterRollBillSubmission/>,
  },
  {
    path: '/MusterRollBillVerification',
    element: <MusterRollBillVerification/>,
  },
  {
    path: '/MusterRollBillRecommendation',
    element: <MusterRollBillReccommendation/>,
  },
  {
    path: '/MusterRollBillApproval',
    element: <MusterRollBillApproval/>,
  }

];

export default resourceForecastRoutes;