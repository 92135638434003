import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { RABillVsTimeChart } from "app/components/charts/RaBillVsTimeChart";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getRaBillVsTimeReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const RABillVsTimeReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [projectDetails, setProjectDetails] = useState({})
  const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  const [maxValue, setMaxValue] = useState(0)
  const [tableData, setTableData] = useState()
  const chart = useRef()
  const [state, setState] = useState({
    project_location_id: '',
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (message) => {
    const key = enqueueSnackbar(message ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      setTableData([])
      setProjectDetails({})
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value });

    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }


  const handleFormSubmit = () => {
    setLoading(true)
    // setTableData([])
    getRaBillVsTimeReport({ project_location_id: state?.project_location_id })
      .then((res) => {
        setLoading(false)

        if (res?.status < 300) {
          if(res?.data?.success){
          if (res?.data?.obj && res?.data?.obj?.item && res?.data?.obj?.item?.ra_bill_vs_time_report?.length > 0) {
            res?.data?.obj?.item?.ra_bill_vs_time_report?.forEach(elem => {
              elem.ra_bill_date = moment(elem?.ra_bill_date).format("DD/MM/YYYY")
            })
            setTableData(res?.data?.obj?.item?.ra_bill_vs_time_report)
            // const total = res?.data?.obj?.item?.ra_bill_vs_time_report.reduce((accumulator, item) => accumulator+item?.tds_amount, 0);
            // setTotalAmount(parseFloat(total).toFixed(2))
            const maxAmount = Math.max(...res?.data?.obj?.item?.ra_bill_vs_time_report.map(entry => entry.amount_paid_in_percent));
            setMaxValue(maxAmount)
          }
          if (res?.data?.obj?.item) {
            setProjectDetails({
              ...projectDetails,
              company: res?.data?.obj?.item?.company,
              project_name: res?.data?.obj?.item?.project_name,
              project_id: res?.data?.obj?.item?.project_id,
              contract_amount: parseFloat(res?.data?.obj?.item?.amount ? res?.data?.obj?.item?.amount : 0).toFixed(2),
              header: res?.data?.obj?.header_logo,
              footer: res?.data?.obj?.footer_logo

            })
          }
        }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
    SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
         const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

      })


  }

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const pdfWidth = 297; // Landscape dimensions
    const pdfHeight = 210;
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    if (chart.current) {
      html2canvas(chart.current, {
        useCORS: true, // Add this line if the image is from a different domain
        allowTaint: true,
        scale: 2 // Add this line if the image is from a different domain
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.footer}`
        // Calculate the width and height of the header image based on the PDF page width
        const pdfWidth = doc.internal.pageSize.getWidth() - 50;
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        const headerHeight = pdfHeight;
        const tableY = headerHeight + 15;
        // Insert the header image into the PDF
        const topBorderPadding = 10; // Adjust the padding value as needed
        doc.setDrawColor(255, 255, 255); // Black color for border
        doc.setLineWidth(topBorderPadding); // Border width (padding)

        // Draw the top border (padding)
        doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);
        // Insert the header image into the PDF
        const textX = 15;
        const textY = tableY - 10;

        doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.header ? projectDetails?.header : "moit_header.jpg"}`, "PNG", 25, topBorderPadding, pdfWidth, pdfHeight - 15)
        doc.setFont('helvetica', 'bold'); // Set font to bold
        doc.setFontSize(12); // Set font size
        doc.setFont('helvetica', 'bold'); // Set font to bold
        doc.setFontSize(12); // Set font size
        doc.text("RA BILL VS TIME", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("RA BILL VS TIME") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
        doc.setFontSize(8); // Set font size
        doc.setFont('helvetica', 'bold'); // Set font to bold
        doc.setFontSize(8); // Set font size
        doc.text("Project Name:", textX, textY + 10);
        const firstTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.setFont('helvetica', 'normal');
        doc.text(projectDetails?.project_name, textX + firstTextWidth + 5, textY + 10);
        doc.setFont('helvetica', 'bold');
        doc.text("Client:", textX, textY + 15);
        const secondTextWidth = doc.getStringUnitWidth("Client:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.setFont('helvetica', 'normal');
        doc.text(projectDetails?.company, textX + secondTextWidth + 5, textY + 15);
        doc.setFont('helvetica', 'bold');
        doc.text("Project ID:", textX, textY + 20);
        const thirdTextWidth = doc.getStringUnitWidth("Project ID:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.setFont('helvetica', 'normal');
        doc.text(`${projectDetails?.project_id}`, textX + thirdTextWidth + 5, textY + 20);
        // doc.text(`Name Of The Work: ${projectDetails?.project_name}`, textX, textY);
        //doc.text(`Client: ${projectDetails?.company}`, textX, textY + 5);
        // doc.text(`eGP Tender Id: ${projectDetails?.contractNo}`, textX, textY + 10);
        //   doc.setFont('helvetica', 'bold');
        //   doc.text("Contract Amount:", textX, textY + 25);
        //   const fourthTextWidth = doc.getStringUnitWidth("Contract Amount:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        //   doc.setFont('helvetica', 'normal');
        //   doc.text(`${projectDetails?.contract_amount}`, textX + fourthTextWidth + 5, textY + 25);
        //doc.setFontSize(5); 
        const headerSectionHeight = headerHeight + pdfHeight; // Adjust the value as needed

        // Calculate the starting Y-coordinate for the table
        const tableStartY = headerSectionHeight - 10;

        doc.setFont('helvetica', 'normal'); // Reset font to normal
        const body = []
        tableData?.length > 0 && tableData?.forEach((item) => {
          body.push([

            { content: item?.ra_bill_date ? moment(item?.ra_bill_date).format("DD/MM/YYYY") : "" },
            { content: item?.rabill_no, },
            { content: parseFloat(item?.ra_amount ? item?.ra_amount : 0).toFixed(2), styles: { halign: "right" } },
            { content: parseFloat(item?.amendment_amount ? item?.amendment_amount : 0).toFixed(2), styles: { halign: "right" } },
            { content: parseFloat(item?.recovery_amount ? item?.recovery_amount : 0).toFixed(2), styles: { halign: "right" } },
            { content: parseFloat(item?.price_adjustment ? item?.price_adjustment : 0).toFixed(2), styles: { halign: "right" } },
            { content: parseFloat(item?.cummulative_adjustment ? item?.cummulative_adjustment : 0).toFixed(2), styles: { halign: "right" } },
            { content: parseFloat(item?.amount_paid_in_percent ? item?.amount_paid_in_percent : 0).toFixed(2), styles: { halign: "right" } },

          ])
        })


        doc.autoTable({
          startY: tableStartY,
          head: [[
            { content: "RA Bill Date", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
            { content: "RA Bill No", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
            { content: "RA Amount", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
            { content: "Amendment Amount", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
            { content: "Recovery Amount", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
            { content: "Price Adjustment", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
            { content: "Cumulative Adjustment", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
            { content: "Amount Paid in %", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },

          ]],
          body: body, // Use the transformed data here
          theme: "grid",
          styles: {
            fillColor: [255, 255, 255], // Remove background color from the table
            lineColor: [0, 0, 0], // Set border color for main table
            lineWidth: 0.1, // Set border width for main table
            textColor: [0, 0, 0], // Set text color to black
            cellPadding: 2, // Add padding to cells
          },

        });
        doc.addImage(imgData, "PNG", 15, doc.autoTable.previous.finalY + 10, pdfWidth + 20, 60);
        //   doc.setFontSize(8);
        //   doc.setFont('helvetica', 'bold');// Set font size
        //   doc.text(`Balance Contract Amount :`, textX, doc.autoTable.previous.finalY + 10);
        //   const balanceText = doc.getStringUnitWidth("Balance Contract Amount :") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        //   doc.setFont('helvetica', 'normal');
        //   doc.text(parseFloat(parseFloat(projectDetails?.contract_amount)-parseFloat(sums?.sum_payment_to_contractor)).toFixed(2), textX + balanceText + 5, doc.autoTable.previous.finalY + 10);
        //   doc.setFont('helvetica', 'bold');// Set font size
        //   doc.text(`Balance Advance Amount :`, textX, doc.autoTable.previous.finalY + 15);
        //   const balanceAdvanceText = doc.getStringUnitWidth("Balance Advance Amount :") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        //   doc.setFont('helvetica', 'normal');
        //   doc.text(parseFloat(sums?.sum_total_advances-sums?.sum_advance_recovered).toFixed(2), textX + balanceAdvanceText + 5, doc.autoTable.previous.finalY + 15);

        // Function to add footer on each page
        function addFooter(pageNumber) {
          // const footerText = "Hello CMS User";
          // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
          const footerX = 0; // Starting X position for the footer
          const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = 10;
          // const availableWidth = pdfWidth - 2 * footerX;

          // doc.setTextColor(0, 0, 0); // Set text color to black
          // doc.setFontSize(10); // Set font size for the footer

          // // Calculate the width of the text and page numbers
          // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

          // // Calculate the space needed for equal spacing
          // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

          // // Add footer text and page numbers in the same row, justified with space between them
          // doc.text(footerX, footerY, footerText);
          // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
          doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.footer ? projectDetails?.footer : 'moit_footer.jpg'}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
        }

        // Get the total number of pages
        const totalNumPages = doc.internal.getNumberOfPages();

        // Loop through each page to add the footer with page numbers
        for (let i = 1; i <= totalNumPages; i++) {
          doc.setPage(i);
          addFooter(i);
        }
        const scaleFactor = 1.0; // Adjust this value as needed
        const pdfBlob = doc.output('blob', { scale: scaleFactor });

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open a new window with the PDF URL

        if (isPrint) {
          const newWindow = window.open(pdfUrl, '_blank');

          // After the new window is opened, you can close the URL object
          URL.revokeObjectURL(pdfUrl);
          if (newWindow) {
            newWindow.onload = () => {
              setIsPrinting(false)
              setIsDownloading(false)
              // Wait for the window to load and then trigger the print dialog
              newWindow.print();
            };
          }
        }

        setIsPrinting(false)
        setIsDownloading(false)

        isPrint === false && doc.save('RA_Bill_VS_Time_Report.pdf');

      });
    }
  };










  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>RA Bill Vs Time</b></Grid>
            </Grid>
            <Grid container spacing={3} >
              {/* <Grid item xs={12}>
                <RadioGroup
                  row
                  name="reportType"
                  value={reportType}
                  onChange={(e) => { setReportType(e.target.value); setTableData([]) }}
                >&nbsp;&nbsp;
                  <FormControlLabel
                    label="Project BoQ Report"
                    value="report"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ mr: 3, height: 20 }}
                  />
                  <FormControlLabel
                    label="Project BoQ Report Summary"
                    value="summaryReport"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ height: 20 }}
                  />
                </RadioGroup>

              </Grid> */}
              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {tableData && tableData?.length > 0 && <Grid container>
          <Grid xs={12} md={6}>
            {checkValidation(projectDetails?.header)===false&&<FormLabel sx={{color:"#ff9800"}} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
          <Grid item xs={12} md={6}>
            <Box component="span" display="flex" justifyContent="flex-end">
              <Button
                size="small"
                sx={{ width: "130px", marginLeft: 4 }}
                variant="contained"
                color="warning"
                disabled={projectDetails?.header?false:true}
                startIcon={<CloudDownload />}
                onClick={() => { handleReportDownload(false) }}
              >
                Download
              </Button>
              <Button
                size="small"
                sx={{ width: "100px", marginLeft: 2 }}
                variant="contained"
                color="primary"
                disabled={projectDetails?.header?false:true}
                startIcon={<Print />}
                onClick={() => { handleReportDownload(true) }}
              >
                Print
              </Button>
            </Box>
          </Grid>

        </Grid>}
        {tableData && tableData?.length > 0 && <Card sx={{ padding: 3 }}>

          <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
            <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header }`} style={{ height: "150px", width: "auto" }} alt="img" />

              </div>

              <Grid container >
                <Grid item xs={12} sx={{ marginTop: 2, textAlign: "center" }}>
                  <FormLabel sx={{ fontSize: 20 }}><b>RA BILL VS TIME</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.company}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Id: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>
                {/* <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Contract Amount : </b>{projectDetails?.contract_amount}</FormLabel>
                </Grid> */}


              </Grid>
            </div>

            <div id="tableData">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell >RA Bill Date</TableCell>
                      <TableCell>RA Bill No</TableCell>
                      <TableCell>RA Amount</TableCell>
                      <TableCell>Amendment Amount</TableCell>
                      <TableCell>Recovery Amount</TableCell>
                      <TableCell>Price Adjustment</TableCell>
                      <TableCell>Cumulative Adjustment</TableCell>
                      <TableCell>Amount Paid in %</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      tableData?.map((item, index) => (
                        <React.Fragment >

                          <TableRow key={index + 1} >
                            <TableCell>{item?.ra_bill_date ? item?.ra_bill_date : ''}</TableCell>
                            <TableCell >{item?.rabill_no}</TableCell>
                            <TableCell align="right">{parseFloat(item?.ra_amount ? item?.ra_amount : 0).toFixed(2)}</TableCell>
                            <TableCell align="right">{parseFloat(item?.amendment_amount ? item?.amendment_amount : 0).toFixed(2)}</TableCell>
                            <TableCell align="right">{parseFloat(item?.recovery_amount ? item?.recovery_amount : 0).toFixed(2)}</TableCell>
                            <TableCell align="right">{parseFloat(item?.price_adjustment ? item?.price_adjustment : 0).toFixed(2)}</TableCell>
                            <TableCell align="right">{parseFloat(item?.cummulative_adjustment ? item?.cummulative_adjustment : 0).toFixed(2)}</TableCell>
                            <TableCell align="right">{parseFloat(item?.amount_paid_in_percent ? item?.amount_paid_in_percent : 0).toFixed(2)}%</TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}


                  </TableBody>
                </Table>

              </TableContainer>

            </div>
          </div>
          <Grid container ref={chart} sx={{ mt: 4 }}>
            <RABillVsTimeChart data={tableData} max={maxValue} />
          </Grid>
        </Card>}

      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}