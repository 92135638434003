import { Button, Grid, Icon, MenuItem, styled, Card, FormLabel, Box } from "@mui/material";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from '@material-ui/core';
import { Loader } from "app/components";
import { getAllBaseLocationList } from "app/redux/actions/BSRActions";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { SimpleCard } from "components";
import { getQuickCostRateReport } from "app/redux/actions/QuickCostActions";
import { AppConstants } from "app/utils/AppConstants";
import { CloudDownload, Print } from "@mui/icons-material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ServiceUrl } from "app/utils/Constants";

const DecoratedTextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
  }));
  const DecimalInputField = styled(TextValidator)(({ theme }) => ({
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    width: "100%",
    marginBottom: "16px",
  }));


export const BSRQuickCostRateReport=()=>{
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false)
    const [isPrinting, setIsPrinting] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [totalAmount, setTotalAmount] = useState()
    const [projectDetails, setProjectDetails] = useState()
    const { classificationList, baseLocationList } = useSelector((state) => state.bsr);
    const [tableData, setTableData] = useState()
    const [chapterList, setChapterList] = useState([])
    const [chapterName, setChapterName] = useState("All")
    const [filteredTableData, setFilteredTableData] = useState([])
      ;
    const [state, setState] = useState({
      bsr_year: '',
      baselocation_ids: []
    })
    useEffect(()=>{
        setLoading(true)
        dispatch(getAllBaseLocationList(false))
        setLoading(false)
    },[])
    const handleGetUniqueBaseLocationCode=()=>{
      
        const uniqueBaseLocationCodes = Array.from(new Set(tableData.flatMap(entry => entry.list.map(item => item.baselocation_code))));
         console.log("uniqueBaseLocationCodes")
         return uniqueBaseLocationCodes
    }
   
    function createYearArray() {
        var fiscalyear = "";
        var today = new Date();
        if ((today.getMonth() + 1) <= 5) {
          fiscalyear = (today.getFullYear())
        } else {
          fiscalyear = today.getFullYear() + 2
        }
        const years = Array.from(new Array(5), (val, index) => fiscalyear - index);
        return years
      }
      const handleChange=(event)=>{
        setTableData()
         if(event.target.name==="baselocation_ids"){
            //   setState(state => ({
            //   ...state,
            //   [event.target.name]:
            //     event.target.type === "checkbox"
            //       ? event.target.checked
            //       : event.target.value
            // }))
            const selectedLocations = event.target.type === "checkbox"
            ? event.target.checked
              ? [...state.baselocation_ids, event.target.value]
              : state.baselocation_ids.filter(id => id !== event.target.value)
            : event.target.value;
      
          // Limit the selection to a maximum of 4 items
          if (selectedLocations.length > 4) {
            const key = enqueueSnackbar("Can not be selected more than four base location", { variant: 'info' });
              SnackBarProperty.info(key, closeSnackbar);
            return;
          }
      
          setState(state => ({
            ...state,
            [event.target.name]: selectedLocations,
          }));
          }
          else if(event?.target?.name==="bsr_year"){
            setState({bsr_year:event?.target?.value})
          }
      }
      const handleFormSubmit=()=>{
        console.log("state?.baselocation_ids:",state?.baselocation_ids)
        if(state?.baselocation_ids?.length===0||state?.baselocation_ids===undefined||state?.baselocation_ids===null){
            const key = enqueueSnackbar("Please select at least one Base Location", { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);
          }
          else{
            const filteredBaselocations=state?.baselocation_ids?.map((elem=>(parseInt(elem?.split("_")?.[0]))))
            const params={
                bsr_year:state?.bsr_year,
                baselocation_list:filteredBaselocations
            }
            setLoading(true)
            getQuickCostRateReport(params)?.then(res=>{
                setLoading(false)
                if(res?.status<300 && res?.data?.success){
                    console.log("response:",res)
                    if(res?.data?.obj&&Object.keys(res?.data?.obj)?.length===0){
                        const key = enqueueSnackbar("No report found", { variant: 'info' });
                        SnackBarProperty.info(key, closeSnackbar);
                    }
                    else{
                        //setTableData(res?.data?.obj)
                        const transformedData = [];
                            Object.entries(res?.data?.obj).forEach(([heading, items]) => {
                            const itemList = Object.values(items)[0];
                            transformedData.push({
                                heading,
                                list: itemList
                            });
                            });
                            console.log("transformed Data:",transformedData)
                            setTableData(transformedData)
                    }
                }
            }).catch(err=>{
                setLoading(false)
                const key = enqueueSnackbar(AppConstants?.somethingWentWrong, { variant: 'error' });
                SnackBarProperty.error(key, closeSnackbar);
            })
          }
      }
      const handleDownload = (isPrint) => {
        if (isPrint) {
          setIsPrinting(true)
          setIsDownloading(false)
        }
        else {
          setIsPrinting(false)
          setIsDownloading(true)
        }
        const doc = new jsPDF();
        const contentHeight = 150;
         const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image
    
        doc.setFontSize(12); // Set font size
    
    
        html2canvas(headerImage, {
          useCORS: true, // Add this line if the image is from a different domain
          allowTaint: true, // Add this line if the image is from a different domain
        }).then((canvas) => {
          //const imgData = canvas.toDataURL("image/png");
         // const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.header ? projectDetails?.header : 'moit_footer.jpg'}`
          // Calculate the width and height of the header image based on the PDF page width
          const pdfWidth = doc.internal.pageSize.getWidth() - 50;
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
          const headerHeight = pdfHeight;
          const tableY = headerHeight + 15;
          // Insert the header image into the PDF
          const topBorderPadding = 10; // Adjust the padding value as needed
          doc.setDrawColor(255, 255, 255); // Black color for border
          doc.setLineWidth(topBorderPadding); // Border width (padding)
          // Draw the top border (padding)
          doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);
        //   doc.addImage(imgData, "PNG", 25, topBorderPadding, pdfWidth, pdfHeight);
          // Insert the header image into the PDF
          const textX = 15;
          const textY = tableY + 5; // Adjust the value for vertical separation
    
          // Add the text content below the header image
          doc.setFont('helvetica', 'bold'); // Set font to bold
          doc.setFontSize(12); // Set font size
          doc.text("QUICK COST RATES (Nu)", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("QUICK COST RATES (Nu)") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
          doc.text(`${state?.bsr_year}`, (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth(`${state?.bsr_year}`) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY+10);
    
          
          const headerSectionHeight = headerHeight + pdfHeight; // Adjust the value as needed
    
          // Calculate the starting Y-coordinate for the table
          const tableStartY = headerSectionHeight + 10;
          doc.setFont('helvetica', 'normal');
    
          const body = []
          const header=[[
            { content: "SL No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
            { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } }
            
          ]]
          handleGetUniqueBaseLocationCode()?.forEach((elem)=>{
            header[0]?.push({ content: elem, colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } })
          }
            )
             
          tableData?.forEach((elem) => {
            body.push([
              { content: elem?.heading, colSpan: handleGetUniqueBaseLocationCode()?.length+2, styles: { fontStyle: "bold",valign: "middle", halign: "center",fontSize:8 } }
            ])
            elem?.list?.forEach((item, index) => {
              body.push([
                {content:index + 1,styles:{fontSize:8}},
                {content:item?.description,styles:{fontSize:8}},
                {content:handleGetUniqueBaseLocationCode()?.map((elem)=>(
                    parseFloat(elem===item?.baselocation_code?item?.avg_pa_rate:0).toFixed(2)
                )),styles:{fontSize:8}}
              ])
            })
          })
        //   body.push([
        //     { content: "Grand Total", colSpan: 10, styles: { fontStyle: "bold", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(totalAmount), colSpan: 1, styles: { fontStyle: "bold", halign: "right" } }
        //   ])
    
        doc.autoTable({
            startY: tableStartY + 30,
            head: header,
            body: body, // Use the transformed data here
            theme: "grid",
            styles: {
              fillColor: [255, 255, 255], // Remove background color from the table
              lineColor: [0, 0, 0], // Set border color for main table
              lineWidth: 0.1, // Set border width for main table
              textColor: [0, 0, 0], // Set text color to black
              cellPadding: 2, // Add padding to cells
              fontSize: 7
            },
            columnStyles: {
              // fontSize:5
    
              3: { halign: "right" },
              4: { halign: "right" },
              5: { halign: "right" },
              6: { halign: "right" },
              // 11:{columnWidth:15}
            },
          });
    
    
          // Function to add footer on each page
        //   function addFooter(pageNumber) {
        //     // const footerText = "Hello CMS User";
        //     // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        //     const footerX = 0; // Starting X position for the footer
        //     const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        //     const pdfWidth = doc.internal.pageSize.getWidth();
        //     const pdfHeight = 10;
        //     // const availableWidth = pdfWidth - 2 * footerX;
    
        //     // doc.setTextColor(0, 0, 0); // Set text color to black
        //     // doc.setFontSize(10); // Set font size for the footer
    
        //     // // Calculate the width of the text and page numbers
        //     // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        //     // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    
        //     // // Calculate the space needed for equal spacing
        //     // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;
    
        //     // // Add footer text and page numbers in the same row, justified with space between them
        //     // doc.text(footerX, footerY, footerText);
        //     // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        //     doc.addImage(footer, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
        //   }
    
        //   // Get the total number of pages
        //   const totalNumPages = doc.internal.getNumberOfPages();
    
        //   // Loop through each page to add the footer with page numbers
        //   for (let i = 1; i <= totalNumPages; i++) {
        //     doc.setPage(i);
        //     addFooter(i);
        //   }
    
    
          const scaleFactor = 1.0; // Adjust this value as needed
          const pdfBlob = doc.output('blob', { scale: scaleFactor });
    
          // Create a URL for the Blob
          const pdfUrl = URL.createObjectURL(pdfBlob);
    
          // Open a new window with the PDF URL
    
          if (isPrint) {
            const newWindow = window.open(pdfUrl, '_blank');
    
            // After the new window is opened, you can close the URL object
            URL.revokeObjectURL(pdfUrl);
            if (newWindow) {
              newWindow.onload = () => {
                setIsPrinting(false)
                setIsDownloading(false)
                // Wait for the window to load and then trigger the print dialog
                newWindow.print();
              };
            }
          }
    
          setIsPrinting(false)
          setIsDownloading(false)
    
          isPrint === false && doc.save(`Quick_Cost_Quantity_${state?.bsr_year}.pdf`);
    
        });
    
      };

 
    return(
        <>
        <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>
  
          <Grid container>
  
            <Grid item xs={12}>
              <Button
                size="small"
                sx={{ width: "100px" }}
                variant="contained"
                color="error"
                onClick={() => { window.history.back() }}
              >
                <Icon color="primary" fontSize="small">
                  skip_previous
                </Icon>
                Back
              </Button>
            </Grid>
          </Grid>
          <SimpleCard>
            <ValidatorForm onSubmit={handleFormSubmit}>
              <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
                <Grid item xs={12}><b className="font20 extraBold">BSR Quick Cost Rate</b></Grid>
              </Grid>
  
              <Grid container spacing={3} >
               
  
                <Grid item sm={4} xs={12}>
                  
                  <DecoratedTextField
            select
            size="small"
            fullWidth
            label="BSR Year"
            id="bsr_year"
            variant="outlined"
            name="bsr_year"
            value={state?.bsr_year || ""}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={["Year is required"]}
          >
            {createYearArray()?.map((item) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </DecoratedTextField>
                </Grid>
                <Grid item sm={4} xs={12}>
                <DecoratedTextField
                        select
                        fullWidth   
                        size="small"
                        name="baselocation_ids"
                        id="base_location"
                        variant="outlined"
                        label="Base Location"
                        SelectProps={{
                        multiple: true,
                        value: state?.baselocation_ids??[],
                        onChange: handleChange
                        }}
                    >
                     {baseLocationList?.map((elem)=>(
                      <MenuItem value={`${elem?.baselocation_id}_${elem?.description}`} style={state?.baselocation_ids?.includes(`${elem?.baselocation_id}_${elem?.description}`) ? { backgroundColor: '#FFA33C' } : {}}>{elem?.baselocation_name}</MenuItem>
                     )) }
                 </DecoratedTextField>
                </Grid>
  
                <Grid item sm={4} xs={12}>
  
  
                  <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>
  
                </Grid>
  
              </Grid>
  
            </ValidatorForm>
          </SimpleCard>
          {tableData && tableData?.length > 0&& 
          <Grid container>
          
          <Grid items xs={12} >
            <Box component="span" display="flex" justifyContent="flex-end">
              <Button
                size="small"
                sx={{ width: "130px", marginLeft: 4 }}
                variant="contained"
                color="warning"
                startIcon={<CloudDownload />}
                //disabled={projectDetails?.header ? false : true}
                onClick={() => { handleDownload(false) }}
              >
                Download
              </Button>
              <Button
                size="small"
                sx={{ width: "100px", marginLeft: 2 }}
                variant="contained"
                color="primary"
                startIcon={<Print />}
                //disabled={projectDetails?.header ? false : true}
                onClick={() => { handleDownload(true) }}
              >
                Print
              </Button>
            </Box>
          </Grid>
          </Grid>}
          {
          tableData && Object?.keys(tableData)?.length > 0 &&
          <Card sx={{ padding: 3 }}>

            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              {/* <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

                </div>
              </div> */}
               <div id="headerLogo"></div>
              <Grid container >
                <Grid item xs={12} sx={{ marginTop: 2, textAlign: "center" }}>
                  <FormLabel sx={{ fontSize: 20 }}><b>QUICK COST RATES (Nu)</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, textAlign: "center" }}>
                  <FormLabel sx={{ fontSize: 20 }}><b>{state?.bsr_year} </b></FormLabel>
                </Grid>
               
              </Grid>
            </div>

            <div id="tableData" style={{marginTop:10}}>
            <TableContainer component={Paper}>
            <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell  style={{ width: '8%', textAlign: 'center' }}>SL No.</TableCell>
                      <TableCell  style={{ whiteSpace: 'wrap', textAlign: "center" }} align="center">Description</TableCell>
                      {handleGetUniqueBaseLocationCode()?.map((elem)=>(
                        <TableCell  style={{ textAlign: 'center' }}>{elem}</TableCell>
                      ))}

                    </TableRow>

                  </TableHead>
                  <TableBody>
                   {
                    tableData&&tableData?.length>0&&tableData?.map((elem)=>(
                        <>
                        <TableRow>
                        <TableCell colSpan={handleGetUniqueBaseLocationCode()?.length+2} align="center" ><b>{elem?.heading}</b></TableCell>
                      </TableRow>
                       
                        {elem?.list?.map((item,index)=>(
                             <TableRow>
                            <TableCell >{index+1}</TableCell>
                            <TableCell >{item?.description}</TableCell>
                            {
                                handleGetUniqueBaseLocationCode()?.map((elem)=>(
                                    <TableCell >{parseFloat(elem===item?.baselocation_code?item?.avg_pa_rate:0).toFixed(2)}</TableCell>
                                ))
                            }
                            {/* <TableCell >{item?.baselocation_code}</TableCell> */}
                            </TableRow>
                        ))
                        }
                        
                      
                      </>
                    ))}
                </TableBody>
                  </Table>
            </TableContainer>
              {/* <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell rowSpan={2} style={{ width: '8%', textAlign: 'center' }}>SL No.</TableCell>
                      <TableCell rowSpan={2} style={{ width: '15%', whiteSpace: 'wrap', textAlign: "center" }} align="center">Description</TableCell>
                      <TableCell colSpan={5} align="center" style={{ width: '300px', whiteSpace: 'nowrap' }}>Quantity</TableCell>
                      <TableCell rowSpan={2} align="center">Unit</TableCell>
                      <TableCell colSpan={2} align="center">Rate(Nu)</TableCell>
                      <TableCell colSpan={2} align="center">Amount(Nu)</TableCell>

                    </TableRow>

                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={styles?.subHeader} colSpan={2}></TableCell>
                      <TableCell style={styles?.subHeader}>Tender</TableCell>
                      <TableCell style={styles?.subHeader}>Till Last RA</TableCell>
                      <TableCell style={styles?.subHeader}>Current</TableCell>
                      <TableCell style={styles?.subHeader}>Deviated</TableCell>
                      <TableCell style={styles?.subHeader}>Cumulative</TableCell>
                      <TableCell style={styles?.subHeader} colSpan={1}></TableCell>
                      <TableCell style={styles?.subHeader}>Tender</TableCell>
                      <TableCell style={styles?.subHeader}>Revised</TableCell>
                      <TableCell style={styles?.subHeader}>Tender</TableCell>
                      <TableCell style={styles?.subHeader}>Revised</TableCell>



                    </TableRow>
                    {tableData && tableData?.map((data) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell colSpan={12}><b>{data?.construction_name}</b></TableCell>
                          </TableRow>
                          {
                            data?.item_list?.map((elem) => {
                              return (
                                <TableRow>
                                  <TableCell>{elem?.item_code}</TableCell>
                                  <TableCell>{elem?.description}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.contract_quantity ? elem?.contract_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.prev_quantity ? elem?.prev_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.new_quantity ? elem?.new_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.deviation_quantity ? elem?.deviation_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(parseFloat(elem?.prev_quantity ? elem?.prev_quantity : 0) + parseFloat(elem?.new_quantity ? elem?.new_quantity : 0) + parseFloat(elem?.deviation_quantity ? elem?.deviation_quantity : 0)).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }} >{elem?.unit_name}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{elem?.contract_rate ? formatNumberWithCommasAndDecimals(parseFloat(elem?.contract_rate).toFixed(2)) : 0}</TableCell>
                                  <TableCell>{formatNumberWithCommasAndDecimals(parseFloat(elem?.revised_rate ? elem?.revised_rate : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.contract_amount ? elem?.contract_amount : 0).toFixed(2))}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{formatNumberWithCommasAndDecimals(parseFloat(elem?.revised_amount ? elem?.revised_amount : 0).toFixed(2))}</TableCell>
                                </TableRow>
                              )
                            })
                          }
                          <TableRow>
                            <TableCell colSpan={10} align="right"><b>Sub-Total</b></TableCell>
                            <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(data?.sub_total_tender)}</b></TableCell>
                            <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(data?.sub_total_revised)}</b></TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                    <TableRow>
                      <TableCell colSpan={10} align="right"><b>Grand Total</b></TableCell>
                      <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(parseFloat(grandTotal?.grand_tender_amount).toFixed(2))}</b></TableCell>
                      <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(parseFloat(grandTotal?.grand_revised_amount).toFixed(2))}</b></TableCell>
                    </TableRow>

                  </TableBody>


                </Table>

              </TableContainer> */}
            </div>
          </Card>}
        </div>
        {loading && (
          <Loader
            show={loading}
          />
        )}
        {(isPrinting || isDownloading) && (
          <Loader
            show={isPrinting ? isPrinting : isDownloading}
            text="Processing...Please wait."
          />
        )}
  
      </>
    )
}

