import { Button, Card, Dialog, FormLabel, Grid, Icon, IconButton, MenuItem, ThemeProvider, Tooltip, createTheme, styled } from "@mui/material";
import { Box } from "@mui/system";
import { Breadcrumb, Loader } from "app/components";
import { FlexBox } from "app/components/FlexBox";
import { getAllProjectLocationByAgency, getMusterRollBillByProjectLocation, saveOrUpdateMusterRollBill } from "app/redux/actions/ResourceForeCastActions";
import { AppConstants } from "app/utils/AppConstants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import moment from "moment";
import { numToWords } from "app/utils/numToWords";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const MyCard = styled(Card)(() => ({
  minWidth: 750,
  whiteSpace: "pre",
  "& thead": { "& th:first-of-type": { padding: 10 } },
  "& td": { borderBottom: "true" },
  "& td": { padding: "3px !important", paddingLeft: "16px !important" },
}));


const theme = createTheme({

  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#87CEEB",
        }
      }
    }
  }
});

const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));





export const MusterRollBillApproval = () => {

  const [isLoad, setIsLoad] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [billList, setBillList] = useState()
  const [verificationModalOpen, setVerificationModalOpen] = useState(false)
  const [billDetails, setBillDetails] = useState()
  const { projectLocationListForAgency } = useSelector((state) => state?.resourceForecast)
  const [state, setState] = useState({
    project_name: "",
    project_location_id: "",
  })
  const months =
    [
      {
        value: 1,
        month: "January",
        max: 31
      },
      {
        value: 2,
        month: "February",
        max: 30
      },
      {
        value: 3,
        month: "March",
        max: 31
      },
      {
        value: 4,
        month: "April",
        max: 30
      },
      {
        value: 5,
        month: "May",
        max: 31
      },
      {
        value: 6,
        month: "June",
        max: 30
      },
      {
        value: 7,
        month: "July",
        max: 31
      },
      {
        value: 8,
        month: "August",
        max: 31
      },
      {
        value: 9,
        month: "September",
        max: 30
      },
      {
        value: 10,
        month: "October",
        max: 31
      },
      {
        value: 11,
        month: "November",
        max: 30
      },
      {
        value: 12,
        month: "December",
        max: 31
      }]

  const columns = [
    {
      name: "index",
      label: "Sl No.",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => rowIndex + 1
      },
    },
    {
      name: "project_id", // field name in the row object
      label: "Project ID", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => state?.project_id
      }
    },
    {
      name: "project_name", // field name in the row object
      label: "Project Name", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => state?.project_name
      }
    }, {
      name: "musterroll_no", // field name in the row object
      label: "Muster Roll No",
      options: {
        filter: true,
        sort: false
      }
    }, {
      name: "amount", // field name in the row object
      label: "Muster Roll Date", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => moment(billList[rowIndex]?.musterroll_date).format("DD-MM-YYYY")
      }
    },
    {
      name: "action", // field name in the row object
      label: "Action", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => <FlexBox alignItems="center">
          <Tooltip title="Verify">
            <IconButton onClick={() => handleClickVerify(billList[rowIndex])}>
              <Icon color="warning" fontSize="small">
                visibility
              </Icon>
            </IconButton>
          </Tooltip>
        </FlexBox>
      }
    }
  ];
  const workerColumns = [
    {
      name: "index",
      label: "Sl No.",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => rowIndex + 1
      },
    },
    {
      name: "category", // field name in the row object
      label: "Category", // column title that will be shown in table
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "unit_name", // field name in the row object
      label: "Unit", // column title that will be shown in table
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "worker_name", // field name in the row object
      label: "Worker Name", // column title that will be shown in table
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "noofdays", // field name in the row object
      label: "Number Of Days", // column title that will be shown in table
      options: {
        filter: true,
        sort: false
      }
    }, {
      name: "rate", // field name in the row object
      label: "Rate (Nu)",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => parseFloat(billDetails?.musterRollBillDetailsList[rowIndex]?.rate).toFixed(2)
      }
    }, {
      name: "amount", // field name in the row object
      label: "Amount (Nu)", // column title that will be shown in table
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => parseFloat(billDetails?.musterRollBillDetailsList[rowIndex]?.amount).toFixed(2)
      }
    }
  ];

  const getMonth = (value) => {
    const filteredMonth = months?.filter((elem) => elem?.value === value)
    return filteredMonth[0]?.month
  }

  const handleClickVerify = (bill) => {
    setVerificationModalOpen(true)
    setBillDetails(bill)
  }

  const showLoader = () => {
    setIsLoad(true)
  }

  const hideLoader = () => {
    setIsLoad(false)
  }
  //   getMusterRollBillByProjectLocation
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);

  }

  const handleCloseVerifyDialog = (type) => {

    setBillDetails()
    setVerificationModalOpen(false)
    if (type === "success") {
      handleSubmit()
    }
  }

  useEffect(() => {
    dispatch(getAllProjectLocationByAgency(showLoader, hideLoader, showToast))
  }, [])



  const handleChange = (event, source) => {
    setBillList()
    if (event.target.name === "project_location_id") {
      // setRequisitionList()
      // setBmlItemsList()
      const filteredArray = projectLocationListForAgency.filter(project => {
        return project.project_location_id === event.target.value
      });


      setState({
        [event.target.name]: event.target.value,
        project_name: filteredArray[0].project_name,
        project_location_id: filteredArray[0].project_location_id,
        project_id: filteredArray[0].project_id,
        project_location: filteredArray[0].project_location,
        // muster_roll_no:"",
        // muster_roll_date:"",
        // month:null
      });



    }

    else {
      setState({ ...state, [event.target.name]: event.target.value })
    }


  }
  const handleSubmit = () => {
    setBillList()
    setIsLoad(true)

    getMusterRollBillByProjectLocation(state?.project_location_id).then(res => {
      setIsLoad(false)
      if (res?.status < 300) {
        if (res?.data?.obj?.length > 0) {

          const filteredBills = res?.data?.obj?.filter((elem) => elem?.status_id === 5)
          if (filteredBills?.length > 0) {
            setBillList(filteredBills)
          }
          else {
            const key = enqueueSnackbar("No Muster Roll Bill found", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
          const key = enqueueSnackbar("No Muster Roll Bill found", { variant: 'info' });
          SnackBarProperty.info(key, closeSnackbar);
        }
      }
      else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);


      }
    }).catch(error => {
      setIsLoad(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }
  const handleChangeBillDetails = (e) => {
    setBillDetails({ ...billDetails, [e.target.name]: e.target.value })
  }

  const getTotalAmount = () => {
    const totalAmount = billDetails?.musterRollBillDetailsList?.reduce((sum, worker) => sum + worker.amount, 0);
    return totalAmount
  }


  const handleVerify = () => {
    setIsLoad(true)
    const params = { ...billDetails, status_id: 7 }
    saveOrUpdateMusterRollBill(params).then(res => {
      setIsLoad(false)
      if (res?.status < 300 && res?.data?.success) {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

        handleCloseVerifyDialog("success")
      }
      else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(error => {
      setIsLoad(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }



  return (
    <Container>
      <div className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Departmental Works", path: "#" }, { name: "Muster Roll Approval" }]} />
      </div>


      <Card sx={{ padding: 4 }} elevation={6}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={4} xs={12}>
              <DecoratedTextField
                select
                size="small"
                fullWidth
                label="Project ID"
                id="project_location_id"
                variant="outlined"
                name="project_location_id"
                value={state?.project_location_id}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["Project ID is required"]}
              >
                {projectLocationListForAgency?.map((item) => (
                  <MenuItem value={item?.project_location_id} key={item?.project_id}>
                    {item?.project_id}
                  </MenuItem>
                ))}
              </DecoratedTextField>
            </Grid>

            <Grid item sm={4} md={3} xs={12} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>

              <Button size="medium" color="success" variant="contained" type="submit" style={{ marginTop: -20, gap: 2 }} >
                <Icon color="secondary" fontSize="small">
                  visibility
                </Icon>
                View
              </Button>

            </Grid>
          </Grid>

        </ValidatorForm>

        {billList && billList?.length > 0 && <>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              data={billList}
              columns={columns}
              title={"List of Muster Roll Bill"}
              options={{
                fixedHeader: true,
                sort: false,
                responsive: 'scroll',
                selectableRows: "none",
                filterType: "textField",
                elevation: 0,
                rowsPerPageOptions: [10, 20, 40, 80, 100],
              }}
            />
          </ThemeProvider>


        </>
        }
        {verificationModalOpen && <Dialog open={verificationModalOpen} maxWidth="lg" fullWidth={true}>
          <Box sx={{ padding: 4 }} elevation={6}>
            <h4 ><b>Approve Muster Roll Bill</b></h4>

            <Grid container spacing={3}>
              <Grid items sx={{ ml: 4, mt: 4 }} xs={12}>
                <FormLabel><b>Project ID: </b>{state?.project_id}</FormLabel>
              </Grid>
              <Grid items sx={{ ml: 4 }} xs={12}>
                <FormLabel><b>Project Name: </b>{state?.project_name}</FormLabel>
              </Grid>
              <Grid items sx={{ ml: 4 }} xs={12}>
                <FormLabel><b>Requisition No.: </b>{billDetails?.requisition_no}</FormLabel>&nbsp;&nbsp;&nbsp;
                <FormLabel><b>Requisition Date: </b>{billDetails?.requisition_date}</FormLabel>
              </Grid>
              {/* <Grid items sx={{ ml: 4 }} xs={12}>
                <FormLabel><b>BML Item:</b>{billDetails?.bml_item}</FormLabel>
              </Grid>
              <Grid items sx={{ ml: 4 }} xs={12}>
                <FormLabel><b>Unit:</b>{billDetails?.unit_name}</FormLabel>
              </Grid> */}
              <Grid items sx={{ ml: 4 }} xs={12}>
                <FormLabel><b>Muster Roll No.: </b>{billDetails?.musterroll_no}</FormLabel>&nbsp;&nbsp;&nbsp;
                <FormLabel><b>Muster Roll Date: </b>{moment(billDetails?.musterroll_date).format("DD-MM-YYYY")}</FormLabel>
              </Grid>
              <Grid items sx={{ ml: 4 }} xs={12}>
                <FormLabel><b>Month: </b>{getMonth(billDetails?.bill_month)}</FormLabel>&nbsp;&nbsp;&nbsp;
                <FormLabel><b>Year: </b>{billDetails?.bill_year}</FormLabel>
              </Grid>
            </Grid>




            {billDetails && billDetails?.musterRollBillDetailsList?.length > 0 && <>
              <ThemeProvider theme={theme}>
                <MUIDataTable
                  data={billDetails?.musterRollBillDetailsList}
                  columns={workerColumns}
                  title={"List of Workers"}
                  options={{
                    fixedHeader: true,
                    sort: false,
                    responsive: 'scroll',
                    selectableRows: "none",
                    filterType: "textField",
                    elevation: 0,
                    rowsPerPageOptions: [10, 20, 40, 80, 100],
                    search: false,
                    filter: false,
                    viewColumns: false,
                    print: false,
                    download: false
                  }}
                />
              </ThemeProvider>

              <FormLabel sx={{ display: "flex", justifyContent: "flex-end" }}><b>Total Amount (Nu): </b>{parseFloat(getTotalAmount()).toFixed(2)}  ({numToWords(parseFloat(getTotalAmount()).toFixed(2))})</FormLabel>
              <ValidatorForm onSubmit={handleVerify}>
                <Grid container sx={{ marginTop: 4 }}>
                  <Grid items sm={12} md={6}  >
                    <DecoratedTextField
                      type="text"
                      rows={3}
                      size="small"
                      fullWidth
                      multiline={true}
                      name="approval_remarks"
                      label="Approval Remarks"
                      value={billDetails?.approval_remarks}
                      onChange={handleChangeBillDetails}
                      validators={["required"]}
                      errorMessages={["Approval Remarks is required"]}
                    />
                  </Grid>
                  <Grid items sm={12} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: 2 }}>
                    <Button size="medium" color="primary" type="submit" disabled={billDetails?.musterRollBillDetailsList?.length > 0 ? false : true} variant="contained">
                      Approve
                    </Button>
                    <Grid items sm={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%", gap: 2 }}>
                      <Button size="medium" color="warning" variant="contained" onClick={() => { handleCloseVerifyDialog() }}  >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </ValidatorForm>

            </>
            }

          </Box>
        </Dialog>}
      </Card>
      {isLoad && (
        <Loader
          show={isLoad}
        />
      )}

    </Container>
  );
};
