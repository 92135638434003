import AuthGuard from 'app/auth/AuthGuard';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import roleRoutes from './views/roles/RoleRoutes';
import usersRoutes from './views/users/UsersRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import bsrRoutes from './views/BSR/BSRRoutes';
import publishBSRRoutes from './views/BSR/PublishBSRRoutes';
import agencyRoutes from './views/agency/AgencyRoutes';
import parameterConfigurationRoutes from './views/ParameterConfiguration/ParameterConfigurationRoutes';

import builtUpItemRoutes from './views/BuiltUpItem/BuiltUpItemRoutes';
import builtUpItemLMCRoutes from './views/BuiltUpItem/BuiltUpItemLMCRoutes';
import builtUpItemRateRoutes from './views/BuiltUpItem/BuiltUpItemRateRoutes';

import projectLocationRoutes from './views/ProjectLocation/ProjectLocationRoutes';
import stageInformationRoutes from './views/StageInformation/StageInformationRoutes';
import constructionNameRoutes from './views/ConstructionName/ConstructionNameRoutes';
import costIndexRoutes from './views/CostIndex/CostIndexRoutes';
import costIndexWeightageRoutes from './views/CostIndex/CostIndexWeightageRoutes';
import projectBoQRoutes from './views/ProjectBoQ/ProjectBoQRoutes';
import contractListRoutes from './views/ContractDetails/ContractListRoutes';
import stagePercentageRoutes from './views/ConstructionName/StagePercentageRoutes';
import workPlanRoutes from './views/WorkPlan/WorkPlanRoutes';
import physicalProgressRoutes from './views/PhysicalProgress/PhysicalProgressRoutes';
import advancePaymentRoutes from './views/AdvancePayment/AdvancePaymentRoutes';
import musterRollPaymentRoutes from './views/MusterRollPayments/MusterRollPaymentRoutes';
import raBillRoutes from './views/RABill/RABillRoutes';
import priceAdjustmentRoutes from './views/PriceAdjustment/PriceAdjustmentRoutes';
import inventoryVerificationRoutes from './views/Inventory/InventoryVerificationRoutes';
import reportsRoutes from './views/Reports/ReportsRoutes';
import resourceForecastRoutes from './views/ResourceForecast/ResourceForeCastRoutes';
import { PDFtest } from './views/pdfTest/pdfTest';
import hiringAgencyRoutes from './views/HiringAgency/HiringAgnecyRoutes';
import materialBillRoutes from './views/MaterialBills/MaterialBillRoutes';
import releaseOfRetentionRoutes from './views/ReleaseOfRetention/ReleaseOfRetentionRoutes';
import supplierRoutes from './views/suppliers/supplierRoutes';
import deploymentOrderRoutes from './views/DeploymentOrder/DeploymentOrderRoutes';
import part3Routes from './views/PartIII/Part3Routes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...dashboardRoutes, ...roleRoutes, ...bsrRoutes, ...publishBSRRoutes, ...agencyRoutes, ...projectLocationRoutes,
    ...stageInformationRoutes, ...usersRoutes,...supplierRoutes, ...builtUpItemRoutes, ...builtUpItemLMCRoutes, ...builtUpItemRateRoutes,
    ...constructionNameRoutes, ...costIndexRoutes, ...costIndexWeightageRoutes, ...projectBoQRoutes, ...contractListRoutes,
    ...stagePercentageRoutes, ...workPlanRoutes, ...physicalProgressRoutes, ...advancePaymentRoutes, ...parameterConfigurationRoutes,
    ...inventoryVerificationRoutes, ...musterRollPaymentRoutes, ...raBillRoutes, ...priceAdjustmentRoutes, ...reportsRoutes,
    ...resourceForecastRoutes, ...hiringAgencyRoutes, ...materialBillRoutes, ...releaseOfRetentionRoutes, ...deploymentOrderRoutes, ...part3Routes]
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard" /> },
  { path: '*', element: <NotFound /> },
  { path: '/pdf', element: <PDFtest /> },
  
];

export default routes;
