import { CHECK_EMAIL, REGISTRATION_SUCCESS } from "../actions/SessionActions"

const initialState = {
    available: false,
    registrationSuccess: ''
}


export const SessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_EMAIL: {
            const { available } = action.payload

            return {
                ...state,
                available: available
            }
        }
        case REGISTRATION_SUCCESS: {
            return {
                ...state,
                registrationSuccess: action.payload
            }
        }
        default: {
            return { ...state }
        }
    }
}