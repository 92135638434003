import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const WorkPlan = Loadable(lazy(() => import('./WorkPlan')));

const workPlanRoutes = [
  {
    path: '/workPlan',
    element: <WorkPlan/>,
  }
];

export default workPlanRoutes;