import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllConstructionNameListByParameters, getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getExecutedBillOfQuantityReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const ExecutedBillOfQuantityReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [constructionNameList, setConstructionNameList] = useState([])
  const [projectDetails, setProjectDetails] = useState({})
  const [totalAmount, setTotalAmount] = useState()
  const { reportsProjectLocationList, projectLevelStagePercentage, constructionLevelStagePercentage } = useSelector((state) => state.projectLocation);
  // const {userList}=useSelector((state)=>state.userList)
  const [tableData, setTableData] = useState([])
    ;
  const [state, setState] = useState({
    project_location_id: '',
    constructionname_id: ''
  })

  // useEffect(()=>{
  //     console.log(userList)
  // },[userList])

  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {
    setProjectDetails({})
    setTableData([])
    if (event.target.name === "project_location_id") {
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value });

      //dispatch(getAllConstructionNameListByParams(0, filteredArray[0].project_location_id, showLoader, hideLoader, showToast))
      getAllConstructionNameList(0, filteredArray[0].project_location_id, filteredArray[0].project_id)

    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }
  const getAllConstructionNameList = (project_location_id, constructionname_id, project_id) => {
    setLoading(true)
    getAllConstructionNameListByParameters(project_location_id, constructionname_id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {

        setConstructionNameList(res?.data)

      } else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
       const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

    })
  }

  const handleFormSubmit = () => {
    setLoading(true)
    setProjectDetails({})
    setTableData([])
    getExecutedBillOfQuantityReport({ construction_name_id: state?.constructionname_id, project_location_id: state?.project_location_id }).then(res => {
      setLoading(false)
      console.log(res?.data)
      if (res?.status < 300) {
        if (res?.data?.obj&&res?.data?.success) {
          if (res?.data?.obj?.item) {
            if (res?.data?.obj?.item?.item) {
              let table = []
              Object.keys(res?.data?.obj?.item?.item).forEach(key => {

                table = (res?.data?.obj?.item?.item[key]?.map(elem => elem))
              })
              const sumOfAmount = table?.reduce((sum, entry) => {
                return sum + entry.item_list.reduce((itemSum, item) => {
                  return itemSum + item.amount;
                }, 0);
              }, 0);
              setTotalAmount(parseFloat(sumOfAmount).toFixed(2))
              setTableData(table)
            }
            setProjectDetails({
              ...projectDetails,
              construction_name: Object.keys(res?.data?.obj?.item?.item)[0],
              project_name: res?.data?.obj?.item?.project_name,
              client: res?.data?.obj?.item?.agency_name,
              project_id: res?.data?.obj?.item?.project_id,
              header: res?.data?.obj?.header_logo,
              footer: res?.data?.obj?.footer_logo
            })
          }
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
          SnackBarProperty.info(key, closeSnackbar);
        }


      }
      else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(error => {
      setLoading(false)
       const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

    })



  }

  const handleDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.header ? projectDetails?.header : 'moit_footer.jpg'}`
     
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
          scaledHeight = pdfHeight;
          scaledWidth = pdfHeight * aspectRatio;
      }

      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)
      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      // Insert the header image into the PDF
      const textX = 15;
      const textY = scaledHeight + 10; // Adjust the value for vertical separation

      // Add the text content below the header image
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text("EXECUTED BILL OF QUANTITY", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("EXECUTED BILL OF QUANTITY") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);

      const textWidth = doc.getStringUnitWidth("Construction Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;

      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY + 10);
     doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + textWidth -10, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.client, textX + textWidth -10, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.project_id}`, textX + textWidth -10, textY + 20);
      doc.setFont('helvetica', 'bold');
      doc.text("Construction Name:", textX, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.construction_name}`, textX + textWidth -10, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(5);
      //   doc.setFont('helvetica', 'bold');
      //   doc.text("End Date:",textX, textY + 15);
      //   const fourthTextWidth = doc.getStringUnitWidth("End Date:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(`${moment(contractDetails?.work_end_date).format("DD-MM-YYYY")}`, textX + fourthTextWidth + 5, textY+15);
      //   doc.setFont('helvetica', 'normal');
      //   doc.setFont('helvetica', 'bold');
      //   doc.text("RA Bill No.:",textX, textY + 20);
      //   const fifthTextWidth = doc.getStringUnitWidth("RA Bill No.:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(`${contractDetails?.rabill_no}`, textX + fifthTextWidth + 5, textY+20);
      //   doc.setFont('helvetica', 'bold');
      //   doc.text("Contract Amount:",textX, textY + 25);
      //   const sixthTextWidth = doc.getStringUnitWidth("Contract Amount:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(`${parseFloat(contractDetails?.initial_contract_amount).toFixed(2)}`, textX + sixthTextWidth + 5, textY+25);
      //   doc.setFont('helvetica', 'normal');

    
      doc.setFont('helvetica', 'normal');

      const body = []
      body.push(["", "", "",
        { content: "BOQ", styles: { fontStyle: "bold",fontSize:8 } },
        { content: "Current", styles: { fontStyle: "bold",fontSize:8 } },
        { content: "Deviated", styles: { fontStyle: "bold",fontSize:8 } },
        { content: "Executed", styles: { fontStyle: "bold",fontSize:8 } },
        "",
        { content: "Tender", styles: { fontStyle: "bold",fontSize:8 } },
        { content: "Revised", styles: { fontStyle: "bold",fontSize:8 } },
        "", ""])
      tableData?.forEach((elem) => {
        body.push([
          { content: `R.A ${elem?.rabill_no}`, colSpan: 11, styles: { fontStyle: "bold",fontSize:8 } }
        ])
        elem?.item_list?.forEach((item, index) => {
          body.push([
            {content:index + 1,styles:{fontSize:8}},
            {content:item?.item_code,styles:{fontSize:8}},
            {content:item?.description,styles:{fontSize:8}},
            {content:`${parseFloat(item?.boq ? item?.boq : 0).toFixed(2)}`,styles:{fontSize:8}},
            {content:`${parseFloat(item?.current_quantity ? item?.current_quantity : 0).toFixed(2)}`,styles:{fontSize:8}},
            {content:`${parseFloat(item?.deviation_quantity ? item?.deviation_quantity : 0).toFixed(2)}`,styles:{fontSize:8}},
            {content:`${parseFloat(parseFloat(item?.current_quantity ? item.current_quantity : 0) + parseFloat(item.deviation_quantity ? item.deviation_quantity : 0)).toFixed(2)}`,styles:{fontSize:8}},
            {content:item?.unit_name,styles:{fontSize:8}},
            { content: formatNumberWithCommasAndDecimals(item?.tender_rate ? item?.tender_rate : 0), styles: { halign: "right",fontSize:8 } },
            { content: formatNumberWithCommasAndDecimals(item?.revised_rate ? item?.revised_rate : 0), styles: { halign: "right",fontSize:8 } },
            { content: formatNumberWithCommasAndDecimals(item?.amount ? item?.amount : 0), styles: { halign: "right",fontSize:8 } },
           {content:item?.remarks,styles:{fontSize:8}}
          ])
        })
      })
      body.push([
        { content: "Grand Total", colSpan: 10, styles: { fontStyle: "bold", halign: "right",fontSize:8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount), colSpan: 1, styles: { fontStyle: "bold", halign: "right",fontSize:8 } }
      ])
      const headerSectionHeight = scaledHeight+40; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;

      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "SL No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "Item Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "Quantity", colSpan: 4, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "Rate (Nu)", colSpan: 2, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } },
          { content: "Remarks", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2,fontSize:8 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
          fontSize: 7
        },
        columnStyles: {
          // fontSize:5

          8: { halign: "right" },
          9: { halign: "right" },
          10: { halign: "right" },
          // 11:{columnWidth:15}
        },
      });


      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(footer, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }


      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save("Executed_Details_Of_Quantity_Report.pdf");

    });

  };


  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Executed Bill of Quantity</b></Grid>
            </Grid>
            <Grid container spacing={3} >

              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={4} xs={12}>

                <DecoratedTextField
                  select
                  size="small"
                  fullWidth
                  label="Construction Name"
                  id="constructionname_id"
                  variant="outlined"
                  sx={{ mb: 3 }}
                  name="constructionname_id"
                  value={state?.constructionname_id || ""}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Construction Name is required"]}
                >
                  {constructionNameList?.map((item) => (
                    <MenuItem value={item?.constructionname_id} key={item?.constructionname_id}>
                      {item?.construction_name}
                    </MenuItem>
                  ))}
                </DecoratedTextField>

              </Grid>
              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {tableData && tableData?.length > 0 && Object.keys(projectDetails).length > 0 && <Grid container>
          <Grid items xs={12} >
            {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box component="span" display="flex" justifyContent="flex-end">
              <Button
                size="small"
                sx={{ width: "130px", marginLeft: 4 }}
                variant="contained"
                color="warning"
                startIcon={<CloudDownload />}
                disabled={projectDetails?.header ? false : true}
                onClick={() => { handleDownload(false) }}
              >
                Download
              </Button>
              <Button
                size="small"
                sx={{ width: "100px", marginLeft: 2 }}
                variant="contained"
                color="primary"
                startIcon={<Print />}
                disabled={projectDetails?.header ? false : true}
                onClick={() => { handleDownload(true) }}
              >
                Print
              </Button>
            </Box>
          </Grid>

        </Grid>}
        {tableData && tableData?.length > 0 && <Card sx={{ padding: 3 }}>

          <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
            <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

              </div>

              <Grid container spacing={0} >
                <Grid item xs={12} align='center' >
                  <FormLabel sx={{ fontSize: 22, justifyContent: "center", width: "100%" }} ><b>EXECUTED BILL OF QUANTITY</b></FormLabel>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.client}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>
                <Grid item xs={12} >
                  <FormLabel sx={{ fontSize: 18 }}><b>Construction Name: </b>{projectDetails?.construction_name}</FormLabel>
                </Grid>
              </Grid>
            </div>

            <div id="tableData">
              <TableContainer component={Paper}>


                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>

                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>Item Code</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell colSpan={4}>QUANTITY</TableCell>
                      <TableCell>Unit</TableCell>
                      <TableCell colSpan={2}>Rate (Nu)</TableCell>
                      <TableCell>Amount (Nu)</TableCell>
                      <TableCell>Remarks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell><b>BOQ</b></TableCell>
                      <TableCell><b>Current</b></TableCell>
                      <TableCell><b>Deviated</b></TableCell>
                      <TableCell><b>Executed</b></TableCell>
                      <TableCell></TableCell>
                      <TableCell><b>Tender</b></TableCell>
                      <TableCell><b>Revised</b></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    {tableData?.map((section) => (
                      <React.Fragment key={section.stageName}>
                        <TableRow>
                          <TableCell colSpan={12}><b>R.A {section.rabill_no}</b></TableCell>
                        </TableRow>
                        {section.item_list.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row.item_code}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{row?.boq ? parseFloat(row?.boq).toFixed(2) : 0.00}</TableCell>
                            <TableCell>{row.current_quantity ? parseFloat(row.current_quantity).toFixed(2) : 0.00}</TableCell>
                            <TableCell>{row.deviation_quantity ? parseFloat(row.deviation_quantity).toFixed(2) : 0.00}</TableCell>
                            <TableCell>{parseFloat(parseFloat(row.current_quantity ? row.current_quantity : 0) + parseFloat(row.deviation_quantity ? row.deviation_quantity : 0)).toFixed(2)}</TableCell>
                            <TableCell>{row?.unit_name}</TableCell>
                            <TableCell>{formatNumberWithCommasAndDecimals(row?.tender_rate ? row.tender_rate : 0.00)}</TableCell>
                            <TableCell>{formatNumberWithCommasAndDecimals(row?.revised_rate ? row.revised_rate : 0.00)}</TableCell>
                            <TableCell>{formatNumberWithCommasAndDecimals(row?.amount ? row.amount : 0.00)}</TableCell>
                            <TableCell>{row?.remarks}</TableCell>
                          </TableRow>
                        ))}

                      </React.Fragment>
                    ))}
                    <TableRow>
                      <TableCell colSpan={10} align="right" style={{ fontWeight: 600 }}><b>Total Amount</b></TableCell>
                      <TableCell colSpan={2} style={{ fontWeight: 600 }} align="right"><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          </div>
        </Card>}
  
      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}