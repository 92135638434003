import { ServiceUrl } from 'app/utils/Constants';
import axios from 'axios';

export const GET_LIQUIDITY_DAMAGE_LIST = 'GET_LIQUIDITY_DAMAGE_LIST';
export const GET_LD_LIMIT_LIST = 'GET_LD_LIMIT_LIST';
export const GET_VARIANCE_LIST = 'GET_VARIANCE_LIST';
export const GET_INITIAL_CONTRACT_LIST = 'GET_INITIAL_CONTRACT_LIST';
export const GET_TDS_LIST = 'GET_TDS_LIST';
export const GET_RETENTION_LIST = 'GET_RETENTION_LIST';


export const UPDATE_CURRENT_CONFIGURATION_TAB = 'UPDATE_CURRENT_CONFIGURATION_TAB';



export const getAllLiquidityDamageList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllLiquidityDamageList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_LIQUIDITY_DAMAGE_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllLDLimitList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllLDLimitList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_LD_LIMIT_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllRetentionList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllRetentionList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_RETENTION_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const getAllVarianceList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllVarianceList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_VARIANCE_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const getAllTDSList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllTDSList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_TDS_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

export const getAllEoIContractList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getAllEoIContractList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_INITIAL_CONTRACT_LIST,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};



export const saveOrUpdateRetention = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRetention, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
};

export const saveOrUpdateVariance = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateVariance, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
};

export const saveOrUpdateMachineryLogbook = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateMachineryLogbook, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
};
export const updateResourceRequisition = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${params}${ServiceUrl.updateResourceRequisition}`, {}, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getResourceRequisitionByProjectLocation = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/resourceForecast/${params}${ServiceUrl.getResourceRequisitionByProjectLocation}`, {}, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateTDS = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateTDS, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
};

export const saveOrUpdateEoIContract = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateEoIContract, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
};

