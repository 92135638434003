import { GET_INITIAL_CONTRACT_LIST, GET_LD_LIMIT_LIST, GET_LIQUIDITY_DAMAGE_LIST, GET_RETENTION_LIST, GET_TDS_LIST, GET_VARIANCE_LIST } from "../actions/ParameterConfigurationActions";

  
  const initialState = {
    liquidityDamageList: [],
    ldLimitList: [],
    tdsList: [],
    retentionList: [],
    varianceList: [],
    initalContractList: [],
    currentConfiguationTab: 0,
    isLoading: false
  };
  
  const ParamterConfigurationReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_LIQUIDITY_DAMAGE_LIST: {
        return {
            ...state,
            isLoading: false,
            liquidityDamageList: [...action.payload],
          };
      }

      case GET_LD_LIMIT_LIST: {
        return {
            ...state,
            isLoading: false,
            ldLimitList: [...action.payload],
          };
      }

      case GET_TDS_LIST: {
        return {
            ...state,
            isLoading: false,
            tdsList: [...action.payload],
          };
      }

      case GET_RETENTION_LIST: {
        return {
            ...state,
            isLoading: false,
            retentionList: [...action.payload],
          };
      }

      case GET_VARIANCE_LIST: {
        return {
            ...state,
            isLoading: false,
            varianceList: [...action.payload],
          };
      }

      case GET_INITIAL_CONTRACT_LIST: {
        return {
            ...state,
            isLoading: false,
            initalContractList: [...action.payload],
          };
      }


      default: {
        state.currentConfiguationTab = 0
        return {...state}
      }
    }
  };
  
  export default ParamterConfigurationReducer;
  