import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import RABillRecommended from './RABillRecommended';

 const RABillSubmission = Loadable(lazy(() => import('./RABillSubmission')));
 const RABillVerification = Loadable(lazy(() => import('./RABillVerification')));
 const RABillApproval = Loadable(lazy(() => import('./RABillApproval')));
 const RABillRecovery = Loadable(lazy(() => import('./RABillRecovery')));
 const RABillAmendment = Loadable(lazy(() => import('./RABillAmendment')));

const raBillRoutes = [
  {
    path: '/RABillSubmission',
    element: <RABillSubmission/>,
  },
  {
    path: '/RABillVerification',
    element: <RABillVerification/>,
  },
  {
    path: '/RABillApproval',
    element: <RABillApproval/>,
  },
  {
    path: '/RABillRecovery',
    element: <RABillRecovery/>,
  },
  {
    path: '/RABillAmendment',
    element: <RABillAmendment/>,
  },{
    path: '/BillRecommendation',
    element: <RABillRecommended/>,
  }


//RABillSubmission
//RABillVerification
//RABillApproval
//RABillRecovery
//RABillAmendment
];

export default raBillRoutes;