import { Close as CloseIcon } from '@mui/icons-material';

export const SnackBarProperty = {
     error:(key,closeSnackbar)=> 
     ({
          variant: "error" ,
      anchorOrigin: { vertical: "top", horizontal: "center" },
       action: () => (
          <button
          onClick={() => closeSnackbar(key)}
          style={{
            color: 'white',
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
        >
          <CloseIcon fontSize="small" />
        </button>
        )}),
     info:(key,closeSnackbar)=> 
     ( {
          variant: "info" ,
      anchorOrigin: { vertical: "top", horizontal: "center" },
      action: () => (
          <button
          onClick={() => closeSnackbar(key)}
          style={{
            color: 'white',
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
        >
          <CloseIcon fontSize="small" />
        </button>
        )}),
     success:(key,closeSnackbar)=> 
     ( {
          variant: "success" ,
          anchorOrigin: { vertical: "top", horizontal: "center" },
          action: () => (
               <button
               onClick={() => closeSnackbar(key)}
               style={{
                 color: 'white',
                 border: 'none',
                 background: 'transparent',
                 cursor: 'pointer',
               }}
             >
               <CloseIcon fontSize="small" />
             </button>
             )
     }),
     warning:(key,closeSnackbar)=> 
     ( {
          variant: "warning" ,
          anchorOrigin: { vertical: "top", horizontal: "center" },
          action: () => (
               <button
               onClick={() => closeSnackbar(key)}
               style={{
                 color: 'white',
                 border: 'none',
                 background: 'transparent',
                 cursor: 'pointer',
               }}
             >
               <CloseIcon fontSize="small" />
             </button>
             )
     })
}